<app-jumbotron [information]="jumbotron"></app-jumbotron>

<div clas="" style="background: linear-gradient(#ffffff 0%, #ffffff 0%, #ecf2f9 0%, #ecf2f9 100%)">

  <p class="pt-5 text-primary text-center"style="font-size: 28px;" >LOS SERVICIOS QUE TENEMOS PARA TI</p>              
  <!-- <div><img class="lazyload" [attr.data-src]="imageBase + '/uploads/mujer_e30e20e11d.png'"  width="80px" height="80px"  alt="..." /></div> -->
  <div class="container ml-5 pl-5">

    <div class="row" *ngIf="code =='1'">
      <div class="col-sm-12 col-lg-3 m-0 p-1" *ngFor="let servicio of zonaExploradores">
        <div class="card h-100">
          <div class="card-body">
              <a routerLink="{{ servicio.url }}">
                  <h4 class="text-primary text-center">{{ servicio.titulo }}</h4>
                  <p class="text-secondary text-center">
                    {{ servicio.descripcion }}
                  </p>
              </a>
          </div>
        </div>

      </div>
    </div>
   
    <div class="row" *ngIf="code =='3'">
      <div class="col-sm-12 col-lg-3 m-0 p-1" *ngFor="let servicio of zonaIntermediarios">
        <div class="card h-100">
          <div class="card-body">
              <a routerLink="{{ servicio.url }}">
                  <h4 class="text-primary text-center">{{ servicio.titulo }}</h4>
                  <p class="text-secondary text-center">
                    {{ servicio.descripcion }}
                  </p>
              </a>
          </div>
        </div>

      </div>
    </div>

    <div class="row" *ngIf="code =='2'">
      <div class="col-sm-12 col-lg-3 m-0 p-1" *ngFor="let servicio of zonaDecisores">
        <div class="card h-100">
          <div class="card-body">
              <a *ngIf="servicio.tipo === 1" routerLink="{{ servicio.url }}">
                  <h4 class="text-primary text-center">{{ servicio.titulo }}</h4>
                  <p class="text-secondary text-center">
                    {{ servicio.descripcion }}
                  </p>
              </a>
              <a *ngIf="servicio.tipo === 2" href="{{ servicio.url }}" target="_blank">
                <h4 class="text-primary text-center">{{ servicio.titulo }}</h4>
                <p class="text-secondary text-center">
                  {{ servicio.descripcion }}
                </p>
              </a>              
          </div>
        </div>

      </div>
    </div>

  </div>  

<br>  
<br>  




</div>





