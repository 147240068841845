import { Component, OnDestroy, OnInit,ElementRef,QueryList,ViewChildren,AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { CommonService } from 'src/app/services/common.service';


@Component({
  selector: 'app-outlook-nostat-filters',
  templateUrl: './nostat-filters.component.html',
  styleUrls: ['./nostat-filters.component.scss']
})
export class NoStatFiltersComponent implements OnInit,OnDestroy {

  @ViewChildren('dropdown') dropdowns!: QueryList<ElementRef> | undefined;;

  
  loading: boolean;
  error: boolean;
  indicatorId: number;
  code: string;
  filters: any[];
  selectedKey: string;
  selectedValue: string;
  defaultValue: string;
  cleanDisabled: boolean;
  paramsSubscription: Subscription;
  

    constructor(
      private router: ActivatedRoute,
      private commonService: CommonService,
      private apiService: ApiService
    ) {
    this.loading = true;
    this.error = false;
    this.indicatorId = 0;
    this.code = '';
    this.filters = [];
    this.selectedKey = '';
    this.selectedValue = '';
    this.defaultValue = '';
    this.cleanDisabled = true;
    this.paramsSubscription = Subscription.EMPTY;
    
  }

  ngOnInit(): void {
    this.getInformation();

  };

  getInformation() {
    this.paramsSubscription = this.router.params.subscribe(({ code, id, order }) => {
      this.loading = true;
      this.error = false;
      this.cleanDisabled = true;
      this.resetFilters();
      this.getIndicatorId(code, id, order);
    });
  }

  async getIndicatorId(code: string, subGroupId: number, order: number) {
    this.code = code;
    let response = await this.apiService.getManyBackend(`/indicators/${this.code}/sub-groups/${subGroupId}`, null);
    if (order <= response.data.length) {
      this.indicatorId = response.data[order - 1].id;
      this.getFilters();
    } else {
      this.error = true;
      this.loading = false;
    }
  }

  async getFilters() {
    let response = await this.apiService.getManyBackend(`/indicators/${this.code}/${this.indicatorId}/filters`, null);
    this.filters = response.data.no_stat_filters;

    if (this.filters.length > 0) {
      this.selectedKey = this.filters[0].key;
       console.log("this.filters",this.filters);
       console.log("this.filters - 1er valor",this.filters[0].values[0].name);
       console.log("this.selectedKey",this.selectedKey);
      //this.selectedValue = "Total";
      this.selectedValue = this.filters[0].values[0].name;
      this.commonService.sendUpdate({filter: this.selectedKey, value: this.filters[0].values[0].name});
      this.cleanDisabled = false;
      
    } 

    this.loading = false;
  }

  onChange(key: string, $event: any) {
    if ($event.target.value == '') {
      this.resetFilters();
      this.cleanDisabled = true;
      this.commonService.sendUpdate({filter: 'clear'});
    } else {
      if (this.selectedKey != key || this.selectedValue != $event.target.value) {
        this.selectedKey = key;
        this.selectedValue = $event.target.value;
        this.commonService.sendUpdate({filter: key, value: $event.target.value});

        this.defaultValue = '';
        this.cleanDisabled = false;
      }
    }
  }

  resetFilters() {
    this.selectedKey = '';
    this.selectedValue = '';
  }

  cleanFilters() {
    this.resetFilters();
    this.cleanDisabled = true;
    this.commonService.sendUpdate({filter: 'clear'});
  }

  ngOnDestroy() {
    this.paramsSubscription.unsubscribe();
  }
}
