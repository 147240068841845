import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from "@angular/forms";
import { SharedModule } from '../shared/shared.module';

import { DiscoverComponent } from './discover.component';
import { listado_ocp } from './listado-ocupaciones/listado-ocupaciones.component';
import { RouterModule } from '@angular/router';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { OcupacionesTableComponent } from './ocupaciones-table/ocupaciones-table.component';
import { ComparadorComponent } from './comparador/comparador.component';

@NgModule({
  declarations: [DiscoverComponent, listado_ocp, OcupacionesTableComponent, ComparadorComponent],
  imports: [CommonModule, SharedModule, RouterModule, AutocompleteLibModule,FormsModule ],
  exports: [ComparadorComponent],
})
export class DiscoverModule {}
