import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-labor-training-modalities',
  templateUrl: './labor-training-modalities.component.html',
  styleUrls: ['./labor-training-modalities.component.scss'],
})
export class LaborTrainingModalitiesComponent implements OnInit {
  loading: boolean;
  tabs: any[];
  imageBase: string;
  totalResults: number;
  information: any;
  constructor(private apiService: ApiService) {
    this.loading = true;
    this.tabs = [];
    this.imageBase = environment.strapiUrl;
    this.totalResults = 0;
    this.information = {};
  }

  ngOnInit(): void {
    this.getInformation();
  }

  async getInformation() {
    let header = await this.apiService.getOne(
      '/job-developments?code=modalidades-formativas'
    );

    let items = await this.apiService.getMany('/labor-training-modalities');

    this.information = {
      title: header.title,
      description: header.description,
      results: items,
    };
    this.totalResults = this.information.results.length;
    this.tabs = await this.apiService.getMany('/labor-training-modalities');
    this.loading = false;
  }
}
