import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-detail-descripcion',
  templateUrl: './descripcion.component.html',
  styleUrls: ['./descripcion.component.scss'],
})
export class DescripcionComponent implements OnInit {
  occupation: any;
  occupationFunctions: any;
  levelSeven: any;
  occupationSkills: any;
  occupationId: string;
  occupationCode: string;
  edoProjection: any;
  edoYear: any;
  occupationGrowth: any;

  occupation_skills: any;

  isFour: boolean;
  occupationSeven: any;

  skillSelected: any;
  edo_year: any;
  edo_projection: any;  


  constructor(private route: ActivatedRoute, private apiService: ApiService) {
    this.occupation = null;
    this.occupationFunctions = [];
    this.levelSeven = [];
    this.occupationSkills = [];
    this.occupationId = this.route.snapshot.params.id;
    this.occupationCode = this.route.snapshot.params.code;
    this.edoProjection = undefined;
    this.edoYear = '';
    this.occupationGrowth = '';
    this.occupation_skills = undefined;
    
    this.isFour = this.route.snapshot.params.code ? this.route.snapshot.params.code.length !== 7: true;
    this.occupationSeven = {};

    this.edo_year = 0;
    this.edo_projection = 0;      
  }

  ngOnInit(): void {
    this.start();
  }

  async start() {
    await this.getOccupation();
    this.getOccupationFunctions();
    this.getLevelSeven();
    this.getOccupationSkills();
  }

  async getOccupation() {
    let occupation = undefined;

    if (!this.isFour) {
      let occupationSeven = await this.apiService.getOne(
        `/occupation-sevens?code=${this.occupationCode}`
      );
      this.occupationSeven = occupationSeven;

      this.edo_year = occupationSeven.edo_year;
      this.edo_projection = occupationSeven.edo_projection;   

      occupation = await this.apiService.getMany(
        `/occupation-fours/${occupationSeven.occupation_four.id}`
      );
    } else {
        occupation = await this.apiService.getMany(
          `/occupation-fours/${this.occupationId}`
        );

        this.edo_year = occupation.edo_year;
        this.edo_projection = occupation.edo_projection;   
    }

    this.occupation = occupation;

    // console.log("this.edo_year",this.edo_year);
    // console.log("this.edo_projection",this.edo_projection);    

    if (!this.isFour) {
      let occupationSeven = await this.apiService.getOne(
        `/occupation-sevens?code=${this.occupationCode}`
      );
      this.occupationSeven = occupationSeven;
    }

    let codeToGrowth = this.isFour
      ? this.occupation.code
      : this.occupationSeven.code;

    this.getOccupationGrowth(codeToGrowth);
  }

  async getOccupationSkills() {
    let idToSearch = this.occupationId;

    if (!this.isFour) {
      idToSearch = this.occupationSeven.occupation_four.id;
    }

    let occupationSkills = await this.apiService.getOne(
      `/occupation-skills?code_four.id=${idToSearch}`
    );

    // console.log("occupationSkills",occupationSkills);

    if (occupationSkills != undefined ){
      let occupationArray = [];

      occupationArray.push({
        name: 'Comunicación, colaboración y creatividad',
        value: Math.round(
          occupationSkills.colaboracion_creatividad * 100
        ).toLocaleString(),
        description: 'Comunicarse, colaborar, relacionarse y negociar con otras personas, desarrollar soluciones a problemas, crear planes o especificaciones para el diseño de objetos y sistemas, componer texto o música, actuar para entretener a una audiencia e impartir conocimientos a otros.',
      });
  
      occupationArray.push({
        name: 'Competencias en materia de información',
        value: Math.round(
          occupationSkills.competencia_informacion * 100
        ).toLocaleString(),
        description: 'Recopilar, almacenar, monitorear y usar información; realización de estudios, investigaciones y pruebas; mantener registros; administrar, evaluar, procesar, analizar y monitorear información y proyectar resultados.',
      });
  
      occupationArray.push({
        name: 'Prestar asistencia y cuidados',
        value: Math.round(
          occupationSkills.asistencia_cuidados * 100
        ).toLocaleString(),
        description: 'Brindar asistencia, crianza, cuidado, servicio y apoyo a las personas, y garantizar el cumplimiento de las reglas, estándares, pautas o leyes.',
      });
  
      occupationArray.push({
        name: 'Competencias de gestión',
        value: Math.round(occupationSkills.gestion * 100).toLocaleString(),
        description:'Gestión de personas, actividades, recursos y organización; desarrollo objetivos y estrategias, organizando actividades laborales, asignando y controlando recursos y liderando, motivando, reclutando y supervisando personas y equipos.',
      });
  
      occupationArray.push({
        name: 'Trabajar con ordenadores',
        value: Math.round(
          occupationSkills.trabajo_ordenadores * 100
        ).toLocaleString(),
        description: 'Usar computadoras y otras herramientas digitales para desarrollar, instalar y mantener software e infraestructura de TIC y para navegar, buscar, filtrar, organizar, almacenar, recuperar y analizar datos, para colaborar y comunicarse con otros, para crear y editar contenido nuevo.',
      });
  
      occupationArray.push({
        name: 'Manipular y mover',
        value: Math.round(
          occupationSkills.manipular_mover * 100
        ).toLocaleString(),
        description: 'Clasificar, ordenar, mover, transformar, fabricar y limpiar productos y materiales a mano o con herramientas y equipos portátiles. Cuidando plantas, cultivos y animales.',
      });
  
      occupationArray.push({
        name: 'Construir',
        value: Math.round(occupationSkills.construir * 100).toLocaleString(),
        description: 'Construcción, reparación, instalación y acabado de estructuras interiores y exteriores.',
      });
  
      occupationArray.push({
        name: 'Trabajar con maquinaria y equipo especializado',
        value: Math.round(
          occupationSkills.maquinaria_equipo_especializado * 100
        ).toLocaleString(),
        description: 'Controlar, operar y monitorear vehículos, maquinaria estacionaria y móvil e instrumentación y equipos de precisión.',
      });
  
      occupationArray.sort((a, b) =>
        parseInt(b.value) > parseInt(a.value) ? 1 : -1
      );
      this.occupationSkills = occupationArray;
  
      let Skills = this.occupationSkills;
         
      Skills = Skills.map((row: any) => {
                        return [
                          row.name,
                          parseInt(row.value)
                        ];
                      });
  
  
  
      this.occupation_skills = [
        {
          title: 'Remuneración mensual (S/)',
          source: 'Encuesta Nacional de Hogares (ENAHO) - INEI',
          data: Skills
        } 
      ];
   
      // console.log("this.occupation_skills");
      // console.log(this.occupation_skills);    
    }


 
  }

  async getOccupationFunctions() {
    const occupationFunctions = await this.apiService.getMany(
      `/occupation-four-functions?occupation_four=${this.occupationId.substring(0,4)}`
    );
    this.occupationFunctions = occupationFunctions;
  }

  async getOccupationGrowth(code: string) {
    const occupationGrowth = (
      await this.apiService.getManyBackend(`/occupations/growth/${code}`, null)
    ).data;
    this.occupationGrowth = occupationGrowth.value;
  }

  async getLevelSeven() {
    let levelSeven = [];
    if (this.isFour) {
      levelSeven = await this.apiService.getMany(
        `/occupation-sevens?occupation_four=${this.occupationId}&_sort=name:ASC`
      );
    } else {
      levelSeven = await this.apiService.getMany(
        `/occupation-sevens?id=${this.occupationSeven.id}&_sort=name:ASC`
      );
    }

    this.levelSeven = levelSeven;

    let year_projection: any = {};
    let year_collection = [];

    for (const occupation of this.levelSeven) {
      if (occupation.edo_year !== null && occupation.edo_projection !== null) {
        year_collection.push(occupation.edo_year);

        year_projection[occupation.edo_year] =
          year_projection[occupation.edo_year] !== undefined
            ? year_projection[occupation.edo_year] + occupation.edo_projection
            : occupation.edo_projection;
      }
    }
    if (year_collection.length > 0) {
      const uniqueYear = new Set([...year_collection]);
      const maxYear = Math.max(...uniqueYear);

      // console.log("year_collection");
      // console.log(year_collection);
      
      this.edoProjection = year_projection;
      this.edoYear = maxYear;
    }
  }

  setSkillSelected(skillName: any){
    if(this.skillSelected == skillName)
      this.skillSelected = '';
    else
      this.skillSelected = skillName;
  }
}
