import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListadoTableroComponent } from '../tablero/listado-tablero/listado-tablero.component';
import { DetalleTableroComponent } from '../tablero/detalle-tablero/detalle-tablero.component';

const routes: Routes = [
  { path: '', component: ListadoTableroComponent },
  { path: ':slug', component: DetalleTableroComponent },  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TableroRoutingModule { }
