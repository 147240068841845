import { Component, OnInit, ViewChild } from '@angular/core';
import { Event, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  @ViewChild('auto') auto: any;

  showSearch: boolean;
  activePage: number;
  routerSubscription: Subscription;

  searchList: any[];
  keyword = 'name';

  constructor(private router: Router, private apiService: ApiService) {
    this.showSearch = false;
    this.activePage = 0;
    this.routerSubscription = Subscription.EMPTY;
    this.searchList = [];
  }

  ngOnInit(): void {
    this.getSearchInformation();

    this.setCurrentPage(this.router.url);

    this.routerSubscription = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.setCurrentPage(event.url);
      }
    });
  }

  async getSearchInformation() {
    let response = await this.apiService.getManyBackend('/home/search', null);
    this.searchList = response.data;
    console.log(response);
  }

  setCurrentPage(url: string) {
    if (
      url.includes('/recursos-desarrollo-laboral') ||
      url.includes('/descubre') ||
      url.includes('/orientacion-presencial')
    ) {
      this.activePage = 1;
    } else if (url.includes('/panorama-laboral')) {
      this.activePage = 2;
    } else if (
      url.includes('/recursos/publicaciones') ||
      url.includes('/recursos/microdatos')  
    ) {
      this.activePage = 3;
    } else if (
      url.includes('/observatorios-regionales')  
    ) {
      this.activePage = 4;
    } 
    else {
      this.activePage = 0;
    }
  }

  onShowSearch() {
    this.showSearch = !this.showSearch;
  }

  selectEvent(item: any) {
    this.router.navigate([item.url]);
  }

  onFocused(e: any) {
    this.auto.close();
  }

  onCleared(e: any) {
    this.auto.close();
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }
}
