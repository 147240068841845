import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-detail-offers-variation',
  templateUrl: './offers-variation.component.html',
  styleUrls: ['./offers-variation.component.scss'],
})
export class OffersVariationComponent implements OnInit {
  @Input() oferta_variacion: any;
  @Input() loading: boolean;
  @Input() primerLevelCode: any;

  information: any;
  imageDownload: any;
  description: any;
  source: any;  
  technical_note: any;

  constructor() {
    this.loading = true;
    //this.information = {};
  }

  ngOnInit(): void {
    this.load();
  }

  async load() {

    if (this.primerLevelCode === undefined) {
      await new Promise((f) => setTimeout(f, 400));
      this.load();
      return;
    }

    if (this.primerLevelCode ==='0') {
      this.description = "Representa la variación en la cantidad de trabajadores en el mes actual respecto al mismo mes del año anterior en el sector público que se desempeñan en la ocupación seleccionada.";
      this.source = 'MTPE-Planilla Electrónica (PLAME y T-Registro) del sector público. La información corresponde a los asalariados del sector público.';
    } else 
    {
      this.description = "Representa la variación en la cantidad de trabajadores en el mes actual respecto al mismo mes del año anterior en el sector formal privado que se desempeñan en la ocupación seleccionada.";
      this.source = 'MTPE-Planilla Electrónica (PLAME y T-Registro) del sector privado. La información corresponde a los asalariados del sector formal privado.';      
    } 

    this.information = {
      buttonsInNewLine: true,
      technical_note: `<p align="justify">La variación porcentual reportada corresponde al total de puestos de trabajo registrados en el mes actual respecto al mismo mes del año anterior.</p>`,
      id: 'variacion-puestos',
      response: {
        title: 'Variación de puestos de trabajo',
        description: this.description,
        source: this.source,
      },

      type: 'ColumnChart',
      columns: [
        { type: 'string', role: 'domain', label: 'Month' },
        { type: 'number', role: 'data', label: 'Positivo' },
        { type: 'string', role: 'annotation', label: 'Porcentaje' },
        { type: 'number', role: 'data', label: 'Negativo' },
        { type: 'string', role: 'annotation', label: 'Porcentaje' },
      ],
      options: {
        isStacked: true,
        colors: ['#3C63B0', '#B13C3C'],
        fill: 25,
        legend: { position: 'none' },
        vAxis: {
          gridlineColor: 'none',
          textPosition: 'none',
        },
        hAxis: {
          textStyle: {
            fontSize: 12,
            color: '#727B7D',
          },
        },
        tooltip: {
          trigger: 'none',
        },
        annotations: {
          stemColor: 'none',
          alwaysOutside: true,
        },
        series: {
          0: {
            annotations: {
              textStyle: {
                strokeWidth: 0,
                fontSize: 12,
              },
            },
          },
          1: {
            annotations: {
              textStyle: {
                strokeWidth: 0,
                fontSize: 12,
              },              
              stem: {
                length: -40,
              },
            },
          },
        },
        chartArea: {
          left: 20,
          right: 20,
          top: 20,
          bottom: 40,
        },
      },
    };
  }
}
