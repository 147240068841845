import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-publications-month',
  templateUrl: './publications-month-report.component.html',
  styleUrls: ['./publications-month-report.component.scss'],
})
export class PublicationsMonthComponent implements OnInit, OnDestroy {
  paramsSubscription: Subscription;
  publicationTypeId: any;

  headers: any;
  publications: any[];
  periods: any;
  months: any;
  monthsSelection: any;
  monthsName: any;
  formats: any;
  formatsSelection: any;

  periodSelected: any;
  strapiUrl: string;
  downloading: boolean;
  enableDownload: boolean;

  constructor(private router: ActivatedRoute, private apiService: ApiService) {
    this.paramsSubscription = Subscription.EMPTY;
    this.publicationTypeId = '';

    this.headers = null;
    this.publications = [];
    this.periods = [];
    this.months = [];
    this.monthsSelection = [];
    this.formats = [];
    this.formatsSelection = [];

    this.monthsName = [
      '',
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ];

    this.periodSelected = '';
    this.strapiUrl = environment.strapiUrl;
    this.downloading = false;
    this.enableDownload = false;
  }

  ngOnInit(): void {
    this.paramsSubscription = this.router.params.subscribe(
      ({ publicationTypeId }) => {
        this.publicationTypeId = publicationTypeId;

        this.getPublicationsData(publicationTypeId);
      }
    );
  }

  async getPublicationsData(publicationTypeId: any) {
    let publicationType = await this.apiService.getMany(
      `/publications-types/${publicationTypeId}&_limit=-1`
    );

    let publications = await this.apiService.getMany(
      `/publications?publications_type.id=${publicationTypeId}&_sort=year:DESC,month:ASC&_limit=-1`
    );

    this.headers = publicationType;
    this.publications = publications;

    let publicationsYears = publications.map(
      (publication: any) => publication.year
    );
    let uniquePeriods = [...new Set(publicationsYears)];
    this.periods = uniquePeriods;
    this.periodSelection(this.periods[0]);
  }

  periodSelection(period: any) {
    this.periodSelected = period;
    let publicationsAvailable = this.publications.filter(
      (publication: any) => publication.year === period
    );

    if (this.headers.periodicity == 'Anual') {
      let year = this.periodSelected;
      let publications = this.publications.filter(
        (publication: any) => publication.year === year
      );

      let formatsAvailable = publications.map(
        (publication: any) => publication.extension
      );
      let uniqueFormats = [...new Set(formatsAvailable)];
      this.formats = uniqueFormats;
    } else {
      let monthsAvailable = publicationsAvailable.map(
        (publication: any) => publication.month
      );
      let uniqueMonths = [...new Set(monthsAvailable)];

      this.months = uniqueMonths;
      this.monthsSelection = [];
    }
  }

  monthSelection(month: any) {
    if (!this.monthsSelection.includes(month)) {
      this.monthsSelection.push(month);
    } else {
      this.monthsSelection.splice(this.monthsSelection.indexOf(month), 1);
    }

    let year = this.periodSelected;
    let months = this.monthsSelection;

    let publications = this.publications.filter(
      (publication: any) =>
        publication.year === year && months.includes(publication.month)
    );

    let formatsAvailable = publications.map(
      (publication: any) => publication.extension
    );
    let uniqueFormats = [...new Set(formatsAvailable)];
    this.formats = uniqueFormats;
  }

  formatSelection(format: any) {
    if (!this.formatsSelection.includes(format)) {
      this.formatsSelection.push(format);
    } else {
      this.formatsSelection.splice(this.formatsSelection.indexOf(format), 1);
    }

    if (this.formatsSelection.length > 0) {
      this.enableDownload = true;
    } else {
      this.enableDownload = false;
    }
  }

  async download() {
    this.enableDownload = false;
    this.downloading = true;
    try {
      let year = this.periodSelected;
      let months = this.monthsSelection;
      let formats = this.formatsSelection;

      let publications;
      if (this.headers.periodicity == 'Anual') {
        publications = this.publications.filter(
          (publication: any) =>
            publication.year === year && formats.includes(publication.extension)
        );
      } else {
        publications = this.publications.filter(
          (publication: any) =>
            publication.year === year &&
            months.includes(publication.month) &&
            formats.includes(publication.extension)
        );
      }

      let linkDownload = '';

      if (publications.length === 1) {
        linkDownload = publications[0].link;
      } else {
        let links = publications.map(
          (publication) => `files=${encodeURIComponent(publication.link)}`
        );
        let queryString = links.join('&');
        let response = await this.apiService.getMany(
          `/files/zip?${queryString}`
        );
        linkDownload = this.strapiUrl + response.link;
      }

      let download = document.createElement('a');
      download.href = linkDownload;
      download.target = '_blank';
      download.click();
      this.enableDownload = true;
      this.downloading = false;
    } catch (error) {
      this.enableDownload = true;
      this.downloading = false;
    }
  }

  ngOnDestroy(): void {
    this.paramsSubscription.unsubscribe();
  }
}
