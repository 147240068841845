<div class="mt-4">
  <h3 class="text-attention">Formación académica</h3>
  <div class="mt-4 row">
    <div class="nav nav-tabs mt-2" id="nav-tab" role="tablist">
      <button
        *ngFor="let type_instituion of type_institutions; let i = index"
        class="nav-link fw-bold"
        [ngClass]="{ active: i === 0 }"
        [id]="'nav-' + type_instituion.id + '-tab'"
        data-bs-toggle="tab"
        [attr.data-bs-target]="'#nav-' + type_instituion.id"
        type="button"
        role="tab"
        [attr.aria-controls]="'nav-' + type_instituion.id"
        aria-selected="false"
      >
        {{ type_instituion.name | titlecase }}
      </button>
    </div>

    <div class="tab-content" id="nav-tabContent">
      <div
        *ngFor="let type_instituion of type_institutions; let i = index"
        class="tab-pane fade"
        [ngClass]="{ 'show active': i === 0 }"
        [id]="'nav-' + type_instituion.id"
        role="tabpanel"
        [attr.aria-labelledby]="'nav-' + type_instituion.id + '-tab'"
      >
        <div class="row">
          <ng-container *ngFor="let career of career_names">
            <div
              *ngIf="career.code_type_institute === type_instituion.id"
              class="col-6"
            >
              <div class="card mt-4" style="height: 97%;">
                <div class="card-body">
                  <span class="card-title fs-16 fw-bold">
                    {{ career.name | titlecase }}</span
                  >
                  <ul>
                    <ng-container *ngFor="let career_institute of career_info">
                      <li
                        *ngIf="career_institute.code_career === career.id"
                        class="mt-2"
                      >
                        {{ career_institute.name_institution | titlecase }}
                      </li>
                    </ng-container>
                  </ul>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
