<div class="card two-columns">
  <div class="row">
    <div class="col-12 col-sm-12 col-md-4 col-lg-3 image">
      <img
        class="lazyload"
        alt=""
        [attr.data-src]="imageBase + information.image[0].url"
      />
    </div>
    <div class="col-12 col-sm-12 col-md-8 col-lg-9">
      <div class="card-body">
        <h4>{{ information.title }}</h4>
        <p class="description" [innerHTML]="information.description"></p>
      </div>
      <div class="card-footer">
        <a class="text-primary" [href]="information.url" target="_blank"
          >Ir al sitio <mat-icon svgIcon="open-in-new"></mat-icon
        ></a>
      </div>
    </div>
  </div>
</div>
