<div class="modal-dialog modal-xl" style="min-width:90%;">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Comparador de Ocupaciones</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      <button class="btn btn-outline-primary fs-16 fw-bold pb-2 pt-2"
        (click)="Limpiar()">
        Limpiar selección
      </button>
        <table class="table mt-1">
          <thead class="">
            <tr>
              <th style="width:18%;vertical-align: middle;text-align: center;">ASPECTOS A COMPARAR</th>
              <th style="width:25%;">
                <label for="exampleFormControlInput1" class="form-label">Ocupación 1</label>
                <div class="ng-autocomplete">
                    <ng-autocomplete
                      #auto1
                      [data]="ocupaciones"
                      name="name1"
                      [(ngModel)]="name1"
                      [initialValue]=""
                      [searchKeyword]="keyword"
                      (inputCleared)="onCleared_1($event)"
                      (inputFocused)="onFocused($event)"
                      placeholder="Escribe la ocupación"
                      (selected)="selectEvent_1($event)"
                      (inputChanged)='onChangeSearch($event)'                          
                      [itemTemplate]="itemTemplate"
                      notFoundText="No se encontraron resultados."
                      [notFoundTemplate]="notFoundTemplate"
                      [minQueryLength]="2"
                      [focusFirst]="true"
                    >
                    </ng-autocomplete>

                    <ng-template #itemTemplate let-item>
                        <a [innerHTML]="item.name"></a>
                      </ng-template>
                      <ng-template #notFoundTemplate let-notFound>
                        <div [innerHTML]="notFound"></div>
                    </ng-template>                        
                </div>
              </th>
              <th style="width:25%;">
                <label for="exampleFormControlInput1" class="form-label">Ocupación 2</label>                
                <div class="ng-autocomplete" id="1">
                  <ng-autocomplete
                    #auto2
                    [data]="ocupaciones"
                    name="name2"
                    [(ngModel)]="name2"
                    [searchKeyword]="keyword"
                    (inputCleared)="onCleared_2($event)"
                    (inputFocused)="onFocused($event)"
                    placeholder="Escribe la ocupación"
                    (selected)="selectEvent_2($event)"
                    (inputChanged)='onChangeSearch($event)'                          
                    [itemTemplate]="itemTemplate"
                    notFoundText="No se encontraron resultados."
                    [notFoundTemplate]="notFoundTemplate"
                    [minQueryLength]="2"
                    [focusFirst]="true"
                >
                </ng-autocomplete>
                  </div>                


              </th>
              <th style="width:25%;">
                <label for="exampleFormControlInput1" class="form-label">Ocupación 3</label>                
                <div class="ng-autocomplete">
                    <ng-autocomplete
                    #auto3
                    [data]="ocupaciones"
                    name="name3"
                    [(ngModel)]="name3"
                    [searchKeyword]="keyword"
                    (inputCleared)="onCleared_3($event)"
                    (inputFocused)="onFocused($event)"
                    placeholder="Escribe la ocupación"
                    (selected)="selectEvent_3($event)"
                    (inputChanged)='onChangeSearch($event)'                          
                    [itemTemplate]="itemTemplate"
                    notFoundText="No se encontraron resultados."
                    [notFoundTemplate]="notFoundTemplate"
                    [minQueryLength]="2"
                    [focusFirst]="true"
                  >
                  </ng-autocomplete>
                  </div>                
              </th>
              <th style="width:7%;vertical-align: middle;text-align: center;">
                <label for="exampleFormControlInput1" class="form-label">TOTAL PAÍS</label>                
              </th>              
            </tr>
          </thead>
          <tbody>
            <tr>
              <th style="background-color: #0056ac;color: white;align-content: center;vertical-align: middle;text-align: center;">Cantidad de Trabajadores 1/</th>
              <td><span *ngIf="ocupacion1.length !== 0">{{ ocupacion1.total_trabajadores }} trabajadores</span></td>
              <td><span *ngIf="ocupacion2.length !== 0">{{ ocupacion2.total_trabajadores }} trabajadores</span></td>
              <td><span *ngIf="ocupacion3.length !== 0">{{ ocupacion3.total_trabajadores }} trabajadores</span></td>
              <td><span *ngIf="total_pais.length !== 0">{{ total_pais.total_trabajadores }} trabajadores</span></td>              
            </tr>
            <tr>
              <th style="background-color: #0056ac;color: white;align-content: center;vertical-align: middle;text-align: center;">Remuneración promedio mensual</th>                
              <td><span *ngIf="ocupacion1.length !== 0">S/ {{ ocupacion1.sueldo }}</span></td>
              <td><span *ngIf="ocupacion2.length !== 0">S/ {{ ocupacion2.sueldo }}</span></td>
              <td><span *ngIf="ocupacion3.length !== 0">S/ {{ ocupacion3.sueldo }}</span></td>
              <td><span *ngIf="total_pais.length !== 0">S/ {{ total_pais.sueldo }}</span></td>
            </tr>
            <tr>
                <th style="background-color: #0056ac;color: white;align-content: center;vertical-align: middle;text-align: center;">Participación de mujeres</th>                
                <td><span *ngIf="ocupacion1.length !== 0">{{ ocupacion1.tasa_mujeres }}%</span></td>
                <td><span *ngIf="ocupacion2.length !== 0">{{ ocupacion2.tasa_mujeres }}%</span></td>
                <td><span *ngIf="ocupacion3.length !== 0">{{ ocupacion3.tasa_mujeres }}%</span></td>                
                <td><span *ngIf="total_pais.length !== 0">{{ total_pais.tasa_mujeres }}%</span></td>                
              </tr>            
            <tr>
                <th style="background-color: #0056ac;color: white;align-content: center;vertical-align: middle;text-align: center;">Edad promedio</th>
                <td><span *ngIf="ocupacion1.length !== 0">{{ ocupacion1.edad_promedio }} años</span></td>
                <td><span *ngIf="ocupacion2.length !== 0">{{ ocupacion2.edad_promedio }} años</span></td>
                <td><span *ngIf="ocupacion3.length !== 0">{{ ocupacion3.edad_promedio }} años</span></td>          
                <td><span *ngIf="total_pais.length !== 0">{{ total_pais.edad_promedio }} años</span></td>          
              </tr>          
            <tr>
                <th style="background-color: #0056ac;color: white;align-content: center;vertical-align: middle;text-align: center;">% de Crecimiento 2/</th>
                <td><span *ngIf="ocupacion1.length !== 0">{{ ocupacion1.tasa_crecimiento }}%</span></td>
                <td><span *ngIf="ocupacion2.length !== 0">{{ ocupacion2.tasa_crecimiento }}%</span></td>
                <td><span *ngIf="ocupacion3.length !== 0">{{ ocupacion3.tasa_crecimiento }}%</span></td>                
                <td><span *ngIf="total_pais.length !== 0">{{ total_pais.tasa_crecimiento }}%</span></td>                
              </tr>                             
            <tr>
                <th style="background-color: #0056ac;color: white;align-content: center;vertical-align: middle;text-align: center;">Empresas que contratan 3/</th>
                <td><span *ngIf="ocupacion1.length !== 0">{{ ocupacion1.empresas_contratan }} empresas</span></td>
                <td><span *ngIf="ocupacion2.length !== 0">{{ ocupacion2.empresas_contratan }} empresas</span></td>
                <td><span *ngIf="ocupacion3.length !== 0">{{ ocupacion3.empresas_contratan }} empresas</span></td>
                <td><span *ngIf="total_pais.length !== 0">{{ total_pais.empresas_contratan }} empresas</span></td>
              </tr>                             
        </tbody>
        </table>
  
        <div style="font-size: 12px;">
          <label class="my-0 py-0"><strong>Nota:</strong></label><br>
          <label align="justify">Información actualizada a Noviembre 2022 de la Planilla Electrónica (PLAME y T-Registro).</label>
          <p align="justify">1/ La información corresponde a los asalariados del sector formal privado.<br>
                             2/ Proyección de Planilla Electrónica basada en el crecimiento del número de puestos de trabajo registrados durante los últimos periodos mensuales del año vigente, respecto del mismo periodo del año anterior.<br>
                             3/ Representa la cantidad de empresas que registran al menos un trabajador que se desempeñan en la ocupación seleccionada. En el caso del total país se refiere al total de empresas registradas.
          </p>
          <p><strong>Fuente: </strong>MTPE - Planilla Electrónica (PLAME y T-Registro).<br>
             <strong>Elaboración: </strong>MTPE - DGPE - Dirección de Investigación Socio Económico Laboral (DISEL).</p>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary view-custom" data-bs-dismiss="modal">Cerrar</button>
      </div>
    </div>
  </div>