<div class="row">
  <div class="col-sm-12 col-lg-6" *ngFor="let link of information.results">
    <div class="card card-half">
      <div class="row">
        <div class="col-7 il-text">
          <h3>{{ link.title }}</h3>
          <a
            class="text-primary card-footer"
            href="{{ link.url }}"
            target="_blank"
            rel="noopener"
            >{{ link.link_text
            }}<mat-icon class="ms-2" svgIcon="open-in-new"></mat-icon
          ></a>
        </div>
        <div class="col-5">
          <img
            class="lazyload"
            style="max-width: 217px"
            width="100%"
            [attr.data-src]="imageBase + link.image[0].url"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</div>
