import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';


import { OccupationalComponent } from './occupational.component';
import { JobTrainingComponent } from './job-training/job-training.component';
import { ScholarshipsComponent } from './scholarships/scholarships.component';
import { OccupationsRoutingModule } from './occupational-routing.module';
import { AdvancedStudiesComponent } from './advanced-studies/advanced-studies.component';
import { EmpreSupportComponent } from './empre-support/empre-support.component';
import { LookingJobComponent } from './looking-job/looking-job.component';
import { LaborRightsComponent } from './labor-rights/labor-rights.component';
import { LaborTrainingModalitiesComponent } from './labor-training-modalities/labor-training-modalities.component';
import { PreProfessionalComponent } from './pre-professional-professional/pre-professional-professional.component';
import { OtherModalitiesComponent } from './other-labor-training/other-labor-training.component';
import { JobNumbersComponent } from './job-numbers/job-numbers.component';
import { JobNumberComponent } from './job-number/job-number.component';
import { TopTenComponent } from './job-numbers/topten/topten.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { PieJobComponent } from './job-numbers/pie-job/pie-job.component';
import { PieContractComponent } from './job-numbers/pie-contract/pie-contract.component';
import { StackExperienceComponent } from './job-numbers/stack-experience/stack-experience.component';
import { StackEducationComponent } from './job-numbers/stack-education/stack-education.component';

@NgModule({
  declarations: [
    OccupationalComponent,
    AdvancedStudiesComponent,
    JobTrainingComponent,
    ScholarshipsComponent,
    EmpreSupportComponent,
    LookingJobComponent,
    LaborRightsComponent,
    LaborTrainingModalitiesComponent,
    PreProfessionalComponent,
    OtherModalitiesComponent,
    JobNumbersComponent,
    JobNumberComponent,    
    TopTenComponent,
    PieJobComponent,
    PieContractComponent,
    StackExperienceComponent,
    StackEducationComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    OccupationsRoutingModule,
    GoogleChartsModule,
  ],
  exports: [],
})
export class OccupationalModule {}
