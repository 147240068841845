<div
  style="margin-bottom: 1rem"
  class="col"
  *ngFor="let chart of information.charts"
>
  <google-chart
    style="width: 100%; height: 360px"
    [type]="chartType"
    [data]="chart.data"
    [columns]="chartColumns"
    [options]="chartOptions"
  >
  </google-chart>
</div>
