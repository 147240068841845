import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { environment } from '../../environments/environment';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-tablero',
  templateUrl: './tablero.component.html',
  styleUrls: ['./tablero.component.scss']
})
export class TableroComponent implements OnInit {

  jumbotron: any;
  tableroDemanda: any;
  tableroOcupacional: any;
  currentTablero: string;
  videoUrl: any;
  videoUrl2: any;
  FlagTablero: any;

  constructor(
    private apiService: ApiService,private sanitizer: DomSanitizer

  ) {
    this.jumbotron = {};
    this.tableroDemanda = {};
    this.tableroOcupacional = {};
    this.currentTablero = 'tablero_1';
    this.videoUrl = '';
    this.videoUrl2 = '';
    this.FlagTablero = 1;

   }

  ngOnInit(): void {
    this.getJumbotron();
    this.getTableroDemanda();
    this.getTableroOcupacional();        
  }

  async getJumbotron() {
    this.jumbotron = await this.apiService.getOne(`/banners?code=tableros-mando`);

    if (this.jumbotron) {

      this.jumbotron.url = environment.strapiUrl + this.jumbotron.image[0].url;
    } else {
      this.jumbotron = {};
    }
  }  


  async getTableroDemanda() {
    this.tableroDemanda = await this.apiService.getMany(
      `/banner-urls?code=demanda_ocupacional_futura`
    );

    this.videoUrl = 
    this.sanitizer.bypassSecurityTrustResourceUrl(this.tableroDemanda[0].url);

  }  

  async getTableroOcupacional() {
    this.tableroOcupacional = await this.apiService.getMany(
      `/banner-urls?code=estructura_ocupacional_planilla`
    );

    this.videoUrl2 = 
    this.sanitizer.bypassSecurityTrustResourceUrl(this.tableroOcupacional[0].url);    

  }    


  getActualTablero(currentTablero: string) {
    this.currentTablero = currentTablero;

  }

}
