import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import axios from 'axios';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  async getOne(resource: string) {
    try {
      let config: any = {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'c9b6c7b407d7d47bffecc0a18fb39f23',
          'sec-fetch-mode': 'cors',
          'sec-fetch-site': 'cross-site',
        },
      };
      let response: any = await axios.get(
        `${environment.strapiUrl}${resource}`
      );
      return response.data[0];
    } catch (error) {
      // handle error
      console.log(error);
      return null;
    }
  }

  async getMany(resource: string) {
    try {
      let config: any = {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'c9b6c7b407d7d47bffecc0a18fb39f23',
          'sec-fetch-mode': 'cors',
          'sec-fetch-site': 'cross-site',
        },
      };
      let response: any = await axios.get(
        `${environment.strapiUrl}${resource}`
      );
      return response.data;
    } catch (error) {
      // handle error
      console.log(error);
      return null;
    }
  }

  async getManyBackend(resource: string, params: any) {
    try {
      let config: any = {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': '65c3a8fd4bac97f7fc145f773191ac9cdev',
        },
        params: params,
      };
      let response: any = await axios.get(
        `${environment.backendUrl}${resource}`,
        config
      );
      return response.data;
    } catch (error) {
      // handle error
      console.log(error);
      return null;
    }
  }

  async post(resource: string, request: any) {
    try {
      let response = await axios.post(
        `${environment.strapiUrl}${resource}`,
        request
      );
      return response.data;
    } catch (error) {
      // handle error
      console.log(error);
      return null;
    }
  }
}
