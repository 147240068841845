<app-loading *ngIf="loading"></app-loading>
<div class="mt-4 mb-4" *ngIf="!loading">
  <h4>{{ head.title }}</h4>
  <p class="mt-3 fs-16">{{ head.description }}</p>

  <div class="row mt-4">
    <div class="col-sm-12 col-lg-3" *ngFor="let region of paginatedList">
      <div class="card image">
        <div class="row">
          <div class="col-sm-12">
            <div class="card-header">
              <img
                class="lazyload"
                style="height: 202px !important"
                [attr.data-src]="imageBase + region.imageCard.formats.thumbnail.url"
                alt=""/>
            </div>
            <div class="card-body">
              <h4>{{ region.name }}</h4>
            </div>
            <div class="card-footer">
              <a class="text-primary fw-bold" routerLink="/recursos/observatorios-regionales/{{ region.id }}">
                Ver Publicaciones
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-end">
      <button
        class="btn btn-sm btn-link fw-bold"
        style="margin: 0rem 0.2rem; padding: 0rem 0.4rem"
        [ngClass]="currentPage <= 1 ? 'link-disabled' : ''"
        (click)="OnPaginate(currentPage - 1)"
      >
        < Anterior
      </button>

      <button
        class="btn btn-sm btn-link fw-bold"
        style="margin: 0rem 0.2rem; padding: 0rem 0.4rem"
        [ngClass]="page === currentPage ? 'current-page' : ''"
        (click)="OnPaginate(page)"
        *ngFor="let page of pagesList"
      >
        {{ page }}
      </button>

      <button
        class="btn btn-sm btn-link fw-bold"
        style="margin-left: 0.2rem; padding: 0rem 0.4rem"
        [ngClass]="currentPage === pagesList.length ? 'link-disabled' : ''"
        (click)="OnPaginate(currentPage + 1)"
        *ngIf="pagesList.length > 1"
      >
        Siguiente >
      </button>
    </div>
  </div>
</div>
