import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-home-indicators',
  templateUrl: './indicators.component.html',
  styleUrls: ['./indicators.component.scss'],
})
export class IndicatorsComponent implements OnInit {
  information: any;
  loading: any;
  indicators: any;

  constructor(private apiService: ApiService) {
    this.loading = true;
    this.indicators = [];
  }

  ngOnInit(): void {
    this.getIndicators();
  }

  async getIndicators() {
    let indicatorsData = (
      //await this.apiService.getManyBackend('/homeIndicators', null)
      await this.apiService.getManyBackend('/homeIndicators/pais', null)
    ).data;

    this.information = {
      title: 'Conoce el panorama laboral en el país',
      source:
        // `Los primeros 5 indicadores provienen de la Encuesta Nacional de Hogares (ENAHO) - (${indicatorsData.enaho_period}) y el último de la Planilla Electrónica - (${indicatorsData.plane_period}).`,
        `Los primeros 5 indicadores provienen de la Encuesta Nacional de Hogares (ENAHO) - (${indicatorsData.enaho_period}) y el último de la Planilla Electrónica - (Promedio Enero-Noviembre 2022).`,        
    };

    this.indicators = [
      {
        value: `${Math.round(indicatorsData.tasa_actividad * 10) / 10}%`,
        description: 'Tasa de <br> actividad',
        icon: 'account-group',
      },
      {
        value: `${Math.round(indicatorsData.tasa_subempleo * 10) / 10}%`,
        description: 'Tasa de <br>subempleo',
        icon: 'timelapse',
      },
      {
        value: `${Math.round(indicatorsData.tasa_desempleo * 10) / 10}%`,
        description: 'Tasa de <br>desempleo',
        icon: 'briefcase-off',
      },
      {
        value: `${Math.round(indicatorsData.tasa_informalidad * 10) / 10}%`,
        description: 'Tasa de empleo <br>informal',
        icon: 'text-box-remove',
      },
      {
        value: `S/ ${Math.round(indicatorsData.remuneracion_promedio)}`,
        description: 'Ingreso laboral<br>promedio mensual <br>de la población<br>ocupada',
        icon: 'cash',
      },
      {
        value: `${Math.round(indicatorsData.trabajador_sector_formal / 1000000 * 10)/10} M`,
        description:
          'Trabajadores <br>del sector formal<br> privado asalariado',
        icon: 'account-supervisor',
      },
    ];

    this.loading = false;
  }
}
