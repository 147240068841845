<app-loading *ngIf="loading"></app-loading>
<div *ngIf="!loading">
  <app-jumbotron [information]="jumbotron"></app-jumbotron>
  <div class="container">
    <div class="row mt-5">
      <h3 class="text-attention mt-5">{{ information.results.title }}</h3>
      <div class="col-12 col-md-12 col-lg-8">
        <p [innerHTML]="information.results.description"></p>
      </div>
      <div class="col-12 col-md-12 col-lg-4 align-self-start">
        <div class="mb-4">
          <h4>{{ information.results.subtitle }}</h4>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="mt-1">
              <div>
                <h4>{{ information.results.team_name }}</h4>
              </div>
            </div>
            <div class="mt-3">
              <span class="text-secondary fs-14">Dirección:</span>
              <div>
                <span>{{ information.results.address }}</span>
              </div>
            </div>
            <div class="mt-2">
              <span class="text-secondary fs-14">Teléfono:</span>
              <div>
                <span>{{ information.results.phone }}</span>
              </div>
            </div>
            <div class="mt-2">
              <span class="text-secondary fs-14">Correo:</span>
              <div>
                <span>{{ information.results.email }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
