import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-detail-datos-tendencias',
  templateUrl: './datos-tendencias.component.html',
  styleUrls: ['./datos-tendencias.component.scss'],
})
export class DatosTendenciasComponent implements OnInit {
 

  @Input() occupation: any;
  @Input() secondLevel: any;  
  ngOnInit(): void {
  }

}

