import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss'],
})
export class ResourcesComponent implements OnInit {
  jumbotron: any;

  constructor(private router: Router, private apiService: ApiService) {
    this.jumbotron = {};
  }

  ngOnInit(): void {
    this.getJumbotron();
  }

  async getJumbotron() {
    this.jumbotron = await this.apiService.getOne(
      `/banners?code=publicaciones-microdatos`
    );
    if (this.jumbotron) {
      this.jumbotron.url = environment.strapiUrl + this.jumbotron.image[0].url;
    } else {
      this.jumbotron = {};
    }
  }
}
