<div class="mb-4 mt-4">
  <a class="breadcrumb-link fw-bold" routerLink="/recursos/publicaciones">Publicaciones</a>
  <span class="iconify text-secondary ms-1 me-1" [attr.data-icon]="'mdi:chevron-right'"></span>
  <span class="fs-13 fw-bold text-secondary">{{ headers !== null ? headers.name : "" }}</span>
</div>

<div class="row bg-white mb-5">
  <div class="col-2">
    <img *ngIf="headers !== null" width="100%" class="" [src]="strapiUrl + headers.image[0].url" alt="" />
  </div>
  <div class="col-10">
    <h3 class="mb-4 fw-bold">{{ headers !== null ? headers.name : "" }}</h3>
    <div [innerHTML]="headers !== null ? headers.description : ''"></div>
    <div [innerHTML]="headers !== null ? headers.content : ''"></div>
    <p class="pt-3 fs-12 text-secondary fw-bold">
      {{ headers !== null ? headers.periodicity : "" }}
      {{ headers !== null ? "| Fuente de información: " + headers.source_information : "" }}
      {{ headers!==null ? "| Última actualización: " + formatLastUpdatedDate(headers.last_updated_date) : "" }}
    </p>
  </div>
</div>
