import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-discover',
  templateUrl: './discover.component.html',
  styleUrls: ['./discover.component.scss'],
})
export class DiscoverComponent implements OnInit {
  jumbotron: any;

  constructor(private apiService: ApiService) {
    this.jumbotron = {};
  }

  ngOnInit(): void {
    this.getJumbotron();
  }

  async getJumbotron() {
    this.jumbotron = await this.apiService.getOne(
      `/banners?code=descubre-ocupaciones`
    );
    if (this.jumbotron) {
      this.jumbotron.url = environment.strapiUrl + this.jumbotron.image[0].url;
    } else {
      this.jumbotron = {};
    }
  }
}
