<div class="container">
  <br /><br />
  <app-buttons></app-buttons>
  <br /><br />
  <app-fonts></app-fonts>
  <br /><br />
  <app-components></app-components>
  <br /><br />
  <app-cards></app-cards>
</div>
