<app-loading *ngIf="loading"></app-loading>
<div *ngIf="!loading">
  <div *ngIf="isFilterSelected">
    <div #cardDiv class="scroll-view card">
      <div>
        <h4 class="fw-bold">{{ chartInformation.title }}</h4>
      </div>
      <div class="mt-2">
        <div [innerHTML]="chartInformation.description
          ? chartInformation.description
          : 'Registro de descripción pendiente'">
      </div>
      </div>
      <div class="mt-3 button-group">
        <button class="mb-2" *ngFor="let btn of indicatorIds" type="button"
        [ngClass]="{ 'active': selectedIndicator == btn.id }"  
        (click)="changeChart(btn.id)">{{ btn.type }}</button>
      </div>
      <app-shared-linechart #lineChart [information]="chartInformation"></app-shared-linechart>
      <div class="mt-4">
        <mat-icon class="icon-color" svgIcon="information"></mat-icon>
        <span class="source fw-bold">
          {{ chartInformation.source ? chartInformation.source : 'Registro de fuente pendiente' }}
        </span>
      </div>
    </div>
    <div class="mt-4 text-center text-sm-center text-md-end">
      <button type="button" class="btn btn-outline-primary" (click)="downloadExcel()">
        <mat-icon svgIcon="file-download-outline"></mat-icon>Descargar datos
      </button>
      <button type="button" class="btn btn-outline-primary ms-4 ms-md-4" (click)="downloadImage()">
        <mat-icon svgIcon="image-outline"></mat-icon>Guardar imagen
      </button>
      <button type="button" class="btn btn-outline-primary mt-4 mt-sm-4 mt-md-0 ms-md-4" data-bs-toggle="modal"
        data-bs-target="#outlookModal">
        <mat-icon svgIcon="note-text-outline"></mat-icon>Ver nota técnica
      </button>
    </div>
  </div>
  <div *ngIf="!isFilterSelected">
    <div #cardDiv class="scroll-view col-12" style="padding: 1.1rem; border: 1px solid #dee3ea">
      <span class="fs-14"><strong>Tip:</strong> Seleccione 1 tipo de filtro para obtener información.
      </span>
    </div>
  </div>
</div>
<div class="modal fade" id="outlookModal" tabindex="-1" data-bs-backdrop="static" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Nota técnica</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body"
        [innerHTML]="chartInformation.technicalNotes
          ? chartInformation.technicalNotes 
          : 'Registro de nota técnica pendiente'">
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary view-custom" data-bs-dismiss="modal">cerrar</button>
      </div>
    </div>
  </div>
</div>
