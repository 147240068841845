import { Component, Input, OnInit } from '@angular/core';
import { ChartType } from 'angular-google-charts';

@Component({
  selector: 'app-shared-linechart',
  templateUrl: './linechart.component.html',
  styleUrls: ['./linechart.component.scss'],
})
export class LineChartComponent implements OnInit {
  @Input() information: any;

  loading: boolean;
  lcImageDownload: any;
  lcMyType = ChartType.LineChart;
  lcMyOptions: any = {};
  lcMyColumns: any = [];
  lcMyData: any = [];
  width: any;

  constructor() {
    this.loading = true;
    this.width = '100%';
  }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.lcMyOptions = {
      legend: {
        position: 'none',
      },
      pointsVisible: true,
      vAxis: {
        textStyle: {
          fontSize: 12,
          color: '#727B7D',
        },
      },
      hAxis: {
        textStyle: {
          fontSize: 12,
          color: '#727B7D',
        },
      },
      tooltip: {
        trigger: 'none',
      },
      series: {
        0: {
          annotations: {
            textStyle: {
              strokeWidth: 0,
              fontSize: 12,
              color: '#3C63B0',
            },
          },
        },
      },
      annotations: {
        stemColor: 'none',
        alwaysOutside: true,
      },
      chartArea: {
        left: 75,
        right: 20,
        top: 20,
        bottom: 20,
      },
    };
    this.lcMyColumns = this.information.columns;
    this.lcMyData = this.information.data;
    let basePX = 75;
    console.log("this.lcMyData");
    console.log(this.lcMyData);    
    if (this.lcMyData.length > 15) {
      this.width = `${this.lcMyData.length * basePX}px`;
    }
    this.loading = false;
  }

  onLineChartReady($event: any) {
    this.lcImageDownload = $event.chart.getImageURI();
  }
}
