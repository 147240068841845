<!-- <button class="btn btn-primary"
  (click)="abriComparador()">
  Abrir Comparador 0
</button> -->

<!-- Button trigger modal -->
<button type="button" class="btn btn-primary btn-flotante" data-bs-toggle="modal" data-bs-target="#exampleModal">
  Abrir Comparador
</button>
<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <app-comparador></app-comparador>
</div>

<div id="categoria"></div>
<div class="row">
  <div class="col-12 container">
    <div class="mt-2 mb-4">
      <div class="ng-autocomplete">
        <ng-autocomplete
          #auto
          [data]="ocupaciones"
          name="name1"
          [(ngModel)]="name1"
          [initialValue]=""          
          [searchKeyword]="keyword"
          (inputCleared)="onCleared($event)"
          (inputFocused)="onFocused($event)"
          placeholder="Escribe la ocupación. Ejm: Técnico en electricidad"
          (selected)="selectEvent($event)"
          [itemTemplate]="itemTemplate"
          notFoundText="No se encontraron resultados."
          [notFoundTemplate]="notFoundTemplate"
          [minQueryLength]="2"
          [focusFirst]="true"
        >
        </ng-autocomplete>
        <button class="btn btn-primary button-custom-search" type="button">
          <mat-icon class="text-inverted" svgIcon="magnify"></mat-icon>
        </button>
      </div>
      <ng-template #itemTemplate let-item>
        <a [innerHTML]="item.name"></a>
      </ng-template>
      <ng-template #notFoundTemplate let-notFound>
        <div [innerHTML]="notFound"></div>
      </ng-template>
    </div>
  </div>
</div>

<div class="row mt-4">
  <div class="mb-5 col-sm-12 col-xl-3">
    <h5 class="text-start fw-bold mb-4 fs-20">Categorías</h5>
    <div class="list-group">
      <a style="cursor: pointer;"
        *ngFor="let category of categories"
        class="list-group-item list-group-item-action"
        (click)="selectCategory(category.id)"
        [ngClass]="category.id === currentCategory ? 'active' : ''"
        >{{ category.name }}
      </a>
    </div>

    <div class="row">
      <div class="col-lg-12 mt-3" *ngFor="let banner of banners">
        <div class="image-container" (click)="redirect(banner.url)">
          <img
            class="lazyload"
            [attr.data-src]="strapiUrl + banner.image[0].url"
            alt=""
          />
          <span class="centered text-inverted fw-bold col-12 col-sm-12">
            {{ banner.title }}&nbsp;<mat-icon
              style="width: 18px; height: 18px"
              class="text-inverted"
              svgIcon="open-in-new"
            >
            </mat-icon>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-5 col-sm-12 col-xl-9">
    <div class="row mb-4 category-info">
      <h3>{{ currentCategoryObject.name }}</h3>
      <p class="subtitle-category">{{ currentCategoryObject.description }}</p>
      <p class="fs-12 fw-bold text-secondary">
        {{ levelFourCount }} ocupaciones
      </p>
    </div>

    <div class="accordion accordion-flush" id="accordionExample">
      <div class="accordion-item" *ngFor="let occupationTwo of levelTwo; let i = index">
          <h2 class="accordion-header" [attr.id]="'heading' + i">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  [attr.data-bs-target]="'#collapse' + i" aria-expanded="false" [attr.aria-controls]="'collapse' + i">
                  {{ occupationTwo.name }} ({{ getOcupacionesCount(occupationTwo.id) }} ocupaciones)
              </button>
          </h2>
          <!-- data-bs-parent="#accordionExample" -->
          <div [attr.id]="'collapse' + i" class="accordion-collapse collapse" [attr.aria-labelledby]="'heading' + i"
              >
              <div class="accordion-body">

                  <app-ocupaciones-table [listaOcupaciones] ="getOcupacionesTwo(occupationTwo.id)" [salaryOccupations] ="salaryOccupations" ></app-ocupaciones-table>
 
              </div>
          </div>
      </div>
    </div>   

  </div>
</div>
