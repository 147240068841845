<app-loading *ngIf="loading"></app-loading>

<!-- <div class="row">
  <div class="col text-center">
    <button class="btn btn-primary" routerLink="{{ information.buttonLink }}">
      {{ information.buttonText }}
    </button>
  </div>
</div> -->

<div
  *ngIf="!loading"
  class="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2"
  style="margin-bottom: 1rem;margin-top: 1rem"
>
  <div
    class="col-12"
    *ngFor="let chart of information.charts"
  >
    <div class="chart-index">
      <h3 class="text-center fs-20 fw-bold">{{ chart.title }}</h3>
      <google-chart
        style="width: 99%; height: 360px"
        [type]="chartType"
        [data]="chart.data"
        [columns]="chartColumns"
        [options]="chartOptions"
      >
      </google-chart>
      <div style="margin-top: 1rem" class="text-start pb-4 ps-4">
        <mat-icon class="icon-color" svgIcon="information"></mat-icon>
        <span class="source">Fuente: {{ chart.source }}</span>
        <br>
        <span *ngIf="chart.lastUpdate !== null ? true : false" class="source">Período de referencia : {{ chart.lastUpdate }}</span>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12 il-text">
    <a
      class="text-primary"
      routerLink="/tablero-mando/2"
      rel="noopener"
      >Conoce más de la estructura ocupacional de la Planilla<mat-icon class="ms-2" svgIcon="open-in-new"></mat-icon
    ></a>
  </div>
</div>


<!-- <div class="row" style="margin-top: 1rem">
  <div class="col text-center">
    <button class="btn btn-primary" style="margin-left:15px ;" routerLink="{{ information.button2Link }}">
      {{ information.button2Text }}
    </button>
  </div>
</div> -->

<div
  *ngIf="!loading"
  class="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2"
  style="margin-bottom: 1rem;margin-top: 1rem"
>
  <div
    class="col-12"
    *ngFor="let chart of chartsRk"
  >
    <div class="chart-index">
      <h3 class="text-center fs-20 fw-bold">{{ chart.title }}</h3>
      <google-chart
        style="width: 99%; height: 360px"
        [type]="chartType"
        [data]="chart.data"
        [columns]="chartColumns"
        [options]="chartOptions"
      >
      </google-chart>
      <div style="margin-top: 1rem" class="text-start pb-4 ps-4">
        <mat-icon class="icon-color" svgIcon="information"></mat-icon>
        <span class="source">Fuente: {{ chart.source }}</span>
        <br>
        <span *ngIf="chart.lastUpdate !== null ? true : false" class="source">Período de referencia : {{ chart.lastUpdate }}</span>
      </div>
    </div>
  </div>
</div>

<!-- <div class="row" style="margin-top: 1rem">
  <div class="col text-center">
    <button class="btn btn-primary" style="margin-left:15px ;" routerLink="{{ information.button3Link }}">
      {{ information.button3Text }}
    </button>
  </div>
</div> -->

<!-- <div class="row" style="margin-top: 1rem">
  <div class="col text-center">
    <button class="btn btn-danger" style="margin-left:15px ;" routerLink="{{ information.button4Link }}">
      {{ information.button4Text }}
    </button>
  </div>
</div> -->

<!-- <div class="row">
  <div class="col-7 il-text">
    <a
      class="text-primary card-footer"
      href="{{ link.url }}"
      target="_blank"
      rel="noopener"
      >{{ link.link_text
      }}<mat-icon class="ms-2" svgIcon="open-in-new"></mat-icon
    ></a>
  </div>
  <div class="col-5">
    <img
      class="lazyload"
      style="max-width: 217px"
      width="100%"
      [attr.data-src]="imageBase + link.image[0].url"
      alt=""
    />
  </div>
</div> -->

<br>
<div class="row">
  <div class="col-12 il-text">
    <a
      class="text-primary"
      routerLink="/tablero-mando/1"
      rel="noopener"
      >Conoce más rankings de la demanda ocupacional futura<mat-icon class="ms-2" svgIcon="open-in-new"></mat-icon
    ></a>
  </div>
</div>

