<div class="card two-columns">
  <div class="row">
    <div class="col-sm-5 col-xs-12 col-5 col-lg-5 col-md-5 image">
      <img
        class="lazyload d-flex align-self-center ps-2"
        style="width: 100% !important"
        [attr.data-src]="imageBase + information.image[0].url"
        alt=""
      />
    </div>
    <div class="col-sm-6 col-6 col-xs-12 col-md-6 col-lg-6">
      <div class="card-body pt-3 px-1">
        <h4>{{ information.title }}</h4>
        <span class="subtitle">{{ information.subcategory }}</span>
        <p class="description pt-2" [innerHTML]="information.description"></p>
      </div>
      <div class="card-footer">
        <a class="text-primary ps-1" [href]="information.url" target="_blank"
          >Ir al sitio <mat-icon svgIcon="open-in-new"></mat-icon
        ></a>
      </div>
    </div>
  </div>
</div>
