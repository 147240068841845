<h2>Text</h2>
<br />
<div class="row">
  <div class="col-3">
    <h1>Título 1</h1>
    <br />
    <h2>Título 2</h2>
    <br />
    <h3>Título 3</h3>
    <br />
    <h4>Título 4</h4>
    <br />
    <h5>Título 5</h5>
    <br />
    <span class="fs-18">Texto 6</span>
    <br />
    <span class="fs-16">Texto 7</span>
    <br />
    <span class="fs-14">Texto 8</span>
    <br />
    <span class="fs-12">Texto 9</span>
    <br />
    <span class="fs-10">Texto 10</span>
  </div>
  <div class="col-3">
    <h1 class="text-attention">Título 1</h1>
    <br />
    <h2 class="text-attention">Título 2</h2>
    <br />
    <h3 class="text-attention">Título 3</h3>
    <br />
    <h4 class="text-attention">Título 4</h4>
    <br />
    <h5 class="text-attention">Título 5</h5>
    <br />
    <span class="text-attention fs-18">Texto 6</span>
    <br />
    <span class="text-attention fs-16">Texto 7</span>
    <br />
    <span class="text-attention fs-14">Texto 8</span>
    <br />
    <span class="text-attention fs-12">Texto 9</span>
    <br />
    <span class="text-attention fs-10">Texto 10</span>
  </div>
  <div class="col-3">
    <h1 class="text-primary">Título 1</h1>
    <br />
    <h2 class="text-primary">Título 2</h2>
    <br />
    <h3 class="text-primary">Título 3</h3>
    <br />
    <h4 class="text-primary">Título 4</h4>
    <br />
    <h5 class="text-primary">Título 5</h5>
    <br />
    <span class="text-primary fs-18">Texto 6</span>
    <br />
    <span class="text-primary fs-16">Texto 7</span>
    <br />
    <span class="text-primary fs-14">Texto 8</span>
    <br />
    <span class="text-primary fs-12">Texto 9</span>
    <br />
    <span class="text-primary fs-10">Texto 10</span>
  </div>
  <div class="col-3" style="background-color: black">
    <h1 class="text-inverted">Título 1</h1>
    <br />
    <h2 class="text-inverted">Título 2</h2>
    <br />
    <h3 class="text-inverted">Título 3</h3>
    <br />
    <h4 class="text-inverted">Título 4</h4>
    <br />
    <h5 class="text-inverted">Título 5</h5>
    <br />
    <span class="text-inverted fs-18">Texto 6</span>
    <br />
    <span class="text-inverted fs-16">Texto 7</span>
    <br />
    <span class="text-inverted fs-14">Texto 8</span>
    <br />
    <span class="text-inverted fs-12">Texto 9</span>
    <br />
    <span class="text-inverted fs-10">Texto 10</span>
  </div>
</div>
