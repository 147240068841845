import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-outlook',
  templateUrl: './outlook.component.html',
  styleUrls: ['./outlook.component.scss'],
})
export class OutlookComponent implements OnInit {
  jumbotron: any;

  constructor(private apiService: ApiService) {
    this.jumbotron = {};
  }

  ngOnInit(): void {
    this.getJumbotron();
  }

  async getJumbotron() {
    this.jumbotron = await this.apiService.getOne(
      `/banners?code=panorama-laboral`
    );
    if (this.jumbotron) {
      this.jumbotron.url = environment.strapiUrl + this.jumbotron.image[0].url;


    } else {
      this.jumbotron = {};
    }
  }
}
