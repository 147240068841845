import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-zero-records',
  templateUrl: './zero-records.component.html',
  styleUrls: ['./zero-records.component.scss'],
})
export class ZeroRecordsComponent implements OnInit {
  @Input() information: any;
  @Input() imageBase: any;

  constructor( private route: ActivatedRoute, private router: Router ) {}

  onSuscriptionClick ( ) {
    this.route.fragment.subscribe ( f => {
      const element: any = document.querySelector ( "#" + f )
      if ( element ) element.scrollIntoView ( element )
    });
  }

  navigate(url: string) {
    if (url === '') {
      return;
    }
    let external = document.createElement('a');
    external.href = url;
    external.target = '_blank';
    external.click();
    external.remove();
  }

  ngOnInit(): void {}
}
