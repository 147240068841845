import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { IndicatorDetailComponent } from './indicator-detail/indicator-detail.component';
import { IndicatorsListComponent } from './indicators-list/indicators-list.component';


const routes: Routes = [
  { path: '', component: IndicatorsListComponent },
  { path: ':code/subgrupo/:id/vista/:order', component: IndicatorDetailComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OutlookRoutingModule { }
