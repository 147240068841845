import { analyzeAndValidateNgModules } from '@angular/compiler';
import { Component, OnInit, Input,SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-ocupaciones-table',
  templateUrl: './ocupaciones-table.component.html',
  styleUrls: ['./ocupaciones-table.component.scss']
})
export class OcupacionesTableComponent implements OnInit {
  
  @Input() listaOcupaciones: any;
  @Input() salaryOccupations: any;
  occupationSalary: any;
  levelFour: any;  
  listaOcupaciones2 :any;
  salaryOccupations2 :any;
    
  constructor(
    private apiService: ApiService
   
  ) { 
    this.occupationSalary = [];     
    //this.salaryOccupations = [];
    this.listaOcupaciones2= []; 
  }

  ngOnInit(): void {
 
  }

  ngOnChanges(changes: SimpleChanges) {
    //console.log(changes);
    if (changes.listaOcupaciones) {
      // do something
      this.listaOcupaciones2= this.listaOcupaciones;
    }

    if (changes.salaryOccupations) {
      // do something
      this.salaryOccupations2= this.salaryOccupations;
    }


    this.occupationSalary = this.salaryOccupations2.reduce(
      (previo: any, actual: any) => {
        previo[`${actual.code}`] = `S/ ${Math.round(
          actual.value
        ).toString()}`;

        return previo;
      },
      {}
    );        

  }
 
}
