import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MapService {

  data = {
    "title": "Mapa de calor",
    "description": "Et has minim elitr intellegat. Mea aeterno eleifend antiopam ad, nam no suscipit aeterno eleifend antiopam ad.",
    "source": "Encuesta de Demanda Ocupacional (EDO) - INEI",
    "data": [
      {
        "region": "AMAZONAS",
        "value": 1700
      },
      {
        "region": "ANCASH",
        "value": 1500
      },
      {
        "region": "LIMA",
        "value": 6000
      },
      {
        "region": "CALLAO",
        "value": 4500
      },
      {
        "region": "CUSCO",
        "value": 4500
      },
      {
        "region": "CAJAMARCA",
        "value": 2400
      },
      {
        "region": "APURIMAC",
        "value": 2200
      },
      {
        "region": "AREQUIPA",
        "value": 4500
      },
      {
        "region": "AYACUCHO",
        "value": 4500
      },
      {
        "region": "ICA",
        "value": 2900
      },
      {
        "region": "PASCO",
        "value": 1600
      },
      {
        "region": "TACNA",
        "value": 3000
      },
      {
        "region": "LAMBAYEQUE",
        "value": 3000
      },
      {
        "region": "HUANUCO",
        "value": 3000
      },
      {
        "region": "HUANCAVELICA",
        "value": 1900
      },
      {
        "region": "JUNIN",
        "value": 4000
      },
      {
        "region": "PIURA",
        "value": 3500
      },
      {
        "region": "MOQUEGUA",
        "value": 3500
      },
      {
        "region": "LA LIBERTAD",
        "value": 3200
      },
      {
        "region": "LORETO",
        "value": 2000
      },
      {
        "region": "SAN MARTIN",
        "value": 2100
      },
      {
        "region": "TUMBES",
        "value": 3000
      },
      {
        "region": "PUNO",
        "value": 2500
      },
      {
        "region": "MADRE DE DIOS",
        "value": 4000
      },
      {
        "region": "UCAYALI",
        "value": 1900
      }
    ]
  };

  dataSecondView = {
    "title": "Mapa de calor",
    "description": "Et has minim elitr intellegat. Mea aeterno eleifend antiopam ad, nam no suscipit aeterno eleifend antiopam ad.",
    "source": "Encuesta de Demanda Ocupacional (EDO) - INEI",
    "data": [
      {
        "region": "AMAZONAS",
        "value": 600000
      },
      {
        "region": "ANCASH",
        "value": 550000
      },
      {
        "region": "LIMA",
        "value": 1250000
      },
      {
        "region": "CALLAO",
        "value": 1100000
      },
      {
        "region": "CUSCO",
        "value": 800000
      },
      {
        "region": "CAJAMARCA",
        "value": 700000
      },
      {
        "region": "APURIMAC",
        "value": 390000
      },
      {
        "region": "AREQUIPA",
        "value": 760000
      },
      {
        "region": "AYACUCHO",
        "value": 450000
      },
      {
        "region": "ICA",
        "value": 310000
      },
      {
        "region": "PASCO",
        "value": 200000
      },
      {
        "region": "TACNA",
        "value": 250000
      },
      {
        "region": "LAMBAYEQUE",
        "value": 300000
      },
      {
        "region": "HUANUCO",
        "value": 215000
      },
      {
        "region": "HUANCAVELICA",
        "value": 160000
      },
      {
        "region": "JUNIN",
        "value": 800000
      },
      {
        "region": "PIURA",
        "value": 310000
      },
      {
        "region": "MOQUEGUA",
        "value": 320000
      },
      {
        "region": "LA LIBERTAD",
        "value": 450000
      },
      {
        "region": "LORETO",
        "value": 1600000
      },
      {
        "region": "SAN MARTIN",
        "value": 300000
      },
      {
        "region": "TUMBES",
        "value": 650000
      },
      {
        "region": "PUNO",
        "value": 190000
      },
      {
        "region": "MADRE DE DIOS",
        "value": 270000
      },
      {
        "region": "UCAYALI",
        "value": 300000
      }
    ]
  };

  constructor() { }

  getHeatmapInformation(view: any): Observable<any> {
    if (view === 1) {
      return of(this.data).pipe(delay(1000));
    } else {
      return of(this.dataSecondView).pipe(delay(1000));
    }
  }
}
