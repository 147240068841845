import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../services/api.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-zona',
  templateUrl: './zona.component.html',
  styleUrls: ['./zona.component.scss']
})
export class ZonaComponent implements OnInit {

  imageBase: string;
  zonaExploradores: any = [];
  zonaIntermediarios: any = [];
  zonaDecisores: any = [];
  jumbotron: any;
  paramsSubscription: Subscription;
  code: string;

  constructor(private apiService: ApiService,private router: ActivatedRoute) { 
    
    this.imageBase = environment.strapiUrl;
    this.jumbotron = {};
    this.paramsSubscription = Subscription.EMPTY;    
    this.code = "";

    this.zonaExploradores = [
      {
        "id": 1,
        "tipo": 1,                       
        "titulo": "DESCUBRE OCUPACIONES",
        "descripcion": "Conoce las ocupaciones según la situación del empleo actual y futura, salarios, perfiles, habilidades y oferta formativa.",
        "url": "/descubre"

      },
      {
        "id": 2,
        "tipo": 1,                       
        "titulo": "ORIENTACIÓN PRESENCIAL",
        "descripcion": "Conoce los Centros de Empleo que puedes acudir para buscar apoyo en la búsqueda de empleo.",
        "url": "/orientacion-presencial"        
      },      
      {
        "id": 3,
        "tipo": 1,                       
        "titulo": "BUSCAR EMPLEOS",
        "descripcion": "Busca ofertas laborales en diferentes bolsas de trabajo en línea.",
        "url": "/recursos-desarrollo-laboral/buscar-empleo"        
      },
      {
        "id": 4,
        "tipo": 1,                       
        "titulo": "CONOCE EL ESTADO DE LAS VACANTES",
        "descripcion": "Entérate sobre la evolución y otras características de las vacantes de empleo ofrecidas por las empresas.",
        "url": "/recursos-desarrollo-laboral/vacantes-de-empleo"        
      },    
      {
        "id": 5,
        "tipo": 1,                       
        "titulo": "CONOCE TUS DERECHOS LABORALES",
        "descripcion": "Te alcanzamos información de utilidad para tu vida laboral para conocer más sobre tus derechos laborales.",
        "url": "/recursos-desarrollo-laboral/derechos-laborales"        
      },
      {
        "id": 6,
        "tipo": 1,                       
        "titulo": "BUSCAR OPCIONES DE ESTUDIOS AVANZADOS",
        "descripcion": "Conoce las opciones de estudios de edcuación técnica o superior para poner en práctica tu vocación.",
        "url": "/recursos-desarrollo-laboral/estudios-avanzados"        
      },    
      {
        "id": 7,
        "tipo": 1,                       
        "titulo": "SERVICIOS DE CAPACITACIÓN LABORAL",
        "descripcion": "Encuentra una lista oficial de servicios que ofrecen capacitación laboral para desarrollar tus competencias.",
        "url": "/recursos-desarrollo-laboral/capacitacion-laboral"        
      },
      {
        "id": 8,
        "tipo": 1,                       
        "titulo": "CONOCE LAS MODALIDADES FORMATIVAS",
        "descripcion": "Conoce sobre los tipos y requisitos para aplicar a alguna modalidad formativa.",
        "url": "/recursos-desarrollo-laboral/modalidades-formativas-laborales"        
      },    
    ];  

    this.zonaIntermediarios = [
      {
        "id": 1,
        "tipo": 1,                       
        "titulo": "DESCUBRE OCUPACIONES",
        "descripcion": "Conoce las ocupaciones según la situación del empleo actual y futura, salarios, perfiles, habilidades y oferta formativa.",
        "url": "/descubre"        
      },
      {
        "id": 2,
        "tipo": 1,                       
        "titulo": "CONSULTA ESTADÍSTICA",
        "descripcion": "Conoce las tendencias de los principales indicadores agregados del mercado laboral en el Perú.",
        "url": "/panorama-laboral"        
      },      
      {
        "id": 3,
        "tipo": 1,                       
        "titulo": "CONSULTA INTERACTIVA",
        "descripcion": "Explora y analiza la evolución de los indicadores del mercado laboral en el Perú con mayor nivel de interacción.",
        "url": "/panorama-laboral-interactivo"        
      },
      {
        "id": 4,
        "tipo": 1,                       
        "titulo": "TABLEROS DE MANDO",
        "descripcion": "Elabora tus propios reportes sobre la dinámica actual y futura del mercado laboral con tableros de mando según temáticas.",
        "url": "/tablero-mando"
      },    
      {
        "id": 5,
        "tipo": 1,                       
        "titulo": "MICRODATOS",
        "descripcion": "Accede a las principales bases de datos que recogen información laboral del país.",
        "url": "/recursos/microdatos"
      },
      {
        "id": 6,
        "tipo": 1,                       
        "titulo": "PUBLICACIONES",
        "descripcion": "Accede a reportes estadísticos y estudios que analizan la dinámica laboral del país.",
        "url": "/recursos/publicaciones"        
      },    
      {
        "id": 7,
        "tipo": 1,                       
        "titulo": "OBSERVATORIOS REGIONALES",
        "descripcion": "Conoce la información laboral generada por los Observatorios regionales.",
        "url": "/observatorios-regionales"        
      },
    ]; 

    this.zonaDecisores = [
      {
        "id": 1,
        "tipo": 1,                       
        "titulo": "DESCUBRE OCUPACIONES",
        "descripcion": "Conoce las ocupaciones según la situación del empleo actual y futura, salarios, perfiles, habilidades y oferta formativa.",
        "url": "/descubre"        
      },
      {
        "id": 2,
        "tipo": 1,                       
        "titulo": "CONSULTA ESTADÍSTICA",
        "descripcion": "Conoce las tendencias de los principales indicadores agregados del mercado laboral en el Perú.",
        "url": "/panorama-laboral"        
      },      
      {
        "id": 3,
        "tipo": 1,                       
        "titulo": "CONSULTA INTERACTIVA",
        "descripcion": "Explora y analiza la evolución de los indicadores del mercado laboral en el Perú con mayor nivel de interacción.",
        "url": "/panorama-laboral-interactivo"        
      },
      {
        "id": 4,
        "tipo": 1,                       
        "titulo": "TABLEROS DE MANDO",
        "descripcion": "Elabora tus propios reportes sobre la dinámica actual y futura del mercado laboral con tableros de mando según temáticas.",
        "url": "/tablero-mando"
      },    
      {
        "id": 5,
        "tipo": 1,                       
        "titulo": "MICRODATOS",
        "descripcion": "Accede a las principales bases de datos que recogen información laboral del país.",
        "url": "/recursos/microdatos"
      },
      {
        "id": 6,
        "tipo": 1,                       
        "titulo": "PUBLICACIONES",
        "descripcion": "Accede a reportes estadísticos y estudios que analizan la dinámica laboral del país.",
        "url": "/recursos/publicaciones"        
      },    
      {
        "id": 7,
        "tipo": 1,                       
        "titulo": "OBSERVATORIOS REGIONALES",
        "descripcion": "Conoce la información laboral generada por los Observatorios regionales.",
        "url": "/observatorios-regionales"        
      },
      {
        "id": 8,
        "tipo": 2,                       
        "titulo": "OBSERVATORIO DE LA FORMALIZACIÓN LABORAL",
        "descripcion": "Accede a información detallada sobre formalidad laboral en el país.",
        "url": "https://www2.trabajo.gob.pe/estadisticas/observatorio-de-la-formalizacion-laboral/"        
      },      
    ]; 

  }

  ngOnInit(): void {

    this.paramsSubscription = this.router.params.subscribe(({ id }) => {
      this.code = id;

      console.log("this.code",this.code);

    });    

    this.getJumbotron();    

  }


  async getJumbotron() {

    switch (this.code) {
      case "1":
        this.jumbotron = await this.apiService.getOne(`/banners?code=zona-estudiantes`);
        break;
      case "2":
        this.jumbotron = await this.apiService.getOne(`/banners?code=zona-funcionarios`);

        break;
      case "3":
        this.jumbotron = await this.apiService.getOne(`/banners?code=zona-especialistas`);
             
        break;

      default:

        //
    }



    if (this.jumbotron) {

      this.jumbotron.url = environment.strapiUrl + this.jumbotron.image[0].url;
    } else {
      this.jumbotron = {};
    }
  }  




  async getZonas() {



  }  

}
