<h2>Cards</h2>
<div>
  <div class="row">
    <div class="col-sm-12 col-lg-6">
      <div class="card two-columns">
        <div class="row">
          <div class="col-sm-5 image">
            <span class="badge">NUEVO</span>
            <img
              class="lazyload"
              data-src="https://estaticos.muyinteresante.es/media/cache/1140x_thumb/uploads/images/gallery/59c4f5655bafe82c692a7052/gato-marron_0.jpg"
              alt=""
            />
          </div>
          <div class="col-sm-7">
            <div class="card-body">
              <h4>Titulo</h4>
              <span class="subtitle">Scope</span>
              <p class="description">Description</p>
            </div>
            <div class="card-footer">
              <a class="text-primary" href="#"
                >Ir al sitio <mat-icon svgIcon="open-in-new"></mat-icon
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-lg-6">
      <div class="card two-columns">
        <div class="row">
          <div class="col-sm-5 image">
            <img
              class="lazyload"
              data-src="https://estaticos.muyinteresante.es/media/cache/1140x_thumb/uploads/images/gallery/59c4f5655bafe82c692a7052/gato-marron_0.jpg"
              alt=""
            />
          </div>
          <div class="col-sm-7">
            <div class="card-body">
              <h4>Titulo</h4>
              <span class="subtitle">Scope</span>
              <p class="description">Description</p>
            </div>
            <div class="card-footer">
              <a class="text-primary" href="#"
                >Ir al sitio <mat-icon svgIcon="open-in-new"></mat-icon
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div>
  <div class="row">
    <div class="col-sm-12 col-lg-3">
      <div class="card simple">
        <div class="row">
          <div class="col-sm-12">
            <div class="card-body">
              <span class="badge">NUEVO</span>
              <h4>Titulo</h4>
            </div>
            <div class="card-footer">
              <a class="text-primary" href="#"
                >Ir al sitio <mat-icon svgIcon="open-in-new"></mat-icon
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-lg-3">
      <div class="card simple">
        <div class="row">
          <div class="col-sm-12">
            <div class="card-body">
              <h4>Titulo</h4>
            </div>
            <div class="card-footer">
              <a class="text-primary" href="#"
                >Ir al sitio <mat-icon svgIcon="open-in-new"></mat-icon
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-lg-3">
      <div class="card simple">
        <div class="row">
          <div class="col-sm-12">
            <div class="card-body">
              <span class="badge">NUEVO</span>
              <h4>Titulo</h4>
            </div>
            <div class="card-footer">
              <a class="text-primary" href="#"
                ><mat-icon class="text-attention" svgIcon="pdf-box"></mat-icon
                >Bóton</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-lg-3">
      <div class="card simple">
        <div class="row">
          <div class="col-sm-12">
            <div class="card-body">
              <h4>Titulo</h4>
            </div>
            <div class="card-footer">
              <a class="text-primary" href="#">
                <mat-icon class="text-warning" svgIcon="zip-box"></mat-icon
                >Bóton
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div>
    <div class="row">
      <div class="col-sm-12 col-lg-3">
        <div class="card image">
          <div class="row">
            <div class="col-sm-12">
              <div class="card-header">
                <img
                  class="lazyload"
                  data-src="https://estaticos.muyinteresante.es/media/cache/1140x_thumb/uploads/images/gallery/59c4f5655bafe82c692a7052/gato-marron_0.jpg"
                  alt=""
                />
              </div>
              <div class="card-body">
                <span class="badge">NUEVO</span>
                <h4>Titulo</h4>
              </div>
              <div class="card-footer">
                <a class="text-primary" href="#"
                  >Ir al sitio <mat-icon svgIcon="open-in-new"></mat-icon
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-12 col-lg-3">
        <div class="card image">
          <div class="row">
            <div class="col-sm-12">
              <div class="card-header">
                <img
                  class="lazyload"
                  data-src="https://estaticos.muyinteresante.es/media/cache/1140x_thumb/uploads/images/gallery/59c4f5655bafe82c692a7052/gato-marron_0.jpg"
                  alt=""
                />
              </div>
              <div class="card-body">
                <h4>Titulo</h4>
              </div>
              <div class="card-footer">
                <a class="text-primary" href="#"
                  >Ir al sitio <mat-icon svgIcon="open-in-new"></mat-icon
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-12 col-lg-3">
        <div class="card image">
          <div class="row">
            <div class="col-sm-12">
              <div class="card-header">
                <img
                  class="lazyload"
                  data-src="https://estaticos.muyinteresante.es/media/cache/1140x_thumb/uploads/images/gallery/59c4f5655bafe82c692a7052/gato-marron_0.jpg"
                  alt=""
                />
              </div>
              <div class="card-body">
                <span class="badge">NUEVO</span>
                <h4>Titulo</h4>
              </div>
              <div class="card-footer">
                <a class="text-primary" href="#">Bóton</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-12 col-lg-3">
        <div class="card image">
          <div class="row">
            <div class="col-sm-12">
              <div class="card-header">
                <img
                  class="lazyload"
                  data-src="https://estaticos.muyinteresante.es/media/cache/1140x_thumb/uploads/images/gallery/59c4f5655bafe82c692a7052/gato-marron_0.jpg"
                  alt=""
                />
              </div>
              <div class="card-body">
                <h4>Titulo</h4>
              </div>
              <div class="card-footer">
                <a class="text-primary" href="#">Bóton</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
