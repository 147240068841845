import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'cont-obl',
  templateUrl: './cont-obl.component.html',
  styleUrls: ['./cont-obl.component.scss'],
})
export class cont_obl implements OnInit {
  information: any;
  loading: any;

  constructor(private apiService: ApiService) {
    this.information = null;
    this.loading = true;
  }

  ngOnInit(): void {
    this.getWhatIsOBLData();
  }

  async getWhatIsOBLData() {
    let items = await this.apiService.getMany('/what-is-obl');

    this.information = {
      results: items,
    };

    this.loading = false;
  }
}
