<nav class="
    navbar navbar-expand-lg navbar-light
    bg-light
    fixed-top
    rounded
    pt-4
    pb-4" aria-label="Eighth navbar example" style="
    background-color: white !important;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);">
  <div class="container">
    <div>
      <a class="navbar-brand" href="https://www.gob.pe/mtpe" target="_blank" rel="noopener">
        <img class="lazyload" alt="" width="237px" height="48px" data-src="/assets/img/home/logo_mtpecolor.png" />
      </a>
      <a class="navbar-brand" routerLink="/">
        <img class="lazyload" alt="" width="128px" height="48px" data-src="/assets/img/home/LogoOBLHD.jpeg" />
      </a>
    </div>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample07"
      aria-controls="navbarsExample07" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarsExample07">
      <div class="me-auto">
        <ul class="navbar-nav mb-2 mb-lg-0" style="color: #26292e">
          <li class="nav-item dropdown">
            <a class="nav-link" [ngClass]="activePage === 1 ? 'active' : ''">
              Orientación <br>Ocupacional
              <mat-icon svgIcon="chevron-down"></mat-icon>
            </a>
            <ul class="dropdown-menu" style="
                border-radius: 0px;
                padding: 0;
                border: 0;
                box-shadow: 0px 4px 10px 0px #0000001a;
              ">
              <li>
                <a class="dropdown-item" routerLink="/descubre">
                  Descubre ocupaciones
                </a>
              </li>
              <li>
                <a class="dropdown-item" routerLink="/recursos-desarrollo-laboral/estudios-avanzados">
                  Recursos para el desarrollo laboral
                </a>
              </li>
              <li>
                <a class="dropdown-item" routerLink="/orientacion-presencial">
                  Orientación presencial
                </a>
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link" [ngClass]="activePage === 2 ? 'active' : ''">
              Panorama Laboral
              <mat-icon svgIcon="chevron-down"></mat-icon>
            </a>
            <ul class="dropdown-menu" style="
                border-radius: 0px;
                padding: 0;
                border: 0;
                box-shadow: 0px 4px 10px 0px #0000001a;
              ">
              <li>
                <a class="dropdown-item" routerLink="/panorama-laboral">
                  Consulta Estadística
                </a>
              </li>
              <li>
                <a class="dropdown-item" routerLink="/panorama-laboral-interactivo">
                  Consulta Interactiva
                </a>
              </li>
              <li>
                <a class="dropdown-item" routerLink="/tablero-mando">
                  Tableros de mando&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </a>
              </li>
            </ul>
          </li>          
          <li class="nav-item">
            <a class="nav-link" [ngClass]="activePage === 3 ? 'active' : ''" aria-current="page" routerLink="/recursos/publicaciones">Publicaciones y Microdatos</a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link" [ngClass]="activePage === 4 ? 'active' : ''">
              Observatorios
              <mat-icon svgIcon="chevron-down"></mat-icon>
            </a>
            <ul class="dropdown-menu" style="
                border-radius: 0px;
                padding: 0;
                border: 0;
                box-shadow: 0px 4px 10px 0px #0000001a;
              ">
              <li>
                <a class="dropdown-item" routerLink="/observatorios-regionales">
                  Observatorios Regionales
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="https://www2.trabajo.gob.pe/estadisticas/observatorio-de-la-formalizacion-laboral/" target="_blank">
                  Observatorio de la Formalización Laboral  
                </a>
              </li>

            </ul>
          </li>           
          
        </ul>
      </div>
      <div>
        <div class="d-flex">
          <div class="ng-autocomplete">
            <ng-autocomplete *ngIf="showSearch" #auto [data]="searchList" [searchKeyword]="keyword" [minQueryLength]="3"
              (inputCleared)="onCleared($event)" (inputFocused)="onFocused($event)" placeholder="Buscar"
              (selected)="selectEvent($event)" [itemTemplate]="itemTemplate"
              notFoundText="No se encontraron resultados." [notFoundTemplate]="notFoundTemplate">
            </ng-autocomplete>
          </div>
          <ng-template #itemTemplate let-item>
            <a [innerHTML]="item.name"></a>
          </ng-template>
          <ng-template #notFoundTemplate let-notFound>
            <div [innerHTML]="notFound"></div>
          </ng-template>
          <button (click)="onShowSearch()" type="button" class="btn-search">
            <mat-icon class="black" svgIcon="magnify"></mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</nav>
<br />
<br />
<br />
<br />
