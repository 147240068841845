import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';


@Component({
  selector: 'app-outlook-select-indicator',
  templateUrl: './select-indicator.component.html',
  styleUrls: ['./select-indicator.component.scss']
})
export class SelectIndicatorComponent implements OnInit, OnDestroy {

  loading: boolean;
  error: boolean;
  information: any;
  paramsSubscription: Subscription;
  selectedSubGroup: number;
  selectedCode: string;

  constructor(private r: Router, private router: ActivatedRoute, private apiService: ApiService) {
    this.loading = true;
    this.error = false;
    this.information = {};
    this.paramsSubscription = Subscription.EMPTY;
    this.selectedSubGroup = 0;
    this.selectedCode = '';
  }

  ngOnInit(): void {
    this.paramsSubscription = this.router.params.subscribe(({ id, code }) => {
      this.getSelectData(id, code);
    });
  }

  async getSelectData(id: number, code: string) {
    this.selectedSubGroup = id;
    this.selectedCode = code;
    this.information = await this.apiService.getManyBackend(`/indicators?code=${this.selectedCode}`, null);
    if (!this.information) {
      this.error = true;
    }
    this.loading = false;
  }

  onChange($event: any) {
    if ($event.target.value) {
      this.r.navigate([`/panorama-laboral/${this.selectedCode}/subgrupo/${$event.target.value}/vista/1`]);
    }
  }

  ngOnDestroy(): void {
    this.paramsSubscription.unsubscribe();
  }
}
