<app-loading *ngIf="loading"></app-loading>
<div class="mt-4" *ngIf="!loading" class="chart-overflow">
  <google-chart
    style="margin-top: 0.5rem; height: 300px"
    [type]="lcMyType"
    [data]="lcMyData"
    [options]="lcMyOptions"
    [columns]="lcMyColumns"
    [ngStyle]="{ width: width }"
    (ready)="onLineChartReady($event)">
  </google-chart>
</div>
