<div
  class="text-start fw-bold mt-4 mb-4"
  style="font-size: 24px; color: #cf000b"
>
  {{ information.title }}
</div>
<div>
  <div [innerHTML]="information.description"></div>
  <div class="fw-bold" style="font-size: 12px; color: #6f777b">
    <span>{{ totalResults }} resultados</span>
  </div>
</div>
<div class="nav nav-tabs mt-5" id="nav-tab" role="tablist">
  <button
    class="nav-link fw-bold active"
    id="nav-public-tab"
    data-bs-toggle="tab"
    data-bs-target="#nav-public"
    type="button"
    role="tab"
    aria-controls="nav-public"
    aria-selected="false"
  >
    Entidades públicas
  </button>
  <button
    class="nav-link fw-bold"
    id="nav-private-tab"
    data-bs-toggle="tab"
    data-bs-target="#nav-private"
    type="button"
    role="tab"
    aria-controls="nav-private"
    aria-selected="false"
  >
    Entidades privadas
  </button>
</div>

<div class="tab-content" id="nav-tabContent">
  <div class="button-group mt-3">
    <button
      *ngFor="let button of subcategory.buttons"
      type="button"
      [ngClass]="button.periodicity === currentPublication ? 'active' : ''"
      (click)="getActualPublication(button.periodicity)"
    >
      {{ button.title }}
    </button>
  </div>
  <div
    class="tab-pane fade show active"
    id="nav-public"
    role="tabpanel"
    aria-labelledby="nav-public-tab"
  >
    <div class="row" *ngIf="publics.length > 0; else zeroRecords">
      <div class="col-lg-6 mt-4" *ngFor="let iniciative of publics">
        <app-card-six-sub
          [information]="iniciative"
          [imageBase]="imageBase"
        ></app-card-six-sub>
      </div>
    </div>
  </div>
  <div
    class="tab-pane fade show"
    id="nav-private"
    role="tabpanel"
    aria-labelledby="nav-private-tab"
  >
    <div class="row" *ngIf="privates.length > 0; else zeroRecords">
      <div class="col-lg-6 mt-4" *ngFor="let iniciative of privates">
        <app-card-six-sub
          [information]="iniciative"
          [imageBase]="imageBase"
        ></app-card-six-sub>
      </div>
    </div>
  </div>
  <ng-template #zeroRecords>
    <app-zero-records></app-zero-records>
  </ng-template>
</div>
