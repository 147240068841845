import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { CardComponent } from './card/card.component';
import { CardTwoColumnsComponent } from './card-two-columns/card-two-columns.component';
import { JumbotronComponent } from './jumbotron/jumbotron.component';
import { NavbarComponent } from './navbar/navbar.component';

import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { CardSixComponent } from './card-6/card-6.component';
import { CardSixSubComponent } from './card-6-sub/card-6-sub.component';
import { ZeroRecordsComponent } from './zero-records/zero-records.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { LoadingComponent } from './loading/loading.component';
import { GoogleChartCustomComponent } from './google-chart-custom/google-chart-custom.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { CardTwoColumnsDLComponent } from './card-two-columns-desarrollo-laboral/card-two-columns-dl.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    NavbarComponent,
    CardComponent,
    CardTwoColumnsComponent,
    CardTwoColumnsDLComponent,
    CardSixComponent,
    CardSixSubComponent,
    JumbotronComponent,
    ZeroRecordsComponent,
    SubscriptionComponent,
    LoadingComponent,
    GoogleChartCustomComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    MatIconModule,
    GoogleChartsModule,
    AutocompleteLibModule,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    NavbarComponent,
    CardComponent,
    CardTwoColumnsComponent,
    CardTwoColumnsDLComponent,
    CardSixComponent,
    CardSixSubComponent,
    JumbotronComponent,
    MatIconModule,
    ZeroRecordsComponent,
    SubscriptionComponent,
    LoadingComponent,
    GoogleChartCustomComponent,
    
  ],
})
export class SharedModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIconSet(
      domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg')
    );
  }
}
