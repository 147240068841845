<div
  class="text-start fw-bold mt-4 mb-4"
  style="font-size: 24px; color: #cf000b"
>
  {{ information.title }}
</div>
<div>
  <div [innerHTML]="information.description"></div>
  <div class="fw-bold" style="font-size: 12px; color: #6f777b">
    <span>{{ totalResults }} resultados</span>
  </div>
</div>
<div class="nav nav-tabs mt-5" id="nav-tab" role="tablist">
  <button
    class="nav-link fw-bold active"
    id="nav-home-tab"
    data-bs-toggle="tab"
    data-bs-target="#nav-home"
    type="button"
    role="tab"
    aria-controls="nav-home"
    aria-selected="false"
  >
    Páginas oficiales
  </button>
</div>

<div class="tab-content" id="nav-tabContent">
  <div
    class="tab-pane fade show active"
    id="nav-home"
    role="tabpanel"
    aria-labelledby="nav-home-tab"
  >
    <div class="row" *ngIf="publics.length > 0; else zeroRecords">
      <div class="col-sm-12" *ngFor="let iniciative of publics">
        <app-card-two-columns-dl
          class="mt-4"
          [information]="iniciative"
          [imageBase]="imageBase"
        ></app-card-two-columns-dl>
      </div>
    </div>
  </div>
  <ng-template #zeroRecords>
    <app-zero-records></app-zero-records>
  </ng-template>
</div>
