<div class="bg-black mt-5 pt-5 pb-4 text-inverted">
  <div class="container">
    <div class="row">
      <div
        class="
          col-sm-12 col-md-4
          fw-bold
          mb-4
          text-center text-sm-center text-md-start
        "
      >
        <a href routerLink="/">
          <img
            class="lazyload"
            width="128px"
            height="48px"
            data-src="/assets/img/home/LogoOBLFT.png"
            alt="logo"
        /></a>
      </div>
      <div class="col-sm-12 col-md-4 fw-bold mb-4 text-center">
        <div>
          <span class="">Síguenos en redes</span>
        </div>
        <div class="text-center">
          <a
            class="text-primary"
            href="https://www.facebook.com/MTPEPERU/"
            target="_blank"
            rel="noopener"
          >
            <mat-icon
              svgIcon="facebook"
              class="text-inverted footer-icon"
            ></mat-icon
          ></a>
          <a
            class="text-primary"
            href="https://twitter.com/MTPE_Peru"
            target="_blank"
            rel="noopener"
          >
            <mat-icon
              svgIcon="twitter"
              class="text-inverted footer-icon"
            ></mat-icon
          ></a>
          <a
            class="text-primary"
            href="https://www.instagram.com/mtpe_peru/"
            target="_blank"
            rel="noopener"
          >
            <mat-icon
              svgIcon="instagram"
              class="text-inverted footer-icon"
            ></mat-icon
          ></a>
          <a
            class="text-primary"
            href="https://www.youtube.com/c/MTPEPer%C3%BA"
            target="_blank"
            rel="noopener"
          >
            <mat-icon
              svgIcon="youtube"
              class="text-inverted footer-icon"
            ></mat-icon
          ></a>
        </div>
      </div>
      <div
        class="col-sm-12 col-md-4 mb-4 text-center text-sm-center text-md-end"
      >
        <a href="https://www.gob.pe/mtpe" target="_blank" rel="noopener"
          ><img
            class="lazyload"
            data-src="/assets/img/home/logo_mtpewhite.png"
            alt="logo"
        /></a>
      </div>
    </div>
    <div>
      <hr class="line" />
    </div>
    <div class="row mt-4">
      <div class="col-12 fw-bold text-center text-sm-center text-md-start">
        <span class="fs-12"
          >2021 - Ministerio de Trabajo y Promoción del Empleo - Derechos
          reservados</span
        >
      </div>
    </div>
  </div>
</div>
