import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-six-sub',
  templateUrl: './card-6-sub.component.html',
  styleUrls: ['./card-6-sub.component.scss'],
})
export class CardSixSubComponent implements OnInit {
  @Input() information: any;
  @Input() imageBase: any;

  constructor() {}

  navigate(url: string) {
    if (url === '') {
      return;
    }
    let external = document.createElement('a');
    external.href = url;
    external.target = '_blank';
    external.click();
    external.remove();
  }

  ngOnInit(): void {}
}
