<header>
  <nav class="navbar navbar-expand-md navbar-dark bg-dark">
    <div class="container-fluid">
      <a class="navbar-brand" routerLink="home" routerLinkActive="router-link-active">OBL</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <ul class="navbar-nav me-auto mb-2 mb-md-0">
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" routerLink="home">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" routerLink="users/1">User</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" routerLink="charts">Charts</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" routerLink="map">Map</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" routerLink="heat">Heat</a>
          </li>
        </ul>
        <div class="d-flex">
          <input #txtInput
            class="form-control me-2" (keyup.enter)="search(txtInput.value)"
            type="search" placeholder="Search" aria-label="Search">
          <button class="btn btn-primary" type="button" (click)="search(txtInput.value)">Search</button>
        </div>
      </div>
    </div>
  </nav>
</header>
