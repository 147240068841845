import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from "@angular/router";
import { AutocompleteLibModule } from 'angular-ng-autocomplete';

import { PresentialComponent } from './presential.component';
import { DescriptionComponent } from './description/description.component';


@NgModule({
  declarations: [
    PresentialComponent,
    DescriptionComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    AutocompleteLibModule
  ],
  exports: []
})
export class PresentialModule { }
