import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { ResourcesRoutingModule } from './resources-routing.module';

import { ResourcesComponent } from './resources.component';
import { SearchComponent } from './search/search.component';
import { PublicationsComponent } from './publications/publications.component';
import { PublicationsAnnualComponent } from './publications/publications-annual-report/publications-annual-report.component';
import { PublicationsMonthComponent } from './publications/publications-month-report/publications-month-report.component';
import { MicrodataComponent } from './microdata/microdata.component';
import { OselComponent } from './osel/osel.component';
import { RegionComponent } from './osel/region/region.component';
import { AboutComponent } from './osel/region/about/about.component';
import { OselPublicationsComponent } from './osel/region/osel-publications/osel-publications.component';
import { FormsModule } from '@angular/forms';
import { PublicationsMiscComponent } from './publications/publications-misc-report/publications-misc-report.component';
import { PublicationsHeaderComponent } from './publications/publications-header/publications-header.component';
import { OselInformationSourceComponent } from './osel/region/osel-information-sources/osel-information-sources.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';

@NgModule({
  declarations: [
    ResourcesComponent,
    SearchComponent,
    PublicationsComponent,
    PublicationsAnnualComponent,
    PublicationsMonthComponent,
    PublicationsMiscComponent,
    PublicationsHeaderComponent,
    MicrodataComponent,
    OselComponent,
    RegionComponent,
    AboutComponent,
    OselPublicationsComponent,
    OselInformationSourceComponent
  ],
  imports: [CommonModule, FormsModule, SharedModule, ResourcesRoutingModule, AutocompleteLibModule],
  exports: [],
})
export class ResourcesModule {}
