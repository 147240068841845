import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-publications-header',
  templateUrl: './publications-header.component.html',
  styleUrls: ['./publications-header.component.scss'],
})
export class PublicationsHeaderComponent {
  @Input() headers: any = null;
  @Input() strapiUrl: any = null;

  monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
  "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
  ];

  formatLastUpdatedDate(lastUpdatedDate: string) {
    let formatedLastUpdatedDate = this.monthNames[(new Date(lastUpdatedDate).getMonth())] + ", " + (new Date(lastUpdatedDate).getFullYear())
    return formatedLastUpdatedDate;
  }

}
