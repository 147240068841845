<app-publications-header
  [headers]="headers"
  [strapiUrl]="strapiUrl"
></app-publications-header>

<div class="row">
  <p class="p-select text-secondary fs-16">
    Selecciona los años, meses y formatos de los documentos que desea descargar:
  </p>
</div>

<div class="row col-12">
  <p class="col-sm-2 col-form-label">Informe</p>
  <div class="pt-2 pb-2 col-sm-10">
    <div class="col-4">
      <select class="form-select" (change)="selectTitle($event)">
        <option *ngFor="let title of titles" [value]="title">
          {{ title }}
        </option>
      </select>
    </div>
  </div>
</div>

<div class="row col-12">
  <p class="col-sm-2 col-form-label">Años</p>
  <div class="pt-2 pb-2 col-sm-10">
    <span class="me-2" *ngFor="let period of periods">
        <input
        (click)="periodSelection(period)"
        [type]="'checkbox'"
        [checked] =  "periodsSelection.includes(period)"
        class="btn-check"
        [id]="'btn-check-' + period + titleSelected"
        autocomplete="off"
      />
      <label
      [ngClass]="
          periodsSelection.includes(period)
            ? 'btn btn-outline-warning mb-2'
            : 'btn btn-outline-primary mb-2'
        "
      [for]="'btn-check-' + period + titleSelected">{{
        period
      }}</label>
    </span>
  </div>
</div>

<div class="row col-12">
  <p class="col-sm-2 col-form-label">Formato</p>
  <div class="pt-2 pb-2 col-sm-10">
    <span class="me-2" *ngFor="let format of formats">
      <input
        (click)="formatSelection(format)"
        type="checkbox"
        class="btn-check"
        [id]="'btn-check-' + format"
        autocomplete="off"
      />
      <label class="btn btn-outline-warning" [for]="'btn-check-' + format">
        {{ format }}</label
      ></span
    >
  </div>
</div>

<div class="mt-5">
  <button
    [ngClass]="enableDownload ? 'btn-primary' : 'disabled'"
    (click)="download()"
    class="btn me-2"
  >
    <mat-icon
      [ngClass]="enableDownload ? 'text-inverted' : 'text-secondary'"
      svgIcon="file-download-outline"
    ></mat-icon>
    Descargar archivos seleccionados
  </button>
</div>

<div class="text-start mt-3">
  <mat-icon class="icon-color" svgIcon="information"></mat-icon>
  <span class="source"
    >Fuente: {{ headers !== null ? headers.source_information : "" }}</span
  >
</div>
