// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  //strapiUrl: 'http://192.168.171.106:1337',
  //backendUrl: 'http://192.168.171.107:8080/api/private',
  strapiUrl: 'http://api-strapi-observatoriolaboral.trabajo.gob.pe:1337',
  backendUrl: 'http://api-backend-observatoriolaboral.trabajo.gob.pe:8080/api/private',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
