<app-jumbotron [information]="jumbotron"></app-jumbotron>
<div class="container">
  <app-presential-description></app-presential-description>

  <div class="mt-4" style="margin-bottom: 2.5rem">
    <div class="col-12">
      <div class="mt-4">
        <div class="ng-autocomplete">
          <ng-autocomplete
            #auto
            [data]="departments"
            [searchKeyword]="keyword"
            (inputCleared)="onCleared($event)"
            (inputFocused)="onFocused($event)"
            placeholder="Ingrese la región que estás buscando. Ejm: Amazonas, Cusco"
            (selected)="selectEvent($event)"
            [itemTemplate]="itemTemplate"
            notFoundText="No se encontraron resultados."
            [notFoundTemplate]="notFoundTemplate"
          >
          </ng-autocomplete>
          <button class="btn btn-primary button-custom-search" type="button">
            <mat-icon class="text-inverted" svgIcon="magnify"></mat-icon>
          </button>
        </div>
        <ng-template #itemTemplate let-item>
          <a [innerHTML]="item.name"></a>
        </ng-template>
        <ng-template #notFoundTemplate let-notFound>
          <div [innerHTML]="notFound"></div>
        </ng-template>
      </div>
    </div>
  </div>
  <div id="tip" class="mt-4" *ngIf="!isSelected">
    <div
      class="col-12 col-md-6 col-lg-4"
      style="padding: 1.1rem; border: 1px solid #dee3ea"
    >
      <span class="fs-14"
        ><strong>Tip:</strong> Haga click en la región de su interés para
        encontrar un Centro de Empleo en la zona.
      </span>
    </div>
  </div>
  <div class="row">
    <div [ngClass]="isSelected ? 'col-md-12 col-lg-6' : 'col-12'">
      <div class="text-center">
        <svg id="presential-map"></svg>
      </div>
    </div>
    <div #cardDiv class="scroll-view mt-4 mt-sm-4 mt-md-4 mt-lg-0"
      [ngClass]="{ 'col-md-12 col-lg-6': isSelected }" *ngIf="isSelected">
      <div class="card" *ngFor="let jobCenter of jobCentersByState">
        <div class="card-body">
          <h4>{{ jobCenter.title }}</h4>
          <div>
            <span class="text-secondary fs-14">Servicios que brinda:</span>
            <div class="mt-2" [innerHTML]="jobCenter.services"></div>
          </div>
          <div class="mt-3">
            <span class="text-secondary fs-14">Dirección:</span>
            <div>
              <span>{{ jobCenter.address }}</span>
            </div>
          </div>
          <div class="mt-2">
            <span class="text-secondary fs-14">Teléfono:</span>
            <div>
              <span>{{ jobCenter.phone }}</span>
            </div>
          </div>
          <div class="mt-2">
            <span class="text-secondary fs-14">Horario de atención:</span>
            <div>
              <span>{{ jobCenter.opening_hours }}</span>
            </div>
          </div>
          <div class="mt-4">
            <a
              class="text-primary fw-bold"
              target="_blank"
              [href]="jobCenter.link"
              >Ver mapa
              <mat-icon
                style="width: 18px; height: 18px"
                svgIcon="open-in-new"
              ></mat-icon>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
