import { Component, OnInit } from '@angular/core';
import { ChartType } from 'angular-google-charts';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-home-topten',
  templateUrl: './topten.component.html',
  styleUrls: ['./topten.component.scss'],
})
export class TopTenComponent implements OnInit {
  monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
  "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
  ];

  information: any;

  chartType = ChartType.BarChart;
  chartOptions: any = {};
  chartColumns: any = [];
  rTrabData: any = [];//Ranking Trabajadores 
  rRemData: any = [];//Ranking Remuneracion
  chartsRk: any = [];  


  loading: any;

  constructor(private apiService: ApiService) {
    this.information = {};
    this.loading = true;
  }

  ngOnInit(): void {
    this.getTopTenCharts();
  }

  stringify(json:any){
    return JSON.stringify(json);
  }

  formatLastUpdatedDate() {
    let date = new Date();
    let month = new Date().getMonth();
    let formatPrevMonth = new Date(date.setMonth(month - 1));
    let formatedLastUpdatedDate = this.monthNames[(formatPrevMonth.getMonth())] + ", " + (formatPrevMonth.getFullYear())
    return formatedLastUpdatedDate;
  }

  formatPeriod(period:any){
    if(period.length===6){
      return period.substring(0,4) + "-" + period.substring(4,6);
    }
    return period;
  }

  async getTopTenCharts() {
    let rankingData = (
      await this.apiService.getManyBackend('/homeIndicators/rankings', null)
    ).data;

    let occupationDemandedCodes = rankingData.mas_demandadas.map(
      (occupation: any) => `code_in=${occupation.name}`
    );
    let occupationSalaryCodes = rankingData.mayor_ingreso.map(
      (occupation: any) => `code_in=${occupation.name}`
    );

    let occupationDemandedNames = await this.apiService.getMany(
      `/occupation-fours?${occupationDemandedCodes.join('&')}`
    );
    let occupationSalaryNames = await this.apiService.getMany(
      `/occupation-fours?${occupationSalaryCodes.join('&')}`
    );

    let mapDemanded = occupationDemandedNames.reduce(
      (previus: any, occupation: any) => {
        previus[occupation.code] = occupation.name;
        return previus;
      },
      {}
    );

    let mapSalary = occupationSalaryNames.reduce(
      (previus: any, occupation: any) => {
        previus[occupation.code] = occupation.name;
        return previus;
      },
      {}
    );

    let chartDataDemanded = rankingData.mas_demandadas.map(
      (occupation: any) => {
        return [
          mapDemanded[occupation.name],
          //mapDemanded[occupation.name].substring(0, 90),
          occupation.value,
          Math.round(occupation.value).toString()
        ];
      }
    );

    let chartDataSalary = rankingData.mayor_ingreso.map((occupation: any) => {
      return [
        mapSalary[occupation.name],
        //mapSalary[occupation.name].substring(0, 90),
        Math.round(occupation.value),
        `S/ ${Math.round(occupation.value).toString()}`
      ];

    });


    this.information = {
      buttonText: 'Conocer más sobre el Panorama laboral',
      buttonLink: '/panorama-laboral',
      button2Text: 'Conocer más de la estructura ocupacional de la Planilla',
      button2Link: '/tablero-mando/2',      
      button3Text: 'Conocer más rankings de la demanda ocupacional futura',
      button3Link: '/tablero-mando/1',      
      button4Text: 'Elabora tus propios reportes de Mercado Laboral, a traves de los siguientes tableros',
      button4Link: '/tablero-mando',      


      charts: [
        {
          title: 'Top 10 Ocupaciones con mayor cantidad de puestos de trabajo asalariados',
          source:
            'Planilla Electrónica (PLAME y T-Registro) del sector privado - MTPE',
          data: chartDataDemanded,
          lastUpdate: this.formatPeriod(rankingData.mas_demandadas[0].period),
        },
        {
          title: 'Top 10 Ocupaciones mejor remuneradas',
          source:
            'Planilla Electrónica (PLAME y T-Registro) del sector privado - MTPE',
          data: chartDataSalary,
          lastUpdate: this.formatPeriod(rankingData.mayor_ingreso[0].period),
        },
      ],
    };

    this.chartColumns = [
      { type: 'string', role: 'domain', label: 'Ocupaciones' },
      { type: 'number', role: 'data', label: 'Valor' },
      { type: 'string', role: 'annotation', label: 'Valor' },
    ];

    this.chartOptions = {
      legend: { position: 'none' },
      bar: { groupWidth: '50%' },
      vAxis: {
        textStyle: {
          color: '#6f777b',
          fontSize: 11,
          fontName: 'Roboto',
        },
        baselineColor: 'none',
        gridlineColor: 'none',
        gridlines: { color: 'none' },
      },
      hAxis: {
        textPosition: 'none',
        baselineColor: 'none',
        gridlineColor: 'none',
        gridlines: { color: 'none' },
      },
      series: {
        0: {
          color: '#0056AC',
          annotations: {
            textStyle: {
              strokeWidth: 0,
              fontSize: 10,
              color: '#6F777B',
            },
          },
        },
      },
      annotations: {
        stemColor: 'none',
        alwaysOutside: true,
      },
      chartArea: {
        left: 300,
        right: 35,
        top: 5,
        bottom: 5,
      },
      tooltip: {
        //trigger: 'none'
      },
    };

    //Nuevos rankins
    this.rTrabData = [
      ['Especialistas programadores', '3474','3474'],
      ['Empleados de centros de llamadas', '2094','2094'],
      ['Guardias de protección en establecimiento', '1730','1730'],      
      ['Limpiadores y asistentes de oficinas, hoteles y otros establecimientos', '1585','1585'],      
      ['Peones de explotaciones agrícolas y ganaderas', '1326','1326'],      
      ['Vendedores minoristas en tiendas y establecimientos (excepto kioscos y mercados)', '1179','1179'],      
      ['Asistentes de venta en tiendas y almacenes', '1074','1074'],
      ['Otros profesionales de la enseñanza', '994','994'],      
      ['Mineros y operadores de instalaciones mineras', '912','912'],
      ['Representantes comerciales', '848','848']
    ];  
     
    this.rRemData = [
      ['Directores de administración financiera, de presupuesto y/o contabilidad', '23155','S/ 23155'],
      ['Directores generales que prestan servicios de educación', 22222,'S/ 22222'],
      ['Directores y gerentes de empresas de explotaciones mineras', 17750,'S/ 17750'],
      ['Directores de investigación y desarrollo', 15000,'S/ 15000'],
      ['Otros gerentes de servicios', 14991,'S/ 14991'],
      ['Profesionales de la salud y la higiene laboral y ambiental', 13750,'S/ 13750'],
      ['Directores de publicidad y relaciones públicas', 13333,'S/ 13333'],
      ['Directores de ventas y comercialización', 10100,'S/ 10100'],
      ['Directores y gerentes de industrias manufacturas', 10000,'S/ 10000'],
      ['Geólogos y geofísicos', 9910,'S/ 9910']     
    ];      
    
    this.chartsRk = [
      {
        title: 'Top 10 Ocupaciones más demandadas para nuevos puestos de carácter permanente, 2022',
        source:
          'Fuente: Encuesta de Demanda Ocupacional (EDO) a empresas de 20 y más trabajadores/as - MTPE',
        data: this.rTrabData ,
        lastUpdate: "2022",
      },
      {
        title: 'Top 10 Ocupaciones mejor remuneradas para nuevos puestos de carácter permanente, 2022',        
        source:
          'Fuente: Encuesta de Demanda Ocupacional (EDO) a empresas de 20 y más trabajadores/as - MTPE',
        data: this.rRemData ,
        lastUpdate: "2022",
      }
    ];




    this.loading = false;
  }
}
