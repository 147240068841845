<app-loading *ngIf="loading"></app-loading>
<div *ngIf="!loading">
  <div class="row">

    <div class="col-12">
      <div class="mt-2 mb-4">
        <div class="ng-autocomplete">
          <ng-autocomplete
            #auto
            [data]="indicators"
            [searchKeyword]="keyword"
            (inputCleared)="onCleared($event)"
            (inputFocused)="onFocused($event)"
            placeholder="Escribe alguna palabra clave del indicador que estás buscando. Ejm: desempleo, brecha salarial"
            (selected)="selectEvent($event)"
            [itemTemplate]="itemTemplate"
            notFoundText="No se encontraron resultados."
            [notFoundTemplate]="notFoundTemplate"
          >
          </ng-autocomplete>
          <button class="btn btn-primary button-custom-search" type="button">
            <mat-icon class="text-inverted" svgIcon="magnify"></mat-icon>
          </button>
        </div>
        <ng-template #itemTemplate let-item>
          <a [innerHTML]="item.name"></a>
        </ng-template>
        <ng-template #notFoundTemplate let-notFound>
          <div [innerHTML]="notFound"></div>
        </ng-template>
      </div>
    </div>

  </div>
  <div class="mt-4">
    <div class="text-center" *ngIf="error">
      <h5 class="text-attention">No se pudo obtener la lista de indicadores</h5>
    </div>
    <div class="row" *ngIf="!error">
      <div class="col-12 col-lg-4">
        <div class="col-12 mb-4">
          <ng-template
            [ngTemplateOutlet]="TheGeekTemplate"
            [ngTemplateOutletContext]="{ $implicit: findByCode('ODE') }"
            #TheGeekTemplate
            let-item
          >
            <div class="card">
              <div class="card-body">
                <div
                  class="mb-3 d-flex justify-content-center align-items-center"
                  [ngStyle]="{
                    'background-color': item.color,
                    width: '52px',
                    height: '52px'
                  }"
                >
                  <mat-icon
                    style="width: 32px; height: 32px; color: #ffffff"
                    [svgIcon]="item.icon"
                  ></mat-icon>
                </div>
                <span class="card-title fw-bold fs-16">{{
                  item.category
                }}</span>
                <div class="mt-3">
                  <div class="mt-2" *ngFor="let link of item.sub_groups">
                    <a
                      class="text-primary fs-14"
                      [routerLink]="
                        item.code + '/subgrupo/' + link.id + '/vista/1'
                      "
                      >{{ link.name }}</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
        <div class="col-12 mb-4">
          <ng-template
            [ngTemplateOutlet]="TheGeekTemplate"
            [ngTemplateOutletContext]="{ $implicit: findByCode('CON') }"
            #TheGeekTemplate
            let-item
          >
          </ng-template>
        </div>
      </div>
      <div class="col-12 col-lg-4">
        <!-- <div class="col-12 mb-4">
          <ng-template
            [ngTemplateOutlet]="TheGeekTemplate"
            [ngTemplateOutletContext]="{ $implicit: findByCode('SLN') }"
            #TheGeekTemplate
            let-item
          >
          </ng-template>
        </div> -->
        <div class="col-12 mb-4">
          <ng-template
            [ngTemplateOutlet]="TheGeekTemplate"
            [ngTemplateOutletContext]="{ $implicit: findByCode('ITP') }"
            #TheGeekTemplate
            let-item
          >
          </ng-template>
        </div>
        <div class="col-12 mb-4">
          <ng-template
            [ngTemplateOutlet]="TheGeekTemplate"
            [ngTemplateOutletContext]="{ $implicit: findByCode('SES') }"
            #TheGeekTemplate
            let-item
          >
          </ng-template>
        </div>
      </div>
      <div class="col-12 col-lg-4">
        <!-- <div class="col-12 mb-4">
          <ng-template
            [ngTemplateOutlet]="TheGeekTemplate"
            [ngTemplateOutletContext]="{ $implicit: findByCode('TSF') }"
            #TheGeekTemplate
            let-item
          >
          </ng-template>
        </div> -->
        <!-- <div class="col-12 mb-4">
          <ng-template
            [ngTemplateOutlet]="TheGeekTemplate"
            [ngTemplateOutletContext]="{ $implicit: findByCode('ESP') }"
            #TheGeekTemplate
            let-item
          >
          </ng-template>
        </div> -->
        <div class="col-12 mb-4">
          <ng-template
            [ngTemplateOutlet]="TheGeekTemplate"
            [ngTemplateOutletContext]="{ $implicit: findByCode('HTD') }"
            #TheGeekTemplate
            let-item
          >
          </ng-template>
        </div>
        <div class="col-12 mb-4">
          <ng-template
            [ngTemplateOutlet]="TheGeekTemplate"
            [ngTemplateOutletContext]="{ $implicit: findByCode('IOT') }"
            #TheGeekTemplate
            let-item
          >
          </ng-template>
        </div>
        <div class="col-12 mb-4">
          <ng-template
            [ngTemplateOutlet]="TheGeekTemplate"
            [ngTemplateOutletContext]="{ $implicit: findByCode('DSR') }"
            #TheGeekTemplate
            let-item
          >
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
