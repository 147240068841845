import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-resources-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  @ViewChild('auto') auto: any;

  items: any[];
  keyword = 'name';

  constructor(private router: Router) {
    this.items = [];
  }

  ngOnInit(): void {
    this.getInformation();
  }

  getInformation() {
    this.items = [
      {id: 1, name: "Publicaciones > Informe Mensual del Empleo Privado Formal", url: "/recursos/publicaciones/archivos/1"},
      {id: 2, name: "Publicaciones > Informe Anual del Empleo Privado Formal", url: "/recursos/publicaciones/anuales/2"},
      {id: 3, name: "Publicaciones > Informe Trimestral del Empleo", url: "/recursos/publicaciones/archivos/3"},
      {id: 4, name: "Publicaciones > Informes Temáticos y Grupos Vulnerables", url: "/recursos/publicaciones/informes/4"},
      {id: 5, name: "Publicaciones > Informe de Demanda de Ocupaciones", url: "/recursos/publicaciones/anuales/5"},
      {id: 6, name: "Publicaciones > Boletín de Economía Laboral", url: "/recursos/publicaciones/anuales/6"},
      {id: 7, name: "Publicaciones > Reportes del Mercado Laboral", url: "/recursos/publicaciones/anuales/7"},
      {id: 8, name: "Publicaciones > Anuario Estadístico Sectorial", url: "/recursos/publicaciones/anuales/8"},
      {id: 9, name: "Publicaciones > Estudios de Trabajo", url: "/recursos/publicaciones/informes/9"},
      {id: 10, name: "Publicaciones > Leyendo en Números", url: "/recursos/publicaciones/archivos/10"},
      {id: 11, name: "Publicaciones > Boletín Estadístico Mensual: Notificaciones de accidentes de trabajo, incidentes peligrosos y enfermedades ocupacionales.", url: "/recursos/publicaciones/archivos/11"},
      {id: 12, name: "Microdatos > Encuesta Nacional de Hogares (ENAHO)", url: "/recursos/microdatos"},
      {id: 13, name: "Microdatos > Encuesta de Demanda Ocupacional (EDO)", url: "/recursos/microdatos"},
      {id: 14, name: "Microdatos > Planilla electrónica", url: "/recursos/microdatos"}
    ]
  }

  selectEvent(item: any) {
    this.router.navigate([item.url]);
    this.auto.close();
  }

  onFocused(e: any) {
    this.auto.close();
  }

  onCleared(e: any) {
    this.auto.close();
  }
}
