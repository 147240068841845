<!-- <div
  class="text-start fw-bold mt-4 mb-4"
  style="font-size: 24px; color: #cf000b"
>
  {{ information.title }}
</div>
<div>
  <div [innerHTML]="information.description"></div>
  <div class="desciption">Fuente: Computrabajo. Última actualización: {{ formatUpdatingDate() }}</div>
  <br>
</div> -->

<h4 class="mb-4">Total de ofertas de empleo en línea</h4>
<div class="row">
  <div class="col-sm-12 col-lg-4">
    <div class="card simple">
      <div class="row">
        <div class="col-sm-12">
          <div class="card-body">
            <h5>Computrabajo</h5>
            <h1 style="display: block; text-align: right" class="text-primary">
              100
            </h1>
            <h4 style="display: block; text-align: right" class="fs-16">
              ofertas
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-sm-12 col-lg-6">
    <h4 class="mb-4">Ofertas de empleo por región</h4>

    <svg id="presential-map"></svg>
  </div>

  <div class="col-sm-12 col-lg-6">
    <h4 class="mb-4">Ofertas de empleos mejor remuneradas (en soles)</h4>
    <app-home-topten></app-home-topten>
  </div>
</div>

<div class="row">
  <div class="col-sm-12 col-lg-6">
    <h4 class="mb-4">Ofertas de empleo por tipo de empleo</h4>
    <app-pie-job></app-pie-job>
  </div>

  <div class="col-sm-12 col-lg-6">
    <h4 class="mb-4">Ofertas de empleo por tipo de contrato</h4>
    <app-pie-contract></app-pie-contract>
  </div>
</div>

<div class="row">
  <div class="col-sm-12 col-lg-6">
    <h4 class="mb-4">Ofertas de empleo por nivel de experiencia requerida</h4>
    <app-stack-experience></app-stack-experience>
  </div>

  <div class="col-sm-12 col-lg-6">
    <h4 class="mb-4">Ofertas de empleo por nivel de educación mínima</h4>
    <app-stack-education></app-stack-education>
  </div>
</div>
