import { Component, Input, OnInit } from '@angular/core';
import { threadId } from 'worker_threads';
import 'dayjs/locale/es';
let dayjs = require('dayjs');

@Component({
  selector: 'app-osel-region-publications',
  templateUrl: './osel-publications.component.html',
  styleUrls: ['./osel-publications.component.scss'],
})
export class OselPublicationsComponent implements OnInit {
  @Input() publications: any;
  @Input() categories: any[] = [];
  @Input() years: any[] = [];
  category: any[];
  currentCategory: number;
  currentYear: number;
  totalResults: number;
  categoryName: any;
  categoryID: any;
  filteredList: any[];
  lastPublication: Date;
  utc = require('dayjs/plugin/utc');
  timezone = require('dayjs/plugin/timezone');

  constructor() {
    this.currentCategory = 0;
    this.currentYear = 0;

    this.totalResults = 0;
    this.filteredList = [];
    this.category = [];
    this.categoryName = [];
    this.categoryID = [];
    this.lastPublication = new Date();
  }

  ngOnInit(): void {
    this.getDefaultInformation();
  }

  getDefaultInformation() {
    this.filteredList = this.publications.results;
    this.totalResults = this.filteredList.length;

    this.years = this.filteredList.map((item) => item.year);
    this.years = this.years.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.years = this.years.sort((n1, n2) => (n1 > n2 ? -1 : 1));
    this.currentYear = this.years[0];

    this.lastPublication = new Date(
      Math.max(
        ...this.publications.results.map(
          (e: any) => new Date(e.osel_publicaciones_type.updated_at)
        )
      )
    );

    dayjs.extend(this.utc);
    dayjs.extend(this.timezone);
    this.lastPublication = dayjs(this.lastPublication)
      .tz('America/Lima')
      .format('YYYY-MM-DD HH:mm:ss');

    this.filterByYear(this.currentYear);
    this.orderByNew();
  }

  filterByCategory(category: number) {
    this.currentCategory = category;

    this.categoryID = this.publications.results.find(
      (x: any) => x.osel_publicaciones_type.id == this.currentCategory
    );
    this.categoryName =
      category === 0
        ? 'Todas las publicaciones'
        : this.categoryID.osel_publicaciones_type.title;

    if (this.currentCategory === 0) {
      this.getDefaultInformation();
    } else {
      this.filteredList = this.publications.results.filter(
        (item: any) => this.currentCategory === item.osel_publicaciones_type.id
      );
      this.years = this.filteredList.map((item) => item.year);
      this.years = this.years.filter(
        (elem, index, self) => index === self.indexOf(elem)
      );
      this.years = this.years.sort((n1, n2) => (n1 > n2 ? -1 : 1));
      this.currentYear = this.years[0];

      this.filteredList = this.publications.results.filter(
        (item: any) =>
          this.currentCategory === item.osel_publicaciones_type.id &&
          this.currentYear === item.year
      );
      this.totalResults = this.filteredList.length;
      this.lastPublication = new Date(
        Math.max(
          ...this.publications.results.map(
            (e: any) => new Date(e.osel_publicaciones_type.updated_at)
          )
        )
      );
      dayjs.extend(this.utc);
      dayjs.extend(this.timezone);
      this.lastPublication = dayjs(this.lastPublication)
        .tz('America/Lima')
        .format('YYYY-MM-DD HH:mm:ss');
    }
    this.orderByNew();
  }

  filterByYear($event: any) {
    if (typeof $event === 'number') {
      this.filteredList = this.publications.results.filter(
        (item: any) => $event == item.year
      );
    } else {
      if (this.currentCategory === 0) {
        this.filteredList = this.publications.results.filter(
          (item: any) => $event.target.value == item.year
        );
      } else {
        this.filteredList = this.publications.results.filter(
          (item: any) =>
            $event.target.value == item.year &&
            this.currentCategory === item.osel_publicaciones_type.id
        );
      }
    }
    this.totalResults = this.filteredList.length;
    this.orderByNew();
  }

  orderByNew() {
    let publications = this.filteredList;
    let orderByYear = publications.sort((n1, n2) =>
      parseInt(n2.year) > parseInt(n1.year) ? 1 : -1
    );
    let publicationsNew = [];
    let publicationsOld = [];
    for (const publication of orderByYear) {
      if (publication.recentlyAdded === true) {
        publicationsNew.push(publication);
      } else {
        publicationsOld.push(publication);
      }
    }
    this.filteredList = publicationsNew.concat(publicationsOld);
  }
}
