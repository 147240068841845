import { Component, Input } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';
import { TableUtil } from "src/app/services/table.Utils";

@Component({
  selector: 'app-google-chart-custom',
  templateUrl: './google-chart-custom.component.html',
  styleUrls: ['./google-chart-custom.component.scss'],
})
export class GoogleChartCustomComponent {
  @Input() information: any;
  @Input() tableData: any;
  @Input() multipleData: any;
  @Input() loading: any;
  multipleDataPosition: any;

  imageDownload: any;

  constructor(private utilService: UtilService) {
    this.loading = false;
    this.multipleDataPosition = 0;
  }

  onReady($event: any) {
    this.loading = false;
    this.imageDownload = $event.chart.getImageURI();
  }

  downloadImage() {
    let download = document.createElement('a');
    download.href = this.imageDownload;
    download.download = this.information.response.title;
    download.click();
    download.remove();
  }

  // downloadCsv() {
  //   let csv: any = [];
  //   let data = this.tableData;

  //   if (this.multipleData !== undefined) {
  //     data = this.multipleData[this.multipleDataPosition].data;
  //     data.forEach((x: any) => {
  //       csv.push({
  //         "actividad": x[0],
  //         "valor": Number(x[1])
  //       });
  //     });
  //   } else {
  //     data.forEach((x: any) => {
  //       if (x.length > 3) {
  //         csv.push({
  //           "periodo": x[0],
  //           "positivo": String(x[1]).includes("-") ? Number(x[3]) : Number(x[1]),
  //           "negativo": String(x[1]).includes("-") ? Number(x[1]) : Number(x[3])
  //         });
  //       } else {
  //         csv.push({
  //           "periodo": x[0],
  //           "valor": Number(x[1])
  //         });
  //       }
  //     });
  //   }
  //   this.utilService.downloadCsvFile(csv, this.information.response.title);
  // }

  downloadExcel() {
    let arrayForXls: any = [];
    let data = this.tableData;

    if (this.multipleData !== undefined) {
      data = this.multipleData[this.multipleDataPosition].data;
      data.forEach((x: any) => {
        arrayForXls.push({
          "actividad": x[0],
          "valor": Number(x[1])
        });
      });
    } else {
      data.forEach((x: any) => {
        if (x.length > 3) {
          arrayForXls.push({
            "periodo": x[0],
            "positivo": String(x[1]).includes("-") ? Number(x[3]) : Number(x[1]),
            "negativo": String(x[1]).includes("-") ? Number(x[1]) : Number(x[3])
          });
        } else {
          arrayForXls.push({
            "periodo": x[0],
            "valor": Number(x[1])
          });
        }
      });
    }

    //console.log("arrayForXls",arrayForXls);

    TableUtil.exportArrayToExcel(arrayForXls, this.information.response.title);
  }  

  changePosition(position: any) {
    this.multipleDataPosition = position;
  }
}
