<div class="text-start fw-bold mt-4 mb-4 fs-24" style="color: #cf000b">
  {{ information.title }}
</div>
<div>
  <div [innerHTML]="information.description"></div>
  <p>{{ information.addDescription }}</p>
  <div class="fw-bold fs-12" style="color: #6f777b">
    <span>{{ totalResults }} resultados</span>
  </div>
</div>
<div class="nav nav-tabs mt-5" id="nav-tab" role="tablist">
  <button
    class="nav-link fw-bold active"
    id="nav-public-tab"
    data-bs-toggle="tab"
    data-bs-target="#nav-public"
    type="button"
    role="tab"
    aria-controls="nav-public"
    aria-selected="false"
    (click)="mostrarBolsaPublico()"    
  >
    Bolsas del sector público
  </button>

  <button
    class="nav-link fw-bold"
    id="nav-private-tab"
    data-bs-toggle="tab"
    data-bs-target="#nav-private"
    type="button"
    role="tab"
    aria-controls="nav-private"
    aria-selected="false"
    (click)="mostrarBolsaPrivado()"
  >
    Bolsas del sector privado
  </button>
</div>

<div class="tab-content" id="nav-tabContent">
  <div
    class="tab-pane fade show active"
    id="nav-public"
    role="tabpanel"
    aria-labelledby="nav-public-tab"
  >

    <div class="row" *ngIf="publics.length > 0  else zeroRecords" >
      <div class="col-sm-12 mt-3" *ngFor="let iniciative of publics">
        <div class="card two-columns" *ngIf="tabPublicoLista==true" style="height: 200px">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-4 col-lg-3 image">
              <img
                class="lazyload"
                alt=""
                [attr.data-src]="imageBase + iniciative.image[0].url"
              />
            </div>
            <div class="col-12 col-sm-12 col-md-8 col-lg-9">
              <div class="card-body">
                <h4>Empleos Perú - MTPE</h4>
                <p class="description" [innerHTML]="iniciative.description"></p>
              </div>

              <div class="card-footer mb-3">
                <a class="text-primary fw-bold"  [routerLink]="" (click)="mostrarEmpleosPeru()">
                  Ver Información
                </a>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>


    <div class="mb-3 mt-1"  *ngIf="empleosPeru">
      <div  class="mb-3"  >
        <a class="breadcrumb-link fw-bold"  [routerLink]="" (click)="ocultarEmpleosPeru()">Bolsas del sector público</a>

        <span class="iconify text-secondary ms-1 me-1" [attr.data-icon]="'mdi:chevron-right'"></span>
        <span class="fs-13 fw-bold text-secondary">Empleos Perú</span>
      </div>

      <div class="button-group">
        <app-loading *ngIf="loading"></app-loading>
        <iframe 
             title="Tablero de Mando" width="100%" 
             height = "3050" 
             src = "https://app.powerbi.com/view?r=eyJrIjoiMDJjNjVlN2EtZjIwYS00MThjLWJhNWEtODU2ZDA5N2UwMDljIiwidCI6IjlmZjY1YjQ2LTAwMDYtNGEwZi04NmQ1LTMzMzY1OWI5MTZkOCIsImMiOjR9" 
             frameborder="0" 
             allowFullScreen="true">
        </iframe>
     </div>      

     </div>        
  </div>

  <div
    class="tab-pane fade show"
    id="nav-private"
    role="tabpanel"
    aria-labelledby="nav-private-tab"
  >

  <!-- <br>
  <div class="button-group">
    <iframe 
         title="Tablero de Mando" width="100%" 
         [height] = "2430" 
         src = "https://app.powerbi.com/view?r=eyJrIjoiZjZmNjMzNTEtZGU3ZS00OWI2LWI4ZTAtNTcyODE1NDRlNGNmIiwidCI6ImMwZTJmMGQzLTk3MDctNGRiZS1iYWE3LWJjYzg3MGZjNTZhMCJ9&pageName=ReportSection" 
         frameborder="0" 
         allowFullScreen="true">
    </iframe>
 </div> -->

  <div class="row" *ngIf="privates.length > 0  else zeroRecords" >
    <div class="col-sm-12 mt-3" *ngFor="let iniciative of privates">
      <div class="card two-columns" *ngIf="tabPrivadoLista==true" style="height: 200px">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-4 col-lg-3 image">
            <img
              class="lazyload"
              alt=""
              [attr.data-src]="imageBase + iniciative.image[0].url"
            />
          </div>
          <div class="col-12 col-sm-12 col-md-8 col-lg-9">
            <div class="card-body">
              <h4>Computrabajo</h4>
              <p class="description" [innerHTML]="iniciative.description"></p>
            </div>

            <div class="card-footer mb-3">
              <a class="text-primary fw-bold"  [routerLink]="" (click)="mostrarComputrabajo()">
                Ver Información
              </a>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="mb-3 mt-1"  *ngIf="computrabajo">
    <div  class="mb-3"  >
      <a class="breadcrumb-link fw-bold"  [routerLink]="" (click)="ocultarComputrabajo()">Bolsas del sector privado</a>

      <span class="iconify text-secondary ms-1 me-1" [attr.data-icon]="'mdi:chevron-right'"></span>
      <span class="fs-13 fw-bold text-secondary">Computrabajo</span>
    </div>

    <!-- <app-occupational-job-numbers></app-occupational-job-numbers> -->
    <div class="button-group">
      <app-loading *ngIf="loading"></app-loading>
      <iframe 
           title="Tablero de Mando" width="100%" 
           height = "2420" 
           src = "https://app.powerbi.com/view?r=eyJrIjoiZjZmNjMzNTEtZGU3ZS00OWI2LWI4ZTAtNTcyODE1NDRlNGNmIiwidCI6ImMwZTJmMGQzLTk3MDctNGRiZS1iYWE3LWJjYzg3MGZjNTZhMCJ9&pageName=ReportSection" 
           frameborder="0" 
           allowFullScreen="true">
      </iframe>
   </div>      

  </div>
  </div>
  <ng-template #zeroRecords>
    <app-zero-records></app-zero-records>
  </ng-template>
</div>
