import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { UtilService } from '../../services/util.service';

import * as d3 from 'd3';
import * as topojson from 'topojson-client';

@Component({
  selector: 'app-occupational-job-numbers',
  templateUrl: './job-numbers.component.html',
  styleUrls: ['./job-numbers.component.scss'],
})
export class JobNumbersComponent implements OnInit {
  innerWidth: number;
  information: any;
  projection = d3.geoAlbers();
  isSelected: boolean;
  selectedId: string;
  lastMonth = (new Date()).getMonth() - 1 >= 1 ?  (new Date()).getMonth() : 1;
  updatingDate = new Date((new Date().getFullYear()), this.lastMonth , 0);
  monthsName = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];

  constructor(
    private apiService: ApiService,
    private utilService: UtilService
  ) {
    this.innerWidth = window.innerWidth;
    this.information = {};
    this.isSelected = false;
    this.selectedId = '';
  }

  ngOnInit(): void {
    this.getInformation();
    this.getJobCenters();
  }

  async getInformation() {
    let header = await this.apiService.getOne(
      '/job-developments?code=empleo-en-numeros'
    );

    this.information = {
      title: header.title,
      description: header.description,
    };
  }

  async getJobCenters() {
    let stateIds: number[] = [4, 13, 15, 7];

    this.draw(stateIds);
  }

  draw(stateIds: number[]) {
    let width = this.innerWidth < 500 ? 350 : 500;
    let height = this.innerWidth < 500 ? 500 : 750;

    const svg = d3
      .select('#presential-map')
      .attr('width', width)
      .attr('height', height);
    const path: any = d3.geoPath().projection(this.projection);

    d3.json('/assets/peru.json').then((json: any) => {
      const geojson: any = topojson.feature(json, json.objects.departments);
      this.projection.rotate([100, 40]).fitExtent(
        [
          [0, 0],
          [width, height],
        ],
        geojson
      );

      svg
        .selectAll('path')
        .data(geojson.features)
        .enter()
        .append('path')
        .attr('d', path)
        .attr(
          'id',
          (r: any) => `r-${r.properties.NOMBDEP.replaceAll(' ', '-')}`
        )
        .style('stroke', '#fff')
        .style('stroke-width', 1.5)
        .attr('fill', '#DCE1EA');

      d3.json('/assets/markers.json').then((data: any) => {
        let filtered = data.filter((x: any) => stateIds.includes(x.id));
        filtered.forEach((x: any) => {
          svg
            .select(`#r-${x.place.replaceAll(' ', '-')}`)
            .attr('class', 'region');
        });

        svg
          .selectAll('text')
          .data(filtered)
          .enter()
          .append('text')
          .attr('transform', (d: any) => {
            return `translate(${this.projection([
              d.lon - d.place.length * 0.06,
              d.lat - 0,
            ])})`;
          })
          .attr('font-size', '12')
          .text((d: any) => {
            if (d.place === 'AREQUIPA') {
              return d.place + ' - 6';
            } else if (d.place === 'LA LIBERTAD') {
              return d.place + ' - 5';
            } else if (d.place === 'LIMA') {
              return d.place + ' - 85';
            // } else if (d.place === 'CALLAO') {
            //   return d.place + ' - 4';
            } else {
              return d.place;
            }
          });
      });
    });
  }

  defineMapMarkers() {}

  formatUpdatingDate() {
    return this.monthsName[this.updatingDate.getMonth()] + ", " + this.updatingDate.getFullYear();
  }
}
