import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-detail-economic-activity',
  templateUrl: './economic-activity.component.html',
  styleUrls: ['./economic-activity.component.scss'],
})
export class EconomicActivityComponent implements OnInit {
  @Input() actividad_ecnonomica_remuneraciones: any;
  @Input() actividad_ecnonomica_trabajadores: any;
  @Input() primerLevelCode: any;
  view: number;
  information: any;
  imageDownload: any;
  @Input() actividad_economica: any;
  @Input() loading: boolean;
  description: any;
  source: any;  
  technical_note: any;

  constructor() {
    this.view = 1;
    this.loading = false;
    //this.information = null;
  }

  ngOnInit(): void {
    this.load();
  }

   async load() {
    
    if (this.primerLevelCode === undefined) {
      await new Promise((f) => setTimeout(f, 400));
      this.load();
      return;
    }

    if (this.primerLevelCode ==='0') {
      this.description = 'Representa la remuneración promedio mensual y la distribución de trabajadores por actividad económica que se desempeñan en la ocupación seleccionada.';
      this.source = 'MTPE-Planilla Electrónica (PLAME y T-Registro) del sector público. La información corresponde a los asalariados del sector público.';
      this.technical_note = '<p align="justify">La remuneración promedio en el sector público se refiere al monto pagado por conceptos pensionarios, no pensionarios y por Comité de Administración de Fondos de Asistencia y Estímulo (CAFAE).<br>El porcentaje se refiere al porcentaje relativo de puestos de trabajo registrados en cada actividad económica, respecto al total de trabajadores en la ocupación.</p>';      
    } else 
    {
      this.description = 'Representa la remuneración promedio mensual y la distribución de trabajadores por actividad económica que se desempeñan en la ocupación seleccionada.';
      this.source = 'MTPE-Planilla Electrónica (PLAME y T-Registro) del sector privado. La información corresponde a los asalariados del sector formal privado.';      
      this.technical_note = '<p align="justify">La remuneración promedio del sector privado formal se refiere a las remuneraciones declaradas por el empleador en la Planilla Electrónica declaradas. Se considera a las remuneraciones de trabajadores percibidas por renta de quinta categoría y correspondientes al monto pagado por conceptos permanentes.<br>El porcentaje se refiere al porcentaje relativo de puestos de trabajo registrados en cada actividad económica, respecto al total de trabajadores en la ocupación.</p>';      
    } 

    this.information = {
      technical_note: this.technical_note,
      id: 'actividad-economica',
      buttonsInNewLine: true,
      customStyle: 'width: 100%; height: 536px',
      response: {
        title: 'Por actividad económica',
        description: this.description,
        source: this.source,
      },
      type: 'BarChart',
      columns: [
        { type: 'string', role: 'domain', label: 'Ocupación' },
        { type: 'number', role: 'data', label: 'Valor' },
        { type: 'string', role: 'annotation', label: 'Valor' },
      ],
      options: {
        legend: { position: 'none' },
        bar: { groupWidth: '50%' },
        vAxis: {
          textStyle: {
            color: '#727B7D',
            fontSize: 12,
          },
        },
        hAxis: {
          textPosition: 'none',
          gridlineColor: 'none',
        },
        series: {
          0: {
            color: '#3C63B0',
            annotations: {
              textStyle: {
                strokeWidth: 0,
                fontSize: 12,
                color: '#3C63B0',
              },
            },
          },
        },
        annotations: {
          stemColor: 'none',
          alwaysOutside: true,
        },
        chartArea: {
          left: 255,
          right: 60,
          top: 0,
          bottom: 20,
        },
      },
    };
  }
}
