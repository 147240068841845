<h2>Buttons</h2>

<div class="row">
  <div class="col-2">
    <button type="button" class="btn btn-primary">Primary</button>
  </div>
  <div class="col-2">
    <button type="button" class="btn btn-outline-primary">Outline</button>
  </div>
  <div class="col-2">
    <button type="button" class="btn btn-link">Link</button>
  </div>
  <div class="col-2">
    <button type="button" class="btn btn-outline-primary disabled">
      Disabled
    </button>
  </div>
  <div class="col-2">
    <button type="button" class="btn btn-primary">
      <mat-icon class="text-inverted" svgIcon="file-download-outline"></mat-icon
      >With icon
    </button>
  </div>
  <div class="col-2">
    <button type="button" class="btn btn-outline-primary">
      <mat-icon svgIcon="file-download-outline"></mat-icon>With icon
    </button>
  </div>
</div>
<br />

<h2>Buttons group</h2>
<div class="row">
  <div class="mt-4 button-group">
    <button type="button" class="active">Filtro 1</button>
    <button type="button" class="">Filtro 2</button>
    <button type="button" class="">Filtro 3</button>
    <button type="button" class="">Filtro 4</button>
  </div>
</div>
