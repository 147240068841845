import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { HomeModule } from './home/home.module';
import { DiscoverModule } from './discover/discover.module';
import { DetailModule } from './detail/detail.module';
import { OccupationalModule } from './occupational/occupational.module';
import { DesignSystemModule } from './design-system/design-system.module';
import { ResourcesModule } from './resources/resources.module';
import { OutlookModule } from './outlook/outlook.module';
import { PresentialModule } from './presential/presential.module';
import { ServiceWorkerModule } from '@angular/service-worker';

import { AppComponent } from './app.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';

import { environment } from 'src/environments/environment';
import { Event, Router, Scroll } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { filter, pairwise } from 'rxjs/operators';
import { TableroModule } from './tablero/tablero.module';


@NgModule({
  declarations: [AppComponent, MainLayoutComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    HomeModule,
    DiscoverModule,
    DetailModule,
    OccupationalModule,
    ResourcesModule,
    DesignSystemModule,
    OutlookModule,
    PresentialModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    TableroModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private router: Router, private viewportScroller: ViewportScroller) {
    this.handleScrollOnNavigation();
  }

  private handleScrollOnNavigation(): void {
    let width = window.innerWidth;
    this.router.events.pipe(
      filter((e: Event): e is Scroll => e instanceof Scroll),
      pairwise()
    ).subscribe((e: Scroll[]) => {
      const previous = e[0];
      const current = e[1];
      if (current.position) {
        this.viewportScroller.scrollToPosition(current.position);
      } else if (current.anchor) {
        this.viewportScroller.scrollToAnchor(current.anchor);
      } else {
        if (this.getBaseRoute(previous.routerEvent.urlAfterRedirects)
          !== this.getBaseRoute(current.routerEvent.urlAfterRedirects)) {
          if (previous.routerEvent.urlAfterRedirects.includes("/recursos-desarrollo-laboral")
            && current.routerEvent.urlAfterRedirects.includes("/recursos-desarrollo-laboral")) {
            if (width >= 1300) {
              this.viewportScroller.scrollToPosition([360, 360]);
            }
            else if (width < 1300 && width >= 1200) {
              this.viewportScroller.scrollToPosition([320, 320]);
            }
            else if (width < 1200 && width >= 1000) {
              this.viewportScroller.scrollToPosition([1250, 1250]);
            }
            else if (width < 1000 && width >= 800) {
              this.viewportScroller.scrollToPosition([1350, 1350]);
            }
            else if (width < 800 && width >= 600) {
              this.viewportScroller.scrollToPosition([1000, 1000]);
            }
            else if (width < 600 && width >= 400) {
              this.viewportScroller.scrollToPosition([1050, 1050]);
            }
          } else {
            this.viewportScroller.scrollToPosition([0, 0]);
          }
        }
      }
    });
  }

  private getBaseRoute(url: string): string {
    return url.split('?')[0];
  }
}
