import { Component, OnInit } from '@angular/core';
import { ChartType } from 'angular-google-charts';

@Component({
  selector: 'app-shared-barchart',
  templateUrl: './barchart.component.html',
  styleUrls: ['./barchart.component.scss']
})
export class BarChartComponent implements OnInit {

  loading: boolean;
  bcImageDownload: any;
  bcMyType = ChartType.ColumnChart;
  bcMyOptions: any = {};
  bcMyColumns: any = [];
  bcMyData: any = [];

  constructor() {
    this.loading = true;
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.load();
    }, 1000);
  }

  load() {
    this.bcMyData = [
      ['2020-05', 1102, '+1,102', -151, '-151'],
      ['2020-06', 655, '+655', -523, '-523'],
      ['2020-07', 876, '+876', -310, '-310'],
      ['2020-08', 534, '+534', -420, '-420'],
      ['2020-09', 1320, '+1320', -301, '-301'],
      ['2020-10', 452, '+452', -146, '-146'],
      ['2020-11', 82, '+82', -87, '-87'],
      ['2020-12', 98, '+98', -187, '-187'],
      ['2021-01', 564, '+564', -602, '-602'],
      ['2021-02', 1804, '+1804', -694, '-694'],
      ['2021-03', 754, '+754', -296, '-296'],
      ['2021-04', 1653, '+1653', -94, '-94'],
      ['2021-05', 352, '+352', -464, '-464']
    ];
    this.bcMyColumns = [
      { type: 'string', role: 'domain', label: 'Month' },
      { type: 'number', role: 'data', label: 'Nuevos contratos' },
      { type: 'string', role: 'annotation' },
      { type: 'number', role: 'data', label: 'Desvinculados' },
      { type: 'string', role: 'annotation' },
  
    ];
    this.bcMyOptions = {
      legend: {position: 'bottom', textStyle: {color: '#727B7D', fontSize: 12}},
      vAxis: {
        gridlineColor: 'none',
        textPosition: 'none'
      },
      hAxis: {
        textStyle: {
          fontSize: 12,
          color: '#727B7D'
        }
      },
      annotations: {
        stemColor : 'none',
        alwaysOutside: true,
        highContrast: false
      },
      isStacked: true,
      tooltip: {
        trigger: 'none'
      },
      series: {
        0: {
          color: "#0056AC",
          backgroundColor: "#0056AC",
          annotations: {
            stem: {
              length: 1
            },
            textStyle: {
              fontSize: 12, 
              color: '#0056AC'
            }
          }
        },
        1: {
          color: "#B21414",
          backgroundColor: "#B21414",
          annotations: {
            stem: {
              length: -70
            },
            textStyle: {
              fontSize: 12, 
              color: '#B21414',
            }
          }
        }
      },
      chartArea: {
        left: 20, right: 20, top: 20, bottom: 60
      },
    };
    this.loading = false;
  }

  onBarChartReady($event: any) {
    this.bcImageDownload = $event.chart.getImageURI();
  }
}
