import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-detail-employee-trend',
  templateUrl: './employee-trend.component.html',
  styleUrls: ['./employee-trend.component.scss'],
})
export class EmployeeTrendComponent implements OnInit, OnDestroy {
  @Input() tendencia_trabajadores: any;
  @Input() loading: boolean;
  @Input() primerLevelCode: any;
  information: any;
  imageDownload: any;
  detailServiceSubscription: Subscription;
  description: any;
  source: any;  
  technical_note: any;

  constructor() {
    this.loading = false;
    //this.information = {};
    this.detailServiceSubscription = Subscription.EMPTY;
  }

  ngOnInit(): void {
    this.load();
  }

  async load() {
    if (this.primerLevelCode === undefined) {
      await new Promise((f) => setTimeout(f, 400));
      this.load();
      return;
    }

    if (this.primerLevelCode ==='0') {
      this.description = 'Representa la cantidad de trabajadores registrados mensualmente en el sector público que se desempeñan en la ocupación seleccionada.';
      this.source = 'MTPE-Planilla Electrónica (PLAME y T-Registro) del sector público. La información corresponde a los asalariados del sector público.';
    } else 
    {
      this.description = 'Representa la cantidad de trabajadores registrados mensualmente en el sector formal privado que se desempeñan en la ocupación seleccionada.';
      this.source = 'MTPE-Planilla Electrónica (PLAME y T-Registro) del sector privado. La información corresponde a los asalariados del sector formal privado.';      
    } 

    this.information = {
      technical_note: `<p align="justify">Información basada en la Planilla Mensual de Pagos (PLAME) y T-Registro de Planilla Electrónica del MTPE. En el sector formal privado se entiende por trabajador privado a toda persona natural que presta servicios a un empleador bajo una relación de subordinación, sujeto a cualquier régimen laboral de la actividad privada, cualquiera sea la modalidad del contrato de trabajo. Considera a puestos de trabajo declarados una sola vez en una empresa, pertenecientes a renta de quinta categoría del sector privado; no incluye personal de modalidades formativas laborales; pensionistas; personal independiente con renta de cuarta categoría (locadores de servicios); y aquellos con suspensión perfecta durante todo el mes y además remuneración igual a cero.</p>
      <p align="justify">En el caso de los trabajadores del sector público, este grupo se conforma de puestos de trabajo registrados en la Planilla Mensual de Pagos (PLAME) y T-Registro de Planilla Electrónica del MTPE de entidades o instituciones que registraron tener un tipo de administración pública y pública-privada.</p>`,
      id: 'tendencia-trabajadores',
      response: {
        title: 'Tendencia de trabajadores',
        description: this.description,
        source: this.source,
      },
      type: 'LineChart',
      columns: [
        { type: 'string', role: 'domain', label: 'Mes' },
        { type: 'number', role: 'data', label: 'Cantidad' },
        { type: 'string', role: 'annotation', label: 'Cantidad' },
      ],
      options: {
        legend: {
          position: 'none',
        },
        pointsVisible: true,
        vAxis: {
          textStyle: {
            fontSize: 12,
            color: '#727B7D',
          },
          format: ''
        },
        hAxis: {
          textStyle: {
            fontSize: 12,
            color: '#727B7D',
          },
        },
        series: {
          0: {
            color: '#B13C3C',
            annotations: {
              textStyle: {
                strokeWidth: 0,
                fontSize: 12,
                color: '#B13C3C',
              },
            },
          },
        },
        annotations: {
          stemColor: 'none',
          alwaysOutside: true,
        },
        chartArea: {
          left: 60,
          right: 20,
          top: 40,
          bottom: 40,
        },
        pointSize: 8,
      },
    };
  }

  ngOnDestroy(): void {
    this.detailServiceSubscription.unsubscribe();
  }
}
