import { Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-publications-misc',
  templateUrl: './publications-misc-report.component.html',
  styleUrls: ['./publications-misc-report.component.scss'],
})
export class PublicationsMiscComponent implements OnInit, OnDestroy {
  paramsSubscription: Subscription;
  publicationTypeId: any;

  headers: any;
  publications: any[];
  periods: any;

  periodsSelection: any;
  periodSelected: any;

  formats: any;
  formatsSelection: any;

  strapiUrl: string;
  downloading: boolean;
  enableDownload: boolean;

  titles: any;
  titleSelected: any;

  constructor(private router: ActivatedRoute, private apiService: ApiService, private cdr: ChangeDetectorRef) {
    this.paramsSubscription = Subscription.EMPTY;
    this.publicationTypeId = '';

    this.headers = null;
    this.publications = [];
    this.periods = [];
    this.periodsSelection = [];
    this.formats = [];
    this.formatsSelection = [];

    this.strapiUrl = environment.strapiUrl;
    this.downloading = false;
    this.enableDownload = false;

    this.titles = [];
    this.titleSelected = '';
    this.periodSelected = '';
  }

  ngOnInit(): void {
    this.paramsSubscription = this.router.params.subscribe(
      ({ publicationTypeId }) => {
        this.publicationTypeId = publicationTypeId;
        this.getPublicationsData(publicationTypeId);
      }
    );
  }

  async getPublicationsData(publicationTypeId: any) {
    let publicationType = await this.apiService.getMany(
      `/publications-types/${publicationTypeId}`
    );

    let publications = await this.apiService.getMany(
      `/publications?publications_type.id=${publicationTypeId}&_limit=-1`
    );

    this.headers = publicationType;
    this.publications = publications;

    let publicationsTitles = publications.map(
      (publication: any) => publication.title
    );
    let uniqueTitles = [...new Set(publicationsTitles)];
    this.titles = uniqueTitles;
    this.titleSelection(this.titles[0]);
  }

  selectTitle(event: any) {
    this.titleSelection(event.target.value);
  }

  titleSelection(title: any) {
    this.titleSelected = title;
    this.periodSelected = "";

    let publicationsAvailable = this.publications.filter(
      (publication: any) => publication.title === title
    );
    let yearsAvailable = publicationsAvailable.map(
      (publication: any) => publication.year
    );
    let uniquePeriods = [...new Set(yearsAvailable)];

    uniquePeriods.sort((a, b) => {
      return Number(b) - Number(a);
    });

    this.periods = uniquePeriods;
    this.periodsSelection = [];
    this.cdr.detectChanges();
  }

  periodSelection(period: any) {
    this.periodSelected = period;
    if (!this.periodsSelection.includes(period)) {
      this.periodsSelection.push(period);
    } else {
      this.periodsSelection.splice(this.periodsSelection.indexOf(period), 1);
    }

    let title = this.titleSelected;
    let periods = this.periodsSelection;

    let publications = this.publications.filter(
      (publication: any) =>
        publication.title === title && periods.includes(publication.year)
    );

    let formatsAvailable = publications.map(
      (publication: any) => publication.extension
    );
    let uniqueFormats = [...new Set(formatsAvailable)];
    this.formats = uniqueFormats;
  }

  formatSelection(format: any) {
    if (!this.formatsSelection.includes(format)) {
      this.formatsSelection.push(format);
    } else {
      this.formatsSelection.splice(this.formatsSelection.indexOf(format), 1);
    }

    if (this.formatsSelection.length > 0) {
      this.enableDownload = true;
    } else {
      this.enableDownload = false;
    }
  }

  async download() {
    this.enableDownload = false;
    this.downloading = true;
    try {
      let title = this.titleSelected;
      let periods = this.periodsSelection;
      let formats = this.formatsSelection;

      let publications = this.publications.filter(
        (publication: any) =>
          publication.title === title &&
          periods.includes(publication.year) &&
          formats.includes(publication.extension)
      );

      let linkDownload = '';

      if (publications.length === 1) {
        linkDownload = publications[0].link;
      } else {
        let links = publications.map(
          (publication) => `files=${encodeURIComponent(publication.link)}`
        );
        let queryString = links.join('&');
        let response = await this.apiService.getMany(
          `/files/zip?${queryString}`
        );
        linkDownload = this.strapiUrl + response.link;
      }

      let download = document.createElement('a');
      download.href = linkDownload;
      download.target = '_blank';
      download.click();
      this.enableDownload = true;
      this.downloading = false;
    } catch (error) {
      this.enableDownload = true;
      this.downloading = false;
    }
  }

  ngOnDestroy(): void {
    this.paramsSubscription.unsubscribe();
  }
}
