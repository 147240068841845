import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'card-ol',
  templateUrl: './card-ol.component.html',
  styleUrls: ['./card-ol.component.scss'],
})
export class card_ol implements OnInit {
  information: any;
  imageBase: string;

  constructor(private apiService: ApiService) {
    this.information = {};
    this.imageBase = environment.strapiUrl;
  }

  ngOnInit(): void {
    this.getLaborOrientationData();
  }

async getLaborOrientationData() {
    let items = await this.apiService.getMany('/nav-cards');

    this.information = {
      results: items,
    };
  }
}
