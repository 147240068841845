<div class="mt-4">
  <div class="alert alert-light text-center" role="alert" *ngIf="loading">
    <h4 class="alert-heading">
      Cargando <span class="fa fa-sync fa-spin fa-1x"></span>
    </h4>
    <p class="mb-0"></p>
  </div>
  <div *ngIf="!loading">
    <google-chart
      style="margin-top: 0.5rem; width: 100%; height: 300px"
      [type]="bcMyType"
      [data]="bcMyData"
      [options]="bcMyOptions"
      [columns]="bcMyColumns"
      (ready)="onBarChartReady($event)"
    >
    </google-chart>
  </div>
</div>
