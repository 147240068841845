 
<div class="container">
    <div class="mb-4 mt-4">
        <a class="btn-link fs-12" routerLink="/tablero-mando">
          <mat-icon svgIcon="arrow-left"></mat-icon>
          Volver a lista de Tableros de mando
        </a>
      </div>
      <h4 class="mt-4"  *ngIf="tableroDemanda">
        {{ tableroDemanda.Title }}
      </h4>

    <div>
        <div class="">
          <p align="justify" 
            class="description"
            [innerHTML]="tableroDemanda.content">
          </p>               
        </div>
        <br>      
    </div>


    <div class="button-group" *ngIf="seguraUrl">
       <iframe 
            title="Tablero de Mando" width="100%" 
            [height] = "tableroDemanda.dashboard_height" 
            [src] = "seguraUrl" 
            frameborder="0" 
            allowFullScreen="true">
       </iframe>
    </div>
 
    <div class="row" style="margin-top: 1rem">
      <div class="col text-center">
        <button class="btn btn-primary" routerLink="{{ information.buttonLink }}">
          {{ information.buttonText }}
        </button>
      </div>
    </div> 


</div>
