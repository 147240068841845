import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-what-is-obl',
  templateUrl: './what-is-obl.component.html',
  styleUrls: ['./what-is-obl.component.scss']
})

export class WhatIsOblComponent implements OnInit {
  loading: boolean;
  information: any;
  jumbotron: any;

  constructor(private apiService: ApiService) {
    this.loading = true;
    this.information = {};
    this.jumbotron = {};
  }

  ngOnInit(): void {
    this.getInformation();
  }

  async getInformation() {
    await this.getJumbotron();
    await this.getWhatIsOblInfo();
    this.loading = false;
  }

  async getJumbotron() {
    this.jumbotron = await this.apiService.getOne(`/banners?code=sobre-obl`);
    if (this.jumbotron) {
      this.jumbotron.url = environment.strapiUrl + this.jumbotron.image[0].url;
    } else {
      this.jumbotron = {};
    }
  }

  async getWhatIsOblInfo() {
    let items = await this.apiService.getMany('/what-is-obl-content');
    this.information = {
      results: items,
    };
  }
}
