import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-allied-entities',
  templateUrl: './allied-entities.component.html',
  styleUrls: ['./allied-entities.component.scss'],
})
export class AlliedEntitiesComponent implements OnInit {
  information: any;
  imageBase: string;

  constructor(private apiService: ApiService) {
    this.information = {};
    this.imageBase = environment.strapiUrl;
  }

  ngOnInit(): void {
    this.getAlliesData();
  }

  async getAlliesData() {
    let items = await this.apiService.getMany('/allied-entities');

    this.information = {
      results: items,
    };
  }
}
