import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-microdata',
  templateUrl: './microdata.component.html',
  styleUrls: ['./microdata.component.scss'],
})
export class MicrodataComponent implements OnInit {
  information: any;
  publicationTypeId: any;

  publications: any[];
  periods: any;

  periodsSelection: any;

  formats: any;
  formatsSelection: any;

  strapiUrl: string;
  downloading: boolean;
  enableDownload: boolean;

  titles: any;
  titleSelected: any;
  periodSelected: any;
  formatSelected: any;

  constructor(private apiService: ApiService) {
    this.information = {};
    this.publicationTypeId = '';

    this.publications = [];
    this.periods = [];
    this.periodsSelection = [];
    this.formats = [];
    this.formatsSelection = [];

    this.strapiUrl = environment.strapiUrl;
    this.downloading = false;
    this.enableDownload = false;

    this.titles = [];
    this.titleSelected = '';
    this.periodSelected = '';
    this.formatSelected = '';
  }

  ngOnInit(): void {
    this.getPublicationsData();
  }

  async getPublicationsData() {
    let publicationType = await this.apiService.getMany(`/microdata-types`);
    let publications = await this.apiService.getMany(`/microdata?_sort=order:DESC,period:DESC`);

    this.publications = publications;
    this.titles = publicationType;
    this.titleSelection(this.titles[0]);
  }

  selectTitle(event: any) {
    let microdataObj = { id: event.target.value };
    this.titleSelection(microdataObj);
    this.periodsSelection = [];
    this.formatsSelection = [];
    this.formats = [];
    this.enableDownload = false;
    let cleanButton = document.getElementsByClassName('btn-check');
    for (let i = 0; i < cleanButton.length; i++) {
      let element = cleanButton[i] as HTMLInputElement;
      element.checked = false;
    }
  }

  titleSelection(title: any) {
    this.titleSelected = title;
    let publicationsAvailable = this.publications.filter(
      (publication: any) => publication.microdataTypeId.id == title.id
    );
    let yearsAvailable = publicationsAvailable.map(
      (publication: any) => publication.period
    );
    let uniquePeriods = [...new Set(yearsAvailable)];

    uniquePeriods.sort((a, b) => {
      return Number(b) - Number(a);
    });
    this.periods = uniquePeriods;
  }

  periodSelection(period: any) {
    this.periodSelected = period;
    if (!this.periodsSelection.includes(period)) {
      this.periodsSelection.push(period);
    } else {
      this.periodsSelection.splice(this.periodsSelection.indexOf(period), 1);
      if (this.periodsSelection.length == 0) {
        this.enableDownload = false;
        this.formatSelected = '';
        this.formatsSelection = [];
      }
    }

    let title = this.titleSelected;
    let periods = this.periodsSelection;

    let publications = this.publications.filter(
      (publication: any) =>
        publication.microdataTypeId.id == title.id &&
        periods.includes(publication.period)
    );

    let formatsAvailable = publications.map(
      (publication: any) => publication.extension
    );
    let uniqueFormats = [...new Set(formatsAvailable)];
    this.formats = uniqueFormats;
  }

  formatSelection(format: any) {
    this.formatSelected = format;
    if (!this.formatsSelection.includes(format)) {
      this.formatsSelection.push(format);
    } else {
      this.formatsSelection.splice(this.formatsSelection.indexOf(format), 1);
    }

    if (this.formatsSelection.length > 0) {
      this.enableDownload = true;
    } else {
      this.enableDownload = false;
    }
  }

  async download() {
    this.enableDownload = false;
    this.downloading = true;
    try {
      let title = this.titleSelected;
      let periods = this.periodsSelection;
      let formats = this.formatsSelection;

      let publications = this.publications.filter(
        (publication: any) =>
          publication.microdataTypeId.id == title.id &&
          periods.includes(publication.period) &&
          formats.includes(publication.extension)
      );

      let linkDownload = '';

      if (publications.length === 1) {

        if (publications[0].file.length > 0) {
          linkDownload = this.strapiUrl + publications[0].file[0].url;
        } else {
          linkDownload = publications[0].url;
        }

      } else {
        let links = publications.map((publication) => {
          let file;
          if (publication.file.length > 0) {
            file = `files=${encodeURIComponent(
              this.strapiUrl + publication.file[0].url
            )}`;
          } else {
            file = `files=${encodeURIComponent(publication.url)}`;
          }
          return file;
        });
        let queryString = links.join('&');
        let response = await this.apiService.getMany(
          `/files/zip?${queryString}`
        );
        linkDownload = this.strapiUrl + response.link;
      }

      let download = document.createElement('a');
      download.href = linkDownload;
      download.target = '_blank';
      download.click();
      this.enableDownload = true;
      this.downloading = false;

    } catch (error) {
      this.enableDownload = true;
      this.downloading = false;
    }
  }
}
