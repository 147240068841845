import { NgModule } from '@angular/core';
import { ExtraOptions, RouteReuseStrategy,RouterModule, Routes } from '@angular/router';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { CustomRouterReuseStrategy } from './custom-router-reuse.strategy';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'inicio',
    pathMatch: 'full',
  },
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./layouts/main-layout/main-layout.module').then(m => m.MainLayoutModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'inicio'
  }
];

const routerOptions: ExtraOptions = 
  {
    scrollPositionRestoration: 'enabled',
    anchorScrolling:'enabled',
    onSameUrlNavigation: 'reload'
  };

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouterReuseStrategy,
    },
  ],    
})
export class AppRoutingModule { }
