<div class="mt-5">
  <h3 class="text-attention">{{ header.title }}</h3>
  <div *ngIf="header.title" class="mt-4 row">
    <div class="col-12 col-md-7">
      <p [innerHTML]="description"></p>
      <a class="btn-link fw-bold" (click)="changeVisibility()">
        {{ view === 1 ? "Ver más" : "Ver menos" }}
      </a>
    </div>
    <div class="col-12 col-md-5 mt-4 mt-sm-4 mt-md-0">
      <img width="100%" class="lazyload" [attr.data-src]="imageUrl" alt="" />
    </div>
  </div>
</div>
