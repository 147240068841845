import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-listado-tablero',
  templateUrl: './listado-tablero.component.html',
  styleUrls: ['./listado-tablero.component.scss']
})
export class ListadoTableroComponent implements OnInit {
  tableros: any;
  tableroDemanda: any;
  tableroOcupacional: any;
  imageBase: string;

  constructor(
    private apiService: ApiService) { 
      this.tableros = [];
      this.tableroDemanda = [];
     this.tableroOcupacional = [];
    
    this.imageBase = environment.strapiUrl;

  }

  ngOnInit(): void {
    this.getTableros();
    
  }

  async getTableros() {
    this.tableros = await this.apiService.getMany(
      `/tablero-de-mandos?_sort=orden:ASC`
    );

      console.log("this.tableros",this.tableros);
  }  

    
}

