<div *ngIf="information.url" class="p-5 mb-4 bg-light gradientback"
  [style.background]="'url(' + information.url + ')'"
  [style.background-position]="'center'"
  [style.background-repeat]="'no-repeat'"
  [style.background-size]="'cover'">
  <div class="container py-5">
    <div>
      <span class="display-5" style="
        color: #FFFFFF;
        font-style: normal;
        font-size: 48px;
        line-height: 56px;
        font-weight: bold;
        letter-spacing: 0.2px;">
        {{ information.title }}
      </span>
    </div>
    <br />
    <br />
    <p class="col-md-8 fs-16" style="color: #FFFFFF;">
      {{ information.description }}
    </p>
  </div>
</div>
<div *ngIf="!information.url" class="p-5 mb-4 bg-light"
  style="background-color: #F2F6FA !important;">
  <div class="container py-5">
    <div>
      <span class="display-5" style="
        font-style: normal;
        font-size: 48px;
        line-height: 56px;
        font-weight: bold;
        letter-spacing: 0.2px;">
        {{ information.title }}
      </span>
    </div>
    <br />
    <br />
    <p class="col-md-8 fs-16" style="color: #737B7D;">
      {{ information.description }}
    </p>
  </div>
</div>
