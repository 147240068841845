import { Component, OnInit } from '@angular/core';
import { ChartType } from 'angular-google-charts';

@Component({
  selector: 'app-home-topten',
  templateUrl: './topten.component.html',
  styleUrls: ['./topten.component.scss'],
})
export class TopTenComponent implements OnInit {
  information: any;

  chartType = ChartType.BarChart;
  chartOptions: any = {};
  chartColumns: any = [];

  constructor() {
    this.information = {};
  }

  ngOnInit(): void {
    this.getTopTenCharts();
  }

  getTopTenCharts() {
    this.information = {
      buttonText: 'Ver más sobre el Panorama laboral',
      buttonLink: '',
      charts: [
        {
          title: 'Top 10 ocupaciones más demandadas',
          source: 'Encuesta Nacional de Hogares (ENAHO) - INEI',
          data: [
            ['Chofer Repartidor de Gas Zonalero', 100, '17'],
            ['Sub Gerente de RRHH', 82, '14'],
            ['Jefe Corporativo de Recursos Humanos', 76, '13'],
            ['Eléctrico / Electricista - Especiali', 65, '11'],
            ['Ingeniero Residente - Mina Convencional', 62, '10'],
            ['Chief Customer Experience Officer', 47, '8'],
            ['Urólogo R X H', 41, '7'],
            ['Jefe de Aseguramiento de Calidad', 38, '6'],
            ['Analista funcional - Sector Tecnología', 38, '6'],
            ['Sociologo o Antropologo', 35, '6'],
            ['Contador Colegiado ', 29, '5'],
            ['Supervisor(a) de Mantenimiento ', 26, '4'],
            ['Diseñador Grafico Senior', 23, '4'],
            ['Ingeniero de Seguridad Industrial', 23, '4'],
            ['Administrador', 23, '4'],
            ['ingeniero mecánico', 23, '4'],
            ['Analista de validaciones de técnicas ', 20, '3'],
            ['Developer Back End', 20, '3'],
            ['Asesor inmobiliario - Con experiencia', 20, '3'],
            ['CON o SIN Experiencia en CALL Center ', 8, '1'],
            ['Asistente de Marketing Digital', 7, '1'],
          ],
        },
      ],
    };

    this.chartColumns = [
      { type: 'string', role: 'domain', label: 'Ocupaciones' },
      { type: 'number', role: 'data', label: 'Valor' },
      { type: 'string', role: 'annotation', label: 'Valor' },
    ];

    this.chartOptions = {
      height: 700,
      legend: { position: 'none' },
      bar: { groupWidth: '50%' },
      vAxis: {
        textStyle: {
          color: '#6f777b',
          fontSize: 12,
          fontName: 'Roboto',
        },
        baselineColor: 'none',
        gridlineColor: 'none',
        gridlines: { color: 'none' },
      },
      hAxis: {
        textPosition: 'none',
        baselineColor: 'none',
        gridlineColor: 'none',
        gridlines: { color: 'none' },
      },
      series: {
        0: {
          color: '#0056AC',
          annotations: {
            textStyle: {
              strokeWidth: 0,
              fontSize: 14,
              color: '#6F777B',
            },
          },
        },
      },
      annotations: {
        stemColor: 'none',
        alwaysOutside: true,
      },
      chartArea: {
        left: 150,
        right: 60,
        top: 10,
        bottom: 10,
      },
      tooltip: {
        //trigger: 'none'
      },
    };
  }
}
