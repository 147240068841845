import { Component, OnInit } from '@angular/core';
import { ChartType } from 'angular-google-charts';

@Component({
  selector: 'app-pie-job',
  templateUrl: './pie-job.component.html',
  styleUrls: ['./pie-job.component.scss'],
})
export class PieJobComponent implements OnInit {
  information: any;

  chartType = ChartType.PieChart;
  chartOptions: any = {};
  chartColumns: any = [];

  constructor() {
    this.information = {};
  }

  ngOnInit(): void {
    this.getTopTenCharts();
  }

  getTopTenCharts() {
    this.information = {
      buttonText: 'Ver más sobre el Panorama laboral',

      charts: [
        {
          title: 'Top 10 ocupaciones más demandadas',
          source: 'Encuesta Nacional de Hogares (ENAHO) - INEI',
          data: [
            ['Tiempo completo', 80],
            ['Tiempo parcial', 10],
            ['Desde casa', 6],
            ['Por horas', 4],
          ],
        },
      ],
    };

    this.chartColumns = [
      { type: 'string', role: 'domain', label: 'Ocupations' },
      { type: 'number', role: 'data', label: 'Value' },
      { type: 'string', role: 'annotation', label: 'Value' },
    ];

    this.chartOptions = {
      colors: ['#0056AC', '#1E456B', '#3573B1', '#7EA4CA', '#DCE1EA'],
      vAxis: {
        textStyle: {
          color: '#6f777b',
          fontSize: 12,
          fontName: 'Roboto',
        },
        baselineColor: 'none',
        gridlineColor: 'none',
        gridlines: { color: 'none' },
      },
      hAxis: {
        textPosition: 'none',
        baselineColor: 'none',
        gridlineColor: 'none',
        gridlines: { color: 'none' },
      },
      series: {
        0: {
          color: '#0056AC',
          annotations: {
            textStyle: {
              strokeWidth: 0,
              fontSize: 14,
              color: '#6F777B',
            },
          },
        },
      },
      annotations: {
        stemColor: 'none',
        alwaysOutside: true,
      },
      chartArea: {
        left: 10,
        right: 10,
        top: 10,
        bottom: 10,
      },
      tooltip: {
        //trigger: 'none'
      },
    };
  }
}
