<div class="trapezoider col-sm-12 col-md-4">
  <div class="container" style="padding-top: 24px;">
    Revisa los servicios de información que tenemos para tí, según tu perfil
  </div>
</div>

<carrousel></carrousel>
<div class="container">
  <br />
  <br />
  <cont-obl></cont-obl>
  <br />
  <br />
  <card-ol></card-ol>
  <br />
  <br />
  <app-information-links></app-information-links>
  <br />
  <br />
  <app-home-indicators></app-home-indicators>
  <br />
  <br />
  <app-home-topten></app-home-topten>
  <br />
  <br />
  <card-ep></card-ep>
  <app-allied-entities></app-allied-entities>
</div>
