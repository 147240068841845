<div class="container" style="padding-top: 6rem">
  <div class="text-center text-attention pb-1">
    <mat-icon svgIcon="alert-circle"></mat-icon>
  </div>
  <h4 class="text-center fw-bold pb-3">¡Muy Pronto!</h4>
  <p class="fs-16 text-center">
    Estamos trabajando en el contenido de esta sección.
  </p>
  <p class="fs-16 text-center fw-bold pb-1">
    ¿Deseas recibir una notificación cuando esté disponible?
  </p>
  <div class="row">
    <div class="text-center">
      <a [routerLink]="'.'" class="btn btn-primary" [fragment]="'Suscription'" (click)="onSuscriptionClick()">Suscríbete</a>
    </div>
  </div>
</div>
