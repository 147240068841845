<div>
  <h3 class="text-attention pb-2">Revisa más acerca del panorama laboral del país</h3>
</div>
<div class="row">
  <div
    class="col-sm-12 col-md-4"
    *ngFor="let card of information.results"
  >
    <div class="card image no-border no-shadow" *ngIf="card.code === 'panorama_laboral'">
      <div class="row">
        <div class="col-sm-12">
          <div class="card-header">
            <a routerLink="{{ card.url }}"
              ><img class="lazyload" [attr.data-src]="imageBase + card.image[0].url" alt=""
            /></a>
          </div>

          <div class="card-body">
            <a routerLink="{{ card.url }}">
              <h4 class="text-primary text-center">{{ card.title }}</h4>
              <p class="text-secondary text-center">
                {{ card.description }}
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>






<!-- ----------------- -->
<div>
  <h3 class="text-attention pb-2">Revisa publicaciones y microdatos</h3>
</div>
<div class="row">
  <div
    class="col-sm-12 col-md-4"
    *ngFor="let card of information.results"
  >
    <div class="card image no-border no-shadow" *ngIf="card.code === 'publicaciones_microdatos'">
      <div class="row">
        <div class="col-sm-12">
          <div class="card-header">
            <a routerLink="{{ card.url }}" *ngIf="card.url.substring(0,4) !== 'http'">
              <img class="lazyload" [attr.data-src]="imageBase + card.image[0].url" alt=""/>
            </a>
            <a href="{{ card.url }}" target="_blank" *ngIf="card.url.substring(0,4) === 'http'">
              <img class="lazyload" [attr.data-src]="imageBase + card.image[0].url" alt=""/>
            </a>            
          </div>

          <div class="card-body">
            <a routerLink="{{ card.url }}" *ngIf="card.url.substring(0,4) !== 'http'">
              <h4 class="text-primary text-center">{{ card.title }}</h4>
              <p class="text-secondary text-center">
                {{ card.description }}
              </p>
            </a>
            <a href="{{ card.url }}" target="_blank" *ngIf="card.url.substring(0,4) === 'http'">
              <h4 class="text-primary text-center">{{ card.title }}</h4>
              <p class="text-secondary text-center">
                {{ card.description }}
              </p>
            </a>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</div>


<!-- ----------------- -->
<div>
  <h3 class="text-attention pb-2">Revisa los observatorios temáticos </h3>
</div>
<div class="row">
  <div
    class="col-sm-12 col-md-4"
    *ngFor="let card of information.results"
  >
    <div class="card image no-border no-shadow" *ngIf="card.code === 'observatorios'">
      <div class="row">
        <div class="col-sm-12">
          <div class="card-header">
            <a routerLink="{{ card.url }}" *ngIf="card.url.substring(0,4) !== 'http'">
              <img class="lazyload" [attr.data-src]="imageBase + card.image[0].url" alt=""/>
            </a>
            <a href="{{ card.url }}" target="_blank" *ngIf="card.url.substring(0,4) === 'http'">
              <img class="lazyload" [attr.data-src]="imageBase + card.image[0].url" alt=""/>
            </a>            
          </div>

          <div class="card-body">
            <a routerLink="{{ card.url }}" *ngIf="card.url.substring(0,4) !== 'http'">
              <h4 class="text-primary text-center">{{ card.title }}</h4>
              <p class="text-secondary text-center">
                {{ card.description }}
              </p>
            </a>
            <a href="{{ card.url }}" target="_blank" *ngIf="card.url.substring(0,4) === 'http'">
              <h4 class="text-primary text-center">{{ card.title }}</h4>
              <p class="text-secondary text-center">
                {{ card.description }}
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
