import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';

import { IndicatorsComponent } from './indicators/indicators.component';
import { SharedModule } from '../shared/shared.module';

import { carrousel } from './carrousel/carrousel.component';
import { card_ol } from './card-orientacion-laboral/card-ol.component';
import { card_ep } from './card-estudios-publicaciones/card-ep.component';
import { cont_obl } from './cont-obl/cont-obl.component';

import { TopTenComponent } from './topten/topten.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { RouterModule } from '@angular/router';
import { InformationLinksComponent } from './information-links/information-links.component';
import { AlliedEntitiesComponent } from './allied-entities/allied-entities.component';
import { WhatIsOblComponent } from './what-is-obl/what-is-obl.component';
import { ZonaComponent } from './zona/zona.component';

@NgModule({
  declarations: [
    HomeComponent,
    IndicatorsComponent,
    TopTenComponent,
    card_ol,
    card_ep,
    cont_obl,
    carrousel,
    InformationLinksComponent,
    AlliedEntitiesComponent,
    WhatIsOblComponent,
    ZonaComponent,
  ],
  imports: [CommonModule, SharedModule, RouterModule, GoogleChartsModule],
  exports: [],
})
export class HomeModule {}
