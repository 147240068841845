<!-- Button trigger modal -->
<button type="button" class="btn btn-primary btn-flotante" data-bs-toggle="modal" data-bs-target="#exampleModal">
  Abrir Comparador
</button>
<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <app-comparador></app-comparador>
</div>

<div class="container">
  <app-detail-header></app-detail-header>

  <ul class="nav nav-tabs">
    <li class="nav-item">
      <button
        class="nav-link active"
        data-bs-toggle="tab"
        data-bs-target="#profile"
        type="button"
        role="tab"
        aria-controls="profile"
        aria-selected="true"
      >
        Descripción del perfil
      </button>
    </li>
    <!-- <li class="nav-item" *ngIf="showDatosYTendencias"> -->
    <li class="nav-item">
      <button
        class="nav-link"
        data-bs-toggle="tab"
        data-bs-target="#data"
        type="button"
        role="tab"
        aria-controls="data"
        aria-selected="true"
      >
        Datos y tendencias
      </button>
    </li>
    <li class="nav-item" *ngIf="showFormacionAcademica">
      <button
        class="nav-link"
        data-bs-toggle="tab"
        data-bs-target="#academic"
        type="button"
        role="tab"
        aria-controls="academic"
        aria-selected="true"
      >
        Formación académica
      </button>
    </li>
  </ul>

  <div class="tab-content" id="myTabContent">
    <div
      class="tab-pane fade show active"
      id="profile"
      role="tabpanel"
      aria-labelledby="profile-tab"
    >
      <app-detail-descripcion></app-detail-descripcion>
    </div>
    <div
      class="tab-pane fade"
      id="data"
      role="tabpanel"
      aria-labelledby="data-tab"
    >
      <app-detail-datos-tendencias
        [occupation]="occupation"
        [secondLevel]="secondLevel"        
      ></app-detail-datos-tendencias>
    </div>
    <div
      class="tab-pane fade"
      id="academic"
      role="tabpanel"
      aria-labelledby="academic-tab"
    >
      <app-detail-formacion-academica></app-detail-formacion-academica>
    </div>
  </div>
</div>
