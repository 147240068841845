import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'carrousel',
    templateUrl: './carrousel.component.html',
    styleUrls: ['./carrousel.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class carrousel implements OnInit{

    information: any;
    imageBase: string;

    constructor(private apiService: ApiService) {
        this.information = {};
        this.imageBase = environment.strapiUrl;
    }
  
    ngOnInit(): void {
      this.getCarrouselData();
    }

async  getCarrouselData(){
        let items = await this.apiService.getMany('/carousels');

        console.log("items de carrusel",items);

        //items[0].title = "Estudiantes y egresados";
        //items[0].description = "¿ Eres estudiante o egresado o necesitas información para mejorar tus oportunidades laborales ?";        
        //items[0].Description = "¿Sabías que un joven gana en promedio S/ 1,700 al mes en el sector privado formal y que esta aumenta con el nivel educativo?<br>¿Sabías que los especialistas en programación es la ocupación más demandada por las empresas privadas para trabajos permanentes en 2022 y  la carrera de analistas de sistemas y modelación de bases de datos la más requerida?";        
         
        //items[1].title = "Funcionarios públicos";
        //items[1].Description = "3 de cada 4 empleos en el Perú son informales, siendo unos de los principales problemas que acarrea al mercado laboral peruano.<br>El teletrabajo es una de las modalidades laborales impulsadas en época de pandemia y que viene abarcando a más de 200 mil empleos en el sector privado formal.";        
        
        //items[2].title = "Usuarios especializados";
        //items[2].Description = "El empleo formal asalariado en el sector privado ya ha superado los niveles de pre-pandemia y su tendencia sigue al alza, abarcando a más de 4 millones de trabajadores formales.<br>Accede a los microdatos más importantes del mercado laboral peruano: Encuesta Nacional de Hogares, la Encuesta de Demanda Ocupacional y la Planilla Electrónica.";        

        this.information = {
        results: items,
        };
    }
}

