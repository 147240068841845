import { Component, OnDestroy, OnInit, Input,SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { UtilService } from 'src/app/services/util.service';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-detail-salary-trend',
  templateUrl: './salary-trend.component.html',
  styleUrls: ['./salary-trend.component.scss'],
})
export class SalaryTrendComponent implements OnInit, OnDestroy {
  @Input() remuneracion_mensual: any;
  @Input() loading: boolean;
  @Input() primerLevelCode: any;
  information: any;
  imageDownload: any;
  detailServiceSubscription: Subscription;
  description: any;
  source: any;  
  technical_note: any;

  constructor(private utilService: UtilService) {
    this.loading = false;
    //this.information = {};
    this.detailServiceSubscription = Subscription.EMPTY;
     
  }

  ngOnInit(): void {
    this.load();
  }

  ngOnChanges(changes: SimpleChanges) {

  }


  async load() {

    if (this.primerLevelCode === undefined) {
      await new Promise((f) => setTimeout(f, 400));
      this.load();
      return;
    }
    
    if (this.primerLevelCode ==='0') {
      this.description = "Representa la remuneración promedio mensual de los trabajadores en empresas del sector público que se desempeñan en la ocupación seleccionada.";
      this.source = 'MTPE-Planilla Electrónica (PLAME y T-Registro) del sector público. La información corresponde a los asalariados del sector público.';
      this.technical_note = '<p align="justify">La remuneración promedio en el sector público se refiere al monto pagado por conceptos pensionarios, no pensionarios y por Comité de Administración de Fondos de Asistencia y Estímulo (CAFAE).</p> ';      
    } else 
    {
      this.description = "Representa la remuneración promedio mensual de los trabajadores en empresas del sector formal privado que se desempeñan en la ocupación seleccionada.,";
      this.source = 'MTPE-Planilla Electrónica (PLAME y T-Registro) del sector privado. La información corresponde a los asalariados del sector formal privado.';      
      this.technical_note = '<p align="justify">La remuneración promedio se refiere a las remuneraciones declaradas por el empleador en la Planilla Electrónica declaradas. Se considera a las remuneraciones de trabajadores percibidas por renta de quinta categoría y correspondientes al monto pagado por conceptos permanentes.</p> ';            
    } 

    this.information = {
      technical_note: this.technical_note,
      id: 'tendencia-remuneracion',
      response: {
        title: 'Tendencia de remuneraciones mensual',
        description: this.description,
        source: this.source,
      },
      type: 'LineChart',
      columns: [
        { type: 'string', role: 'domain', label: 'Mes' },
        { type: 'number', role: 'data', label: 'Remuneración' },
        { type: 'string', role: 'annotation', label: 'Remuneración' },
      ],
      options: {
        legend: {
          position: 'none',
        },
        pointsVisible: true,
        vAxis: {
          textStyle: {
            fontSize: 12,
            color: '#727B7D'
          },
          format: ''
        },
        hAxis: {
          textStyle: {
            fontSize: 12,
            color: '#727B7D',
          },
        },
        series: {
          0: {
            annotations: {
              textStyle: {
                strokeWidth: 0,
                fontSize: 12,
                color: '#3C63B0',
              },
            },
          },
        },
        annotations: {
          stemColor: 'none',
          alwaysOutside: true,
        },
        chartArea: {
          left: 60,
          right: 20,
          top: 20,
          bottom: 40,
        },
        pointSize: 8,
      },
    };

    console.log("this.primerLevelCode input",this.primerLevelCode);
    console.log("this.information input",this.information);

  }

  ngOnDestroy(): void {
    this.detailServiceSubscription.unsubscribe();
  }
}
