import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-occupational-job-training',
  templateUrl: './job-training.component.html',
  styleUrls: ['./job-training.component.scss']
})
export class JobTrainingComponent implements OnInit, OnDestroy {

  information: any;
  totalResults: number;
  publics: any[];
  privates: any[];
  imageBase: string;

  constructor(private apiService: ApiService) {
    this.information = {};
    this.totalResults = 0;
    this.publics = [];
    this.privates = [];
    this.imageBase = environment.strapiUrl;
  }

  ngOnInit(): void {
    this.getInformation();
  }

  async getInformation() {
    let header = await this.apiService.getOne(
        '/job-developments?code=capacitacion-laboral'
    );
  
    let items = await this.apiService.getMany('/job-trainings');

    this.information = {
        title: header.title,
        description: header.description,
        results: items,
    };

    this.publics = this.information.results.filter((x: any) => x.category === "Páginas oficiales");
    this.totalResults = this.information.results.length;
  }

  navigate(url: string) {
    if (url === '') { return; }
    let external = document.createElement('a');
    external.href = url;
    external.target = '_blank';
    external.click();
    external.remove();
  }

  ngOnDestroy(): void {
  }
}
