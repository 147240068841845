import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-information-links',
  templateUrl: './information-links.component.html',
  styleUrls: ['./information-links.component.scss']
})
export class InformationLinksComponent implements OnInit {
  
  information: any;
  imageBase: string;

  constructor(private apiService: ApiService) {
    this.information = {};
    this.imageBase = environment.strapiUrl;
  }

  ngOnInit(): void {
    this.getCardsLinks();
  }

 async getCardsLinks() {
    let items = await this.apiService.getMany('/external-link-cards');

    this.information = {
      results: items,
    };
  }
}