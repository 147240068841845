<app-publications-header [headers]="headers" [strapiUrl]="strapiUrl"></app-publications-header>

<div class="row">
  <p class="p-select text-secondary fs-16">
    Selecciona los años, meses y formatos de los documentos que desea descargar:
  </p>
</div>

<div *ngIf="headers && periods">
  <div class="row col-12">
    <p class="col-sm-2 col-form-label">Año</p>
    <div class="pt-2 pb-2 col-sm-10">
      <button *ngFor="let period of periods" (click)="periodSelection(period)" type="button" class="btn me-2 mb-2"
        [ngClass]="period === periodSelected ? 'btn-warning' : 'btn-outline-primary'">
        {{ period }}
      </button>
    </div>
  </div>

  <div class="row col-12" *ngIf="headers.periodicity != 'Anual'">
    <p class="col-sm-2 col-form-label">Mes</p>
    <div class="pt-2 pb-2 col-sm-10">
      <span class="me-2" *ngFor="let month of months">
        <input (click)="monthSelection(month)" type="checkbox" class="btn-check" [id]="'btn-check-' + month"
          autocomplete="off" />
        <label class="btn btn-outline-warning mt-2" [for]="'btn-check-' + month">{{ monthsName[month] }}</label></span>
    </div>
  </div>

  <div class="row col-12">
    <p class="col-sm-2 col-form-label">Formato</p>
    <div class="pt-2 pb-2 col-sm-10">
      <span class="me-2" *ngFor="let format of formats">
        <input (click)="formatSelection(format)" type="checkbox" class="btn-check" [id]="'btn-check-' + format"
          autocomplete="off" />
        <label class="btn btn-outline-warning" [for]="'btn-check-' + format">
          {{ format }}
        </label>
      </span>
    </div>
  </div>

  <div class="mt-5">
    <button [ngClass]="enableDownload ? 'btn-primary' : 'disabled'" (click)="download()" class="btn me-2">
      <mat-icon [ngClass]="enableDownload ? 'text-inverted' : 'text-secondary'" svgIcon="file-download-outline">
      </mat-icon>
      Descargar archivos seleccionados
    </button>
  </div>

  <div class="text-start mt-3">
    <mat-icon class="icon-color" svgIcon="information"></mat-icon>
    <span class="source">Fuente: {{ headers !== null ? headers.source_information : "" }}</span>
  </div>
</div>
