<div class="mt-4">
  <div class="row">
    <div class="col-12 col-md-12 col-lg-8">
      <div>
        <img
          width="100%"
          height="280px"
          [src]="imageBase + information.results[0].image.url"
          alt=""
        />
      </div>
      <div class="mt-4">
        <div *ngFor="let text of information.results">
          <div [innerHTML]="text.description"></div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-12 col-lg-4">
      <h4>Contacto</h4>
      <div class="col-12 mt-4">
        <div class="card">
          <div class="row">
            <div class="col-sm-12">
              <div class="card-body">
                <h5 class="fw-bold">Equipo Técnico del OSEL</h5>
                <span
                  class="text-attention fw-bold"
                  *ngIf="information.results[0].active == 0"
                  ><mat-icon
                    class="text-attention"
                    svgIcon="alert-circle"
                  ></mat-icon>
                  No se encuentra activo</span
                >
              </div>
              <div class="mt-4">
                <span
                  class="text-secondary"
                  *ngIf="information.results[0].id != 1"
                  >Coordinador:</span
                >
                <span
                  class="text-secondary"
                  *ngIf="information.results[0].id == 1"
                  >Directora de la Dirección de Investigación Socioeconómica Laboral:</span
                >
                <div>
                  <span>{{ information.results[0].coordinator }}</span>
                </div>
              </div>
              <div class="mt-4">
                <span
                  class="text-secondary"
                  *ngIf="information.results[0].id == 1"
                  >Responsable de la Red OSEL:</span
                >
                <div>
                  <span>{{ information.results[0].manager }}</span>
                </div>
              </div>
              <div class="mt-4">
                <span
                  class="text-secondary"
                  *ngIf="information.results[0].id != 1"
                  >Analista:</span
                >
                <span
                  class="text-secondary"
                  *ngIf="information.results[0].id == 1"
                  >Analista Junior:</span
                >
                <div>
                  <span>{{ information.results[0].analyst }}</span>
                </div>
              </div>
              <div class="mt-4">
                <span class="text-secondary">Dirección:</span>
                <div>
                  <span>{{ information.results[0].address }}</span>
                </div>
              </div>
              <div class="mt-4">
                <span class="text-secondary">Teléfono:</span>
                <div>
                  <span>{{ information.results[0].phone }}</span>
                </div>
              </div>
              <div class="mt-4">
                <span class="text-secondary">Correo:</span>
                <div>
                  <span>{{ information.results[0].email }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card logo">
        <img
          [src]="imageBase + information.results[0].logo.url"
          style="width: 350px"
          alt=""
        />
      </div>
    </div>
  </div>
  <div class="">
    <h3 *ngIf="information.results[0].gallery.length != 0">Galería de fotos</h3>
    <div class="row" *ngIf="information.results[0].gallery.length != 0">
      <div
        class="col-lg-4 col-md-4 col-12 col-sm-12 mb-3"
        *ngFor="let gallery of information.results[0].gallery"
      >
        <div class="">
          <img
            class="lazyload"
            [src]="imageBase + gallery.url"
            style="width: 416px; height: 300px"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</div>
