<div class="row">
  <div class="col-12">
    <div class="mt-2 mb-4">
      <div class="ng-autocomplete">
        <ng-autocomplete #auto
          [data]="items"
          [searchKeyword]="keyword" 
          (inputCleared)="onCleared($event)"
          (inputFocused)="onFocused($event)"
          placeholder="Escribe información que estás buscando. Ejm: Informe estadístico mensual, demanda ocupacional"
          (selected)="selectEvent($event)"
          [itemTemplate]="itemTemplate"
          notFoundText="No se encontraron resultados."
          [notFoundTemplate]="notFoundTemplate">
        </ng-autocomplete>
        <button class="btn btn-primary button-custom-search" type="button">
          <mat-icon class="text-inverted" svgIcon="magnify"></mat-icon>
        </button>
      </div>
      <ng-template #itemTemplate let-item>
        <a [innerHTML]="item.name"></a>
      </ng-template>
      <ng-template #notFoundTemplate let-notFound>
        <div [innerHTML]="notFound"></div>
      </ng-template>
    </div>
  </div>
</div>
