import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment'; 

@Component({
  selector: 'app-osel',
  templateUrl: './osel.component.html',
  styleUrls: ['./osel.component.scss']
})
export class OselComponent implements OnInit {

  loading: boolean;
  head: any;
  information: any;
  paginatedList: any[];
  pagesList: number[];
  currentPage: number;
  cardsPerPage: number;
  imageBase: string;

  constructor(private apiService: ApiService) {
    this.loading = true;
    this.information = {};
    this.head = {};
    this.paginatedList = [];
    this.pagesList = [];
    this.currentPage = 1;
    this.cardsPerPage = 16;
    this.imageBase = environment.strapiUrl;
  }

  ngOnInit(): void {
    this.getRegionInformation();
    this.getInformation();
  }

  getInformation() {
    this.head = {
      "title": "¿Qué es un OSEL?",
      "description": "El Observatorio Socio Económico y Laboral (OSEL) se define como un instrumento de producción, análisis y difusión de la situación socioeconómico laboral en un ámbito geográfico determinado, brindando a las entidades públicas y privadas, información oficial sobre el mercado de trabajo.",
    }
  }

  async getRegionInformation() {
    let items = await this.apiService.getMany('/osel-regions');

    this.information = {
        results: items,
    };
    this.paginationLogic();
    this.loading = false;
  }

  OnPaginate(page: number) {
    this.currentPage = page;
    this.paginationLogic();
  }

  paginationLogic() {
    let result = this.paginate(this.information.results.length, this.currentPage, this.cardsPerPage);
    const range = (start: number, end: number) => Array.from({ length: (end - start) }, (v, k) => k + start);
    let indexArray = range(result.startIndex, result.endIndex + 1);
    this.paginatedList = this.information.results.filter((el: any, i: any) => indexArray.some((j: any) => i === j));
    this.pagesList = result.pages;
  }

  paginate(totalItems: number, currentPage: number = 1, pageSize: number = 10, maxPages: number = 10) {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= maxPages) {
      // total pages less than max so show all pages
      startPage = 1;
      endPage = totalPages;
    } else {
      // total pages more than max so calculate start and end pages
      let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
      let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
      if (currentPage <= maxPagesBeforeCurrentPage) {
        // current page near the start
        startPage = 1;
        endPage = maxPages;
      } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
        // current page near the end
        startPage = totalPages - maxPages + 1;
        endPage = totalPages;
      } else {
        // current page somewhere in the middle
        startPage = currentPage - maxPagesBeforeCurrentPage;
        endPage = currentPage + maxPagesAfterCurrentPage;
      }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }
}
