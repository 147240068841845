<div class="mt-4">
  <div class="button-group">
    <button
      *ngFor="let button of information.buttons"
      type="button"
      [ngClass]="button.periodicity === currentPublication ? 'active' : ''"
      (click)="getActualPublication(button.periodicity)"
    >
      {{ button.title }}
    </button>
  </div>
  <div class="col-12 mt-4">
    <div class="row">
      <div
        class="col-xs-12 col-lg-6 mb-2"
        *ngFor="let publication of typePublications"
      >
        <div class="card two-columns">
          <div class="row">
            <div class="col-3 col-sm-3 col-md-3 col-lg-3 image">
              <span *ngIf="publication.new == 1" class="badge">NUEVO</span>
              <img
                class="lazyload"
                alt=""
                [attr.data-src]="imageBase + publication.image[0].url"
              />
            </div>
            <div class="col-9 col-sm-9 col-md-9 col-lg-9">
              <div class="card-body">
                <h4>
                  <a
                    *ngIf="
                      publication.periodicity === 'Anual' ||
                      publication.periodicity === 'Semestral'
                    "
                    class="text-primary"
                    [href]="'/recursos/publicaciones/anuales/' + publication.id"
                    >{{ publication.name }}</a
                  >
                  <a
                    *ngIf="publication.periodicity === 'Interanual'"
                    class="text-primary"
                    [href]="
                      '/recursos/publicaciones/informes/' + publication.id
                    "
                    >{{ publication.name }}</a
                  >
                  <a
                    *ngIf="
                      publication.periodicity !== 'Anual' &&
                      publication.periodicity !== 'Interanual' &&
                      publication.periodicity !== 'Semestral'
                    "
                    class="text-primary"
                    [href]="
                      '/recursos/publicaciones/archivos/' + publication.id
                    "
                    >{{ publication.name }}</a
                  >
                </h4>
                <p
                  class="description"
                  [innerHTML]="publication.description"
                ></p>
              </div>
              <div class="card-footer">
                <span class="fs-14 fw-bold description tag">
                  {{ publication.periodicity }} | Última actualización: {{ formatLastUpdatedDate(publication.last_updated_date) }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
