import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-publications',
  templateUrl: './publications.component.html',
  styleUrls: ['./publications.component.scss'],
})
export class PublicationsComponent implements OnInit {
  monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
  "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
  ];
  information: any;
  currentPublication: string;
  typePublications: any[];
  basePublications: any[];
  imageBase: string;

  constructor(private apiService: ApiService) {
    this.information = {};
    this.currentPublication = 'Todos';
    this.typePublications = [];
    this.basePublications = [];
    this.imageBase = environment.strapiUrl;
  }

  ngOnInit(): void {
    this.getPublicationsData();
    this.getActualPublication(this.currentPublication);
  }

  async getPublicationsData() {
    this.information = {
      buttons: [
        {
          title: 'Todos',
          periodicity: 'Todos',
        },
        {
          title: 'Mensual',
          periodicity: 'Mensual',
        },
        {
          title: 'Trimestral',
          periodicity: 'Trimestral',
        },
        {
          title: 'Semestral',
          periodicity: 'Semestral',
        },
        {
          title: 'Anual',
          periodicity: 'Anual',
        },
        {
          title: 'Interanual',
          periodicity: 'Interanual',
        },
      ],
    };

    let publicationTypes = await this.apiService.getMany('/publications-types?_sort=last_updated_date:DESC');
    this.typePublications = publicationTypes;
    this.basePublications = publicationTypes;
  }

  getActualPublication(currentPublication: string) {
    this.currentPublication = currentPublication;

    if (currentPublication !== 'Todos') {
      let publicationsFiltered = this.basePublications.filter(
        (publication: any) => publication.periodicity === currentPublication
      );
      this.typePublications = publicationsFiltered;
    } else {
      this.typePublications = this.basePublications;
    }
  }

  formatLastUpdatedDate(lastUpdatedDate: string) {
    let formatedLastUpdatedDate = this.monthNames[(new Date(lastUpdatedDate).getMonth())] + ", " + (new Date(lastUpdatedDate).getFullYear())
    return formatedLastUpdatedDate;
  }
}
