<div class="row">
  <div class="text-center fs-24 text-attention">
    <h3>Entidades aliadas</h3>
  </div>
</div>

<div class="row d-flex justify-content-around align-items-end mb-5">
  <div class="col-sm-3 text-center" *ngFor="let image of information.results">
    <a href="{{ image.url }}" target="_blank" rel="noopener">
      <img
        style="max-width: 175px"
        class="lazyload"
        [attr.data-src]="imageBase + image.image[0].url"
        alt=""
    /></a>
  </div>
</div>
