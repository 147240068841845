import { Component, Input, OnInit } from '@angular/core';
import 'dayjs/locale/es';
let dayjs = require('dayjs');

@Component({
  selector: 'app-osel-information-sources',
  templateUrl: './osel-information-sources.component.html',
  styleUrls: ['./osel-information-sources.component.scss'],
})
export class OselInformationSourceComponent implements OnInit {

  loading: boolean;
  @Input() sources: any;
  @Input() categories: any[] = [];
  @Input() years: any[] = [];
  category: any[];
  currentCategory: number;
  currentYear: number;
  totalResults: number;
  categoryName: any;
  categoryID: any;
  filteredList: any[];
  defaultFilteredList: any[];
  lastPublication: Date;
  utc = require('dayjs/plugin/utc');
  timezone = require('dayjs/plugin/timezone');

  constructor() {
    this.loading = true;
    this.currentCategory = 0;
    this.currentYear = 0;

    this.totalResults = 0;
    this.categoryName = [];
    this.categoryID = [];
    this.filteredList = [];
    this.defaultFilteredList = [];
    this.category = [];
    this.lastPublication = new Date();
  }

  ngOnInit(): void {
    this.getDefaultInformation();
  }

  getDefaultInformation() {
    if (this.sources && this.sources.results.length > 0) {
      this.filteredList = this.sources.results;
      this.totalResults = this.filteredList.length;

      this.years = this.filteredList.map((item) => item.year);
      this.years = this.years.filter(function (elem, index, self) {
        return index === self.indexOf(elem);
      });
      this.years = this.years.sort((n1, n2) => (n1 > n2 ? -1 : 1));
      this.currentYear = this.years[0];

      this.filterByYear(this.currentYear);

      this.lastPublication = new Date(
        Math.max(
          ...this.sources.results.map(
            (e: any) => new Date(e.osel_information_source_type.updated_at)
          )
        )
      );
      dayjs.extend(this.utc);
      dayjs.extend(this.timezone);
      this.lastPublication = dayjs(this.lastPublication)
        .tz('America/Lima')
        .format('YYYY-MM-DD HH:mm:ss');
      this.orderByNew();
      this.loading = false;
    }
  }

  filterByCategory(category: number) {
    this.currentCategory = category;

    this.categoryID = this.sources.results.find(
      (x: any) => x.osel_information_source_type.id == this.currentCategory
    );

    this.categoryName =
      category === 0
        ? 'Todas las publicaciones'
        : this.categoryID.osel_information_source_type.title;

    if (this.currentCategory === 0) {
      this.getDefaultInformation();
    } else {
      this.filteredList = this.sources.results.filter(
        (item: any) =>
          this.currentCategory === item.osel_information_source_type.id
      );
      this.years = this.filteredList.map((item) => item.year);
      this.years = this.years.filter(
        (elem, index, self) => index === self.indexOf(elem)
      );
      this.years = this.years.sort((n1, n2) => (n1 > n2 ? -1 : 1));
      this.currentYear = this.years[0];

      this.filteredList = this.sources.results.filter(
        (item: any) =>
          this.currentCategory === item.osel_information_source_type.id &&
          this.currentYear === item.year
      );
      this.totalResults = this.filteredList.length;
      this.lastPublication = new Date(
        Math.max(
          ...this.sources.results.map(
            (e: any) => new Date(e.osel_information_source_type.updated_at)
          )
        )
      );
      dayjs.extend(this.utc);
      dayjs.extend(this.timezone);
      this.lastPublication = dayjs(this.lastPublication)
        .tz('America/Lima')
        .format('YYYY-MM-DD HH:mm:ss');
    }
    this.orderByNew();
  }

  filterByYear($event: any) {
    if (typeof $event === 'number') {
      this.filteredList = this.sources.results.filter(
        (item: any) => $event == item.year
      );
    } else {
      if (this.currentCategory === 0) {
        this.filteredList = this.sources.results.filter(
          (item: any) => $event.target.value == item.year
        );
      } else {
        this.filteredList = this.sources.results.filter(
          (item: any) =>
            $event.target.value == item.year &&
            this.currentCategory === item.osel_information_source_type.id
        );
      }
    }
    this.totalResults = this.filteredList.length;
    this.orderByNew();
  }

  orderByNew() {
    let publications = this.filteredList;
    let orderByYear = publications.sort((n1, n2) =>
      parseInt(n2.year) > parseInt(n1.year) ? 1 : -1
    );
    let publicationsNew = [];
    let publicationsOld = [];
    for (const publication of orderByYear) {
      if (publication.recentlyAdded === true) {
        publicationsNew.push(publication);
      } else {
        publicationsOld.push(publication);
      }
    }
    this.filteredList = publicationsNew.concat(publicationsOld);
  }
}
