<div class="card two-columns">
  <div class="row">
    <div class="col-sm-12 col-xs-12 col-12 col-lg-5 col-md-12 image">
      <img
        class="lazyload d-flex align-self-center ms-2"
        alt=""
        style="width: 100% !important;"
        [attr.data-src]="imageBase + information.image[0].url"
      />
    </div>
    <div class="col-sm-12 col-12 col-xs-12 col-md-12 col-lg-6">
      <div class="card-body pt-3 px-1">
        <h4>{{ information.title }}</h4>
        <p class="description" [innerHTML]="information.description"></p>
      </div>
      <div class="card-footer">
        <a class="text-primary" [href]="information.url" target="_blank"
          >Ir al sitio <mat-icon svgIcon="open-in-new"></mat-icon
        ></a>
      </div>
    </div>
  </div>
</div>
