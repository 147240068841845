import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { PaginationService } from '../../services/pagination.service';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import searchOptions from './search-options.json';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';

@Component({
  selector: 'lista-ocupaciones',
  templateUrl: './listado-ocupaciones.component.html',
  styleUrls: ['./listado-ocupaciones.component.scss'],
})
export class listado_ocp implements OnInit {
  @ViewChild('auto') auto: any;
  categories: any;
  ocupaciones: any;
  levelTwo: any;
  levelFour: any;
  levelFourCount: any;
  salaryOccupations: any;

  currentCategory: number;
  currentCategoryObject: any;
  pagesList: number[];
  currentPage: number;
  cardsPerPage: number;

  banners: any;
  strapiUrl: string;

  occupationSalary: any;

  items: any[];
  keyword = 'name_sa'; //nombre sin acentos
  name1 :string;

  constructor(
    private apiService: ApiService,
    private paginationService: PaginationService,
    private router: Router
    
  ) {
    this.categories = [];
    this.levelTwo = [];
    this.levelFour = [];
    this.salaryOccupations = [];
    this.ocupaciones = [];
    this.name1 = '';    


    this.levelFourCount = 0;

    this.currentCategory = 0;
    this.currentCategoryObject = '';
    this.pagesList = [];
    this.currentPage = 1;
    this.cardsPerPage = 12;

    this.banners = [];
    this.strapiUrl = environment.strapiUrl;

    this.items = searchOptions.items;
  }

  ngOnInit(): void {
    this.getCategorias();
    this.getBanners();
    this.getOcupaciones();
  }
  
  //Posiciona pagina al inicio
  public posicionaClick(fragment: string): void {
    this.router.navigateByUrl('descubre#' + fragment);
  }

  async getBanners() {
    this.banners = await this.apiService.getMany(
      `/banner-urls?code=descubre-ocupaciones`
    );
  }

  async getCategorias() {
    this.categories = await this.apiService.getMany(`/occupation-ones`);
    this.selectCategory(this.categories[0].id);
  }

  async getOcupaciones() {
    //Recorre por tramos, ya que strapi tiene un limite de 100 registros de retorno.
    let inicio = 0;
    let limite = 50;
    let total =  await this.apiService.getMany(`/occupation-fours/count`);

    for (let index = 0; index < 10; index++) {

      let ocupacionesTramo = await this.apiService.getMany(`/occupation-fours?_start=${inicio}&_limit=${limite}`);
      
      //console.log("ocupacionesTramo.length",ocupacionesTramo.length);

      this.ocupaciones = this.ocupaciones.concat(ocupacionesTramo);

      if (inicio + limite >= total) {
        index = 10;
      }

      inicio = inicio + limite;

    }

    this.ocupaciones = this.ocupaciones.map((row: any) => {
        const nombre_sa = this.removeAccents(row.name) + ' ' + row.name;

      return {
        id: row.id,
        code: row.code,
        name: row.name,
        name_sa: nombre_sa,
      };
    });
    
    //console.log("this.ocupaciones 2",this.ocupaciones);


  }

   removeAccents = (str:string) => {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    } 

    public actualizaTexto(cadena:string) {
      //console.log("actualizaTexto");
      this.name1 = cadena;   

   
     }

  //Acordion #1,2,3
  async getSecondLevel() {
    let occupations = await this.apiService.getMany(
      `/occupation-twos?occupation_one.id=${this.currentCategory}`
    );
    this.levelTwo = occupations;

  }

  async selectCategory(currentCategory: number) {

    //Posiciona
    this.posicionaClick('categoria');

    this.currentPage = 1;
    this.levelTwo = [];
    this.levelFour = [];
    this.levelFourCount = 0;
    this.currentCategory = currentCategory;
    this.currentCategoryObject = this.categories.filter(
      (category: any) => category.id === currentCategory
    )[0];
    this.getSecondLevel();

    this.levelFour = await this.apiService.getMany(
      //`/occupation-fours?occupation_three.occupation_two.occupation_one.id=${this.currentCategory}&_sort=name:ASC`
      `/occupation-fours?occupation_three.occupation_two.occupation_one.id=${this.currentCategory}&_sort=code:ASC`
    );
    // console.log("this.levelFour");
    // console.log(this.levelFour);
     
    this.levelFourCount = this.levelFour.length;
  
    const codesFour = this.levelFour
      .map((occupation: any) => occupation.code)
      .join(',');

 
    this.salaryOccupations = (
      await this.apiService.getManyBackend(`/occupations/salary`, {
        code: codesFour,
      })
    ).data;
 

  }

  public abriComparador() {
    //
  }

  getSecondCategoryName(id: number) {
    let occupation = this.levelTwo.filter(
      (occupation: any) => occupation.id === id
    );

    return occupation[0].name;
  }
  
  //Filtra ocupaciones del acordion seleccionado
  getOcupacionesTwo(id: number) {
    let occupationTwo = this.levelFour.filter(
      (occupation: any) => occupation.occupation_three.occupation_two === id
    );

    return occupationTwo;
  }  

  getOcupacionesCount(id: number) {

    let contador = this.levelFour.filter(
      (occupation: any) => occupation.occupation_three.occupation_two === id
    ).length;

    return contador;

  }  
  

  redirect(url: string) {
    let download = document.createElement('a');
    download.href = url;
    download.target = '_blank';
    download.click();
  }

  selectEvent(item: any) {
    setTimeout(() => {
      this.actualizaTexto(item.name)
    }, 1);
    var url = `/descubre/${[item.id]}/detail`; 

    this.router.navigate([url]);
    this.auto.close();
  }

  onFocused(e: any) {
    this.auto.close();
  }

  onCleared(e: any) {
    this.auto.close();
  }
}

