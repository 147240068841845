import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdvancedStudiesComponent } from './advanced-studies/advanced-studies.component';
import { EmpreSupportComponent } from './empre-support/empre-support.component';
import { JobTrainingComponent } from './job-training/job-training.component';
import { LaborRightsComponent } from './labor-rights/labor-rights.component';
import { LookingJobComponent } from './looking-job/looking-job.component';
import { ScholarshipsComponent } from './scholarships/scholarships.component';
import { LaborTrainingModalitiesComponent } from './labor-training-modalities/labor-training-modalities.component';
import { PreProfessionalComponent } from './pre-professional-professional/pre-professional-professional.component';
import { OtherModalitiesComponent } from './other-labor-training/other-labor-training.component';
import { JobNumbersComponent } from './job-numbers/job-numbers.component';
import { JobNumberComponent } from './job-number/job-number.component';

const routes: Routes = [
  { path: 'estudios-avanzados', component: AdvancedStudiesComponent },
  { path: 'capacitacion-laboral', component: JobTrainingComponent },
  { path: 'financiamiento-becas', component: ScholarshipsComponent },
  { path: 'soporte-emprendimiento', component: EmpreSupportComponent },
  { path: 'buscar-empleo', component: LookingJobComponent },
  { path: 'derechos-laborales', component: LaborRightsComponent },
  { path: 'modalidades-formativas-laborales', component: LaborTrainingModalitiesComponent },
  { path: 'pre-profesional-profesional', component: PreProfessionalComponent },
  { path: 'otras-modalidades-formativas-laborales', component: OtherModalitiesComponent },
  { path: 'empleo-en-numeros', component: JobNumbersComponent },
  { path: 'vacantes-de-empleo', component: JobNumberComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OccupationsRoutingModule {}
