<app-loading *ngIf="loading"></app-loading>
<div class="mt-4 row" *ngIf="!loading">
  <div class="mb-4">
    <a class="btn-link fs-12" routerLink="/panorama-laboral">
      <mat-icon svgIcon="arrow-left"></mat-icon>
      Volver a lista de indicadores
    </a>
    <div class="mt-4">
      <h4 class="text-attention">{{ title }}</h4>
    </div>
  </div>
  <div class="col-md-12 col-lg-3">
    <app-outlook-select-indicator></app-outlook-select-indicator>
    <!-- <app-outlook-filters *ngIf="isBaseGeneral(code)"></app-outlook-filters> -->
    <app-outlook-nostat-filters *ngIf="!isBaseGeneral(code)"></app-outlook-nostat-filters>
  </div>
  <div class="col-md-12 col-lg-9">
    <!-- <app-outlook-chart-section *ngIf="isBaseGeneral(code)"></app-outlook-chart-section> -->
    <app-outlook-nostat-chart-section *ngIf="!isBaseGeneral(code)"></app-outlook-nostat-chart-section>
  </div>
</div>
