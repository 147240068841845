import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-detail-employee-variations',
  templateUrl: './employee-variations.component.html',
  styleUrls: ['./employee-variations.component.scss'],
})
export class EmployeeVariationsComponent implements OnInit, OnDestroy {
  @Input() entradas_salidas: any;
  @Input() loading: boolean;
  @Input() primerLevelCode: any;

  information: any;
  imageDownload: any;
  detailServiceSubscription: Subscription;
  description: any;
  source: any;  
  technical_note: any;

  constructor() {
    this.loading = false;
    //this.information = {};
    this.detailServiceSubscription = Subscription.EMPTY;
  }

  ngOnInit(): void {
    this.load();
  }

  async load() {

    if (this.primerLevelCode === undefined) {
      await new Promise((f) => setTimeout(f, 400));
      this.load();
      return;
    }

    if (this.primerLevelCode ==='0') {
      this.description = "Representa la cantidad de trabajadores contratados y desvinculados mensualmente en el sector público que se desempeñan en la ocupación seleccionada.";
      this.source = 'MTPE-Planilla Electrónica (PLAME y T-Registro) del sector público. La información corresponde a los asalariados del sector público.';
      this.technical_note = '<p align="justify">La información de entradas y salidas corresponde a la comparación del mes actual respecto del mes anterior. En el caso de la entrada se refiere al número de puestos de trabajo que comenzaron (o volvieron) a registrarse en el sector público; mientras que, las salidas se refieren a aquellos que dejaron de registrarse. Cabe resaltar que la entrada y salida en el sector público no implica el ingreso o la salida de la formalidad, debido estos indicadores están sujetos a la declaración del empleador en Planilla Electrónica con fechas de corte.</p>';      
    } else 
    {
      this.description = "Representa la cantidad de trabajadores contratados y desvinculados mensualmente en el sector formal privado que se desempeñan en la ocupación seleccionada.";
      this.source = 'MTPE-Planilla Electrónica (PLAME y T-Registro) del sector privado. La información corresponde a los asalariados del sector formal privado.';      
      this.technical_note = '<p align="justify">La información de entradas y salidas corresponde a la comparación del mes actual respecto del mes anterior. En el caso de la entrada se refiere al número de puestos de trabajo que comenzaron (o volvieron) a registrarse en el sector formal privado; mientras que, las salidas se refieren a aquellos que dejaron de registrarse. Cabe resaltar que la entrada y salida en el sector formal privado no implica el ingreso o la salida de la formalidad, debido estos indicadores están sujetos a la declaración del empleador en Planilla Electrónica con fechas de corte.</p>';            
    } 

    this.information = {
      technical_note: this.technical_note,
      id: 'entradas-salidas',
      response: {
        title: 'Nuevos contratos y desvinculados',
        description: this.description,
        source: this.source,
      },
      // "csv": csv,
      type: 'ColumnChart',
      columns: [
        { type: 'string', role: 'domain', label: 'Month' },
        { type: 'number', role: 'data', label: 'Desvinculados' },
        { type: 'string', role: 'annotation' },
        { type: 'number', role: 'data', label: 'Nuevos contratos' },
        { type: 'string', role: 'annotation' },
      ],
      options: {
        legend: {
          alignment: 'center',
          position: 'right',
          textStyle: { color: '#727B7D', fontSize: 12 },
        },
        isStacked: true,
        vAxis: {
          gridlineColor: 'none',
          textPosition: 'none',
        },
        hAxis: {
          textStyle: {
            fontSize: 12,
            color: '#727B7D',
          },
        },
        annotations: {
          stemColor: 'none',
          alwaysOutside: true,
          highContrast: false,
        },
        tooltip: {
          trigger: 'none',
        },
        series: {
          0: {
            color: '#B13C3C',
            backgroundColor: '#B13C3C',
            annotations: {
              stem: {
                length: -20,
              },
              textStyle: {
                fontSize: 12,
                color: '#B13C3C',
                marginTop: '15px',
              },
            },
          },
          1: {
            color: '#3C63B0',
            backgroundColor: '#3C63B0',
            annotations: {
              stem: {
                length: 5,
              },
              textStyle: {
                fontSize: 12,
                color: '#3C63B0',
              },
            },
          },
        },
        chartArea: {
          left: 20,
          right: 160,
          top: 0,
          bottom: 40,
        },
      },
    };
  }

  ngOnDestroy(): void {
    this.detailServiceSubscription.unsubscribe();
  }
}
