import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-occupational',
  templateUrl: './occupational.component.html',
  styleUrls: ['./occupational.component.scss'],
})
export class OccupationalComponent implements OnInit {
  jumbotron: any;
  currentCategory: number;
  categories: any;
  imageBase: string;

  constructor(private router: Router, private apiService: ApiService) {
    this.jumbotron = {};
    this.currentCategory = 0;
    this.categories = {};
    this.imageBase = environment.strapiUrl;
  }

  ngOnInit(): void {
    this.setCurrentCategory();
    this.getJumbotron();
    this.getCategories();
  }

  setCurrentCategory() {
    if (this.router.url.includes('estudios-avanzados')) {
      this.currentCategory = 1;
    } else if (this.router.url.includes('capacitacion-laboral')) {
      this.currentCategory = 2;
    } else if (this.router.url.includes('financiamiento-becas')) {
      this.currentCategory = 3;
    } else if (this.router.url.includes('derechos-laborales')) {
      this.currentCategory = 5;
    } else if (this.router.url.includes('buscar-empleo')) {
      this.currentCategory = 6;
    } else if (this.router.url.includes('soporte-emprendimiento')) {
      this.currentCategory = 8;
    } else {
      this.currentCategory = 0;
    }
  }

  async getJumbotron() {
    this.jumbotron = await this.apiService.getOne(
      `/banners?code=recursos-desarrollo-laboral`
    );
    this.jumbotron.url = environment.strapiUrl + this.jumbotron.image[0].url;
  }

  getCategories() {
    this.categories = {
      studiesCategories: [
        {
          id: 1,
          title: 'Opciones de estudios avanzados',
          url: '/recursos-desarrollo-laboral/estudios-avanzados',
        },
        {
          id: 2,
          title: 'Capacitación laboral',
          url: '/recursos-desarrollo-laboral/capacitacion-laboral',
        },
        {
          id: 3,
          title: 'Financiamiento y becas',
          url: '/recursos-desarrollo-laboral/financiamiento-becas',
        },
      ],
      laborTraningModalitiesCategories: [
        {
          id: 9,
          title: '¿Qué son las Modalidades formativas laborales?',
          url: '/recursos-desarrollo-laboral/modalidades-formativas-laborales',
        },
        {
          id: 10,
          title: 'Prácticas pre profesionales y profesionales',
          url: '/recursos-desarrollo-laboral/pre-profesional-profesional',
        },
        {
          id: 11,
          title: 'Otros tipos de Modalidades formativas laborales',
          url: '/recursos-desarrollo-laboral/otras-modalidades-formativas-laborales',
        },
      ],
      jobsCategories: [
        {
          id: 5,
          title: 'Derechos laborales de un empleado',
          url: '/recursos-desarrollo-laboral/derechos-laborales',
        },
        {
          id: 6,
          title: 'Dónde buscar empleo',
          url: '/recursos-desarrollo-laboral/buscar-empleo',
        },
        {
          id: 7,
          title: 'Estado de las vacantes de empleo',
          url: '/recursos-desarrollo-laboral/vacantes-de-empleo',
        },
      ],
      entreCategories: [
        {
          id: 8,
          title: 'Soporte para el emprendimiento',
          url: '/recursos-desarrollo-laboral/soporte-emprendimiento',
        },
      ],
    };
  }

  loadComponent(currentCategory: number) {
    this.currentCategory = currentCategory;
  }
}
