<div class="container">

    <table class="table table-sm table-hover text-left mt-2">
        <thead class="">
            <tr>
                <th scope="col" class="">Grupos de ocupación</th>
                <th scope="col" [width]="250" class="td-educational text-center">Remuneración Promedio Mensual</th>
                <th scope="col" [width]="150"> </th>          
            </tr>
        </thead>
    
        <tbody>
            <tr *ngFor="let ocupacion of listaOcupaciones2; let i = index">
                <td class="td-educational">{{ocupacion.name}}</td>
                <td class="td-educational text-center"> 
                    {{
                        occupationSalary[ocupacion.code] !== undefined
                          ? occupationSalary[ocupacion.code]
                          : "- -"
                      }}
                </td> 
                <th scope="col">
                    <button type="button" 
                            routerLink="/descubre/{{ ocupacion.id }}/detail"
                            class="btn btn-outline-primary fs-16 fw-bold pb-2 pt-2">
                            Ver detalles
                    </button>
                </th>                                                
            </tr>
        </tbody>
    
         
    
    </table>

</div> 
