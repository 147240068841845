import { Component, OnInit, Input } from '@angular/core';
import { MapService } from 'src/app/services/map.service';
import { UtilService } from 'src/app/services/util.service';

import * as d3 from 'd3';
import * as topojson from 'topojson-client';
import { TableUtil } from "src/app/services/table.Utils";

let svg: any;
let heat: any;

@Component({
  selector: 'app-detail-charts-heat',
  templateUrl: './heat.component.html',
  styleUrls: ['./heat.component.scss'],
})
export class HeatComponent implements OnInit {
  @Input() mapa_calor: any;
  innerWidth: number;
  map_position: number;
  @Input() loading: boolean;
  information: any;
  @Input() periodReference: any;
  @Input() primerLevelCode: any;
  description: any;
  source: any;  
  technical_note: any;

  anterior_input: any;
  monthsName = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];

  constructor(
    private mapService: MapService,
    private utilService: UtilService
  ) {
    this.innerWidth = 0;
    this.map_position = 0;
    this.loading = true;
    this.information = {};
    this.anterior_input = this.mapa_calor;
  }

  ngOnInit(): void {
    this.getInformation();
    this.refresh();
  }

  async refresh() {
    if (this.mapa_calor === this.anterior_input) {
      await new Promise((f) => setTimeout(f, 1000));
      this.refresh();
    } else {
      this.anterior_input = this.mapa_calor;
      this.refresh();
      this.getInformation();
    }
  }

  async getInformation() {
    if (this.mapa_calor === undefined) {
      await new Promise((f) => setTimeout(f, 400));
      this.getInformation();
      return;
    }

    if (this.primerLevelCode === undefined) {
      await new Promise((f) => setTimeout(f, 400));
      this.getInformation();
      return;
    }    
    //console.log("Mapa de calor",this.mapa_calor );

    this.innerWidth = window.innerWidth;
    this.loading = true;


    if (this.primerLevelCode ==='0') {
      this.description = "Representan la remuneración promedio mensual y la distribución de trabajadores por departamento que se desempeñan en la ocupación seleccionada.";
      this.source = 'MTPE-Planilla Electrónica (PLAME y T-Registro) del sector público. La información corresponde a los asalariados del sector público.';
      this.technical_note = '<p align="justify">La remuneración promedio en el sector público se refiere al monto pagado por conceptos pensionarios, no pensionarios y por Comité de Administración de Fondos de Asistencia y Estímulo (CAFAE).<br>La información de departamentos corresponde a la ubicación geográfica de la sede principal de la institución o entidad pública.</p>';
    } else 
    {
      this.description = "Representan la remuneración promedio mensual y la distribución de trabajadores por departamento que se desempeñan en la ocupación seleccionada.";
      this.source = 'MTPE-Planilla Electrónica (PLAME y T-Registro) del sector privado. La información corresponde a los asalariados del sector formal privado.';      
      this.technical_note = '<p align="justify">La remuneración promedio del sector privado formal se refiere a las remuneraciones declaradas por el empleador en la Planilla Electrónica declaradas. Se considera a las remuneraciones de trabajadores percibidas por renta de quinta categoría y correspondientes al monto pagado por conceptos permanentes. La información de departamentos corresponde a la ubicación geográfica del centro de trabajo del trabajador.</p>';
    } 


    this.information = {
      technical_note: this.technical_note,
      id: 'mapa-calor',
      title: 'Mapa de calor',
      description: this.description,
      source: this.source,
      periodReference: this.periodReference,
    };

    this.draw(this.map_position, this.innerWidth);
    this.loading = false;
  }

  draw(map_position: number, innerWidth: number) {


    let map = this.mapa_calor[map_position];
    heat = this.mapa_calor[map_position].data;

    heat.forEach((element:any) => {
      if (element.region == 'Prov.Cons.Callao') {
        element.region = 'Callao';
      }
    });

    let regexp = /(\d)(?=(\d{3})+(?!\d))/g;
    let myMap = new Map([
      ['ANCASH', 'Áncash'],
      ['APURIMAC', 'Apurímac'],
      ['HUANUCO', 'Huánuco'],
      ['JUNIN', 'Junín'],
      ['SAN MARTIN', 'San Martín'],
    ]);

    let mapTranslate: any = {
      Amazonas: 'AMAZONAS',
      Áncash: 'ANCASH',
      Apurímac: 'APURIMAC',
      Arequipa: 'AREQUIPA',
      Ayacucho: 'AYACUCHO',
      Cajamarca: 'CAJAMARCA',
      Callao: 'CALLAO',
      Cusco: 'CUSCO',
      Huancavelica: 'HUANCAVELICA',
      Huánuco: 'HUANUCO',
      Ica: 'ICA',
      Junín: 'JUNIN',
      'La Libertad': 'LA LIBERTAD',
      Lambayeque: 'LAMBAYEQUE',
      'Lima Provincias' : 'LIMA PROVINCIAS',
      'Lima Metropolitana' : 'CALLAO',      
      Loreto: 'LORETO',
      'Madre de Dios': 'MADRE DE DIOS',
      Moquegua: 'MOQUEGUA',
      Pasco: 'PASCO',
      Piura: 'PIURA',
      Puno: 'PUNO',
      'San Martín': 'SAN MARTIN',
      Tacna: 'TACNA',
      Tumbes: 'TUMBES',
      Ucayali: 'UCAYALI',
    };

     //let heat_tmp = heat;
    // console.log("heat_tmp",heat_tmp);
    
    // var index = heat_tmp.map((e: any) => e.region).indexOf("Lima Metropolitana");

    // console.log("index",index);
    // heat_tmp.splice(index,1);

    let min = Math.min.apply(
      Math,
      heat.map(function (o: any) {
        return o.value;
      })
    );
    let max = Math.max.apply(
      Math,
      heat.map(function (o: any) {
        if (o.region == "Lima Metropolitana" ) {
          return 0;
        } else {
          return o.value;
        }
      })

    );

    // console.log("min",min);
    // console.log("max",max);    

    d3.select('div#heatmap-content').select('svg').remove();
    d3.select('div#heatmap-content').select('div.my-tooltip').remove();

    let width = innerWidth < 500 ? 350 : 500;
    let height = 500;

    svg = d3
      .select('div#heatmap-content')
      .append('svg')
      .attr('id', 'heatmap')
      .attr('width', width)
      .attr('height', height);
    const div = d3
      .select('div#heatmap-content')
      .append('div')
      .attr('class', 'my-tooltip')
      .style('opacity', 0);

    const projection = d3.geoAlbers();
    const path: any = d3.geoPath().projection(projection);
    const color = d3.scaleLinear(['#F5DCC7', '#D09261']);
    color.domain([min, max]);

    d3.json('/assets/peru.json').then(function (json: any) {
      const geojson: any = topojson.feature(json, json.objects.departments);
      projection.rotate([80, 40]).fitExtent(
        [
          [0, 0],
          [width, height],
        ],
        geojson
      );

      for (let i = 0; i < heat.length; i++) {
        const dataState = heat[i].region;
        const dataValue = heat[i].value;
        //console.log("dataState",dataState);

        for (let j = 0; j < json.objects.departments.geometries.length; j++) {
          const jsonState =
            json.objects.departments.geometries[j].properties.NOMBDEP;
            //console.log("jsonState",jsonState);
          if (mapTranslate[dataState] == jsonState) {
            json.objects.departments.geometries[j].properties.visited =
              dataValue;
            break;
          }
        }
      }

      svg
        .selectAll('path')
        .data(geojson.features)
        .enter()
        .append('path')
        .attr('d', path)
        .style('stroke', '#fff')
        .style('stroke-width', '1')
        .style('fill', function (d: any) {
          const value = d.properties.visited;
          //console.log("value de color",value);
          if (value) {
            return color(value);
          } else {
            return '#DCDCDC';
          }
        });

      //return;  
      d3.json('/assets/markers-dyt.json').then(function (data) {
        svg
          .selectAll('.text')
          .data(data)
          .enter()
          .append('text')
          .attr('transform', (d: any) => {
            return `translate(${projection([d.lon, d.lat])})`;
          })
          .attr('font-size', '12')
          .style('cursor', 'pointer')
          .text((d: any) => {
            for (let i = 0; i < heat.length; i++) {
              if (heat[i].region === d.place) {
                if (myMap.has(d.place)) {
                  return myMap.get(d.place);
                } else {
                  return (
                    d.place.charAt(0).toUpperCase() + 
                    d.place.slice(1)
                  );
                  // if (d.place == 'Lima Provincias') {
                  //   return 'Lima Provincias';
                  // } else if(d.place == 'Lima Metropolitana') {
                  //   return 'Lima Metropolitana';
                  // } else {
                  //   return (
                  //     d.place.charAt(0).toUpperCase() + 
                  //     d.place.slice(1)
                  //   );
                  // }   
                }
              }
            }
            return null;
          })
          .on('mouseover', function (event: any, d: any) {
            div.transition().duration(200).style('opacity', 0.9);
            div
              .text(() => {
                for (let i = 0; i < heat.length; i++) {
                  if (heat[i].region === d.place) {
                    let city;
                    if (myMap.has(d.place)) {
                      city = myMap.get(d.place);
                    } else {
                      city =
                        d.place.charAt(0).toUpperCase() +
                        d.place.slice(1);
                    }
                    return `${city}: ${heat[i].label}`;
                  }
                }
                return null;
              })
              .style('left', event.pageX + 'px')
              .style('top', event.pageY - 28 + 'px');
          })
          .on('mouseout', function () {
            div.transition().duration(500).style('opacity', 0);
          });

        svg
          .selectAll('.text')
          .data(data)
          .enter()
          .append('text')
          .attr('transform', (d: any) => {
            return `translate(${projection([d.lon, d.lat - 0.4])})`;
          })
          .attr('font-size', '12')
          .text((d: any) => {
            for (let i = 0; i < heat.length; i++) {
              if (heat[i].region === d.place) {
                return heat[i].label;
              }
            }
            return null;
          });

        const svgLegend = d3.select('svg#heatmap');
        const defs = svgLegend.append('defs');
        const linearGradient = defs
          .append('linearGradient')
          .attr('id', 'linear-gradient');

        linearGradient
          .attr('x1', '0%')
          .attr('y1', '0%')
          .attr('x2', '100%')
          .attr('y2', '0%');

        linearGradient
          .selectAll('stop')
          .data([
            { offset: '0%', color: '#F5DCC7' },
            { offset: '100%', color: '#D09261' },
          ])
          .enter()
          .append('stop')
          .attr('offset', function (d) {
            return d.offset;
          })
          .attr('stop-color', function (d) {
            return d.color;
          });

        svgLegend
          .append('text')
          .attr('x', innerWidth < 500 ? 10 : 70)
          .attr('y', 471)
          .attr('font-size', '12')
          .style('text-anchor', 'left')
          .text(map.shortTitle);

        svgLegend
          .append('text')
          .attr('x', innerWidth < 500 ? 70 : 165)
          .attr('y', 490)
          .attr('font-size', '12')
          .style('text-anchor', 'left')
          .text(min.toString());
          //.text(min.toString().replace(regexp, '$1,'));          

        svgLegend
          .append('text')
          .attr('x', innerWidth < 500 ? 170 : 265)
          .attr('y', 490)
          .attr('font-size', '12')
          .style('text-anchor', 'left')
          .text(max.toString());          
//          .text(max.toString().replace(regexp, '$1,'));

        svgLegend
          .append('rect')
          .attr('x', innerWidth < 500 ? 80 : 180)
          .attr('y', 460)
          .attr('width', 100)
          .attr('height', 15)
          .style('fill', 'url(#linear-gradient)');
      });
    });
  }

  changePosition(position: any) {
    this.map_position = position;
    this.draw(this.map_position, this.innerWidth);
  }

  downloadImage() {
    this.utilService.downloadPngFile(svg, 'Mapa de calor');
  }

  // downloadCsv() {
  //   let csv: any = [];
  //   heat.forEach((x: any) => {
  //     csv.push({"region": x.region, "valor": Number(x.value)});
  //   });
  //   this.utilService.downloadCsvFile(csv, 'Mapa de calor');
  // }

  downloadExcel() {
    let arrayForXls: any = [];
    heat.forEach((x: any) => {
      arrayForXls.push({"region": x.region, "valor": Number(x.value)});
    });

    TableUtil.exportArrayToExcel(arrayForXls, 'Mapa de calor');
  }  

}
