import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'card-ep',
  templateUrl: './card-ep.component.html',
  styleUrls: ['./card-ep.component.scss'],
})
export class card_ep implements OnInit {
  information: any;
  imageBase: string;
  prefijo: string;

  constructor(private apiService: ApiService) {
    this.information = {};
    this.imageBase = environment.strapiUrl;
    this.prefijo = "http";
  }

  ngOnInit(): void {
    this.getPublicationsData();
  }

  async getPublicationsData() {
    let items = await this.apiService.getMany('/nav-cards');

    this.information = {
      results: items,
    };

 

  }
}
