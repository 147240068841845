import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-detail-hiring-companies',
  templateUrl: './hiring-companies.component.html',
  styleUrls: ['./hiring-companies.component.scss'],
})
export class HiringCompaniesComponent implements OnInit, OnDestroy {
  @Input() empresas_contratan: any;
  @Input() loading: boolean;
  information: any;
  imageDownload: any;
  detailServiceSubscription: Subscription;

  constructor() {
    this.loading = false;
    this.information = {};
    this.detailServiceSubscription = Subscription.EMPTY;
  }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.information = {
      technical_note: `<p align="justify">La información se refiere al número de empresas distintas que contrataron a personal en la ocupación seleccionada.</p>`,
      id: 'empresas-contratan',
      customStyle: 'width: 100%; height: 324px',
      buttonsInNewLine: true,
      response: {
        title: 'Empresas que contratan',
        description:
          'Representa la cantidad de empresas que registran al menos un trabajador que se desempeñan en la ocupación seleccionada.',
        source:
          'MTPE-Planilla Electrónica (PLAME y T-Registro) del sector privado.',
      },
      type: 'LineChart',
      columns: [
        { type: 'string', role: 'domain', label: 'Mes' },
        { type: 'number', role: 'data', label: 'Cantidad' },
        { type: 'string', role: 'annotation', label: 'Cantidad' },
      ],
      options: {
        legend: {
          position: 'none',
        },
        pointsVisible: true,
        vAxis: {
          textStyle: {
            fontSize: 12,
            color: '#727B7D',
          },
          format: ''
        },
        hAxis: {
          textStyle: {
            fontSize: 12,
            color: '#727B7D',
          },
        },
        series: {
          0: {
            annotations: {
              textStyle: {
                strokeWidth: 0,
                fontSize: 12,
                color: '#3C63B0',
              },
            },
          },
        },
        annotations: {
          stemColor: 'none',
          alwaysOutside: true,
        },
        chartArea: {
          left: 60,
          right: 20,
          top: 40,
          bottom: 40,
        },
        pointSize: 8,
      },
    };
  }

  ngOnDestroy(): void {
    this.detailServiceSubscription.unsubscribe();
  }
}
