import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-other-modalities',
  templateUrl: './other-labor-training.component.html',
  styleUrls: ['./other-labor-training.component.scss']
})
export class OtherModalitiesComponent implements OnInit, OnDestroy {

  information: any;
  totalResults: number;
  tabs: any[];
  loading: boolean;

  constructor(private apiService: ApiService) { 
    this.information = {};
    this.totalResults = 0;
    this.tabs = [];
    this.loading = true;
  }

  ngOnInit(): void {
    this.getInformation();
  }

  async getInformation() {
    let header = await this.apiService.getOne(
        '/job-developments?code=otras-modalidades'
    );
  
    let items = await this.apiService.getMany('/other-labor-trainings');
    

    this.information = {
        title: header.title,
        description: header.description,
        results: items,
    };
    this.totalResults = this.information.results.length;
    this.tabs = await this.apiService.getMany('/other-labor-trainings');
    this.loading = false;
  }

  ngOnDestroy(): void {
  }
}