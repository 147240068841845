import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-pre-professional-professional',
  templateUrl: './pre-professional-professional.component.html',
  styleUrls: ['./pre-professional-professional.component.scss']
})
export class PreProfessionalComponent implements OnInit, OnDestroy {

  information: any;
  totalResults: number;
  imageBase: string;
  tabs: any[];
  loading: boolean;

  constructor(private apiService: ApiService) { 
    this.information = {};
    this.totalResults = 0;
    this.imageBase = environment.strapiUrl;
    this.tabs = [];
    this.loading = true;
  }

  ngOnInit(): void {
    this.getInformation();
  }

  async getInformation() {
    let header = await this.apiService.getOne(
        '/job-developments?code=pre-profesional-profesional'
    );
  
    let items = await this.apiService.getMany('/pre-professional-and-professionals');
    

    this.information = {
        title: header.title,
        description: header.description,
        results: items,
    };
    this.totalResults = this.information.results.length;
    this.tabs = await this.apiService.getMany('/pre-professional-and-professionals');
    this.loading = false;
  }

  ngOnDestroy(): void {
  }
}