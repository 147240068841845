import { Component, OnInit, ViewChild } from '@angular/core';
import { UtilService } from '../services/util.service';
import { ApiService } from '../services/api.service';
import { environment } from '../../environments/environment';

import * as d3 from 'd3';
import * as topojson from 'topojson-client';

@Component({
  selector: 'app-presential',
  templateUrl: './presential.component.html',
  styleUrls: ['./presential.component.scss'],
})
export class PresentialComponent implements OnInit {

  @ViewChild('auto') auto: any;
  @ViewChild('cardDiv') cardDiv: any;

  innerWidth: number;
  jumbotron: any;
  imageUrl: string;

  isSelected: boolean;
  selectedId: string;

  departments: any[];
  keyword = 'name';
  notFound = 'No se encontraron resultados';

  jobCenters: any[];
  jobCentersByState: any[];
  projection = d3.geoAlbers();

  constructor(
    private apiService: ApiService,
    private utilService: UtilService
  ) {
    this.innerWidth = window.innerWidth;
    this.jumbotron = {};
    this.imageUrl = '';
    this.isSelected = false;
    this.selectedId = '';
    this.departments = [];
    this.jobCenters = [];
    this.jobCentersByState = [];
  }

  ngOnInit(): void {
    this.getJumbotron();
    this.getJobCenters();
  }

  async getJumbotron() {
    this.jumbotron = await this.apiService.getOne(`/banners?code=orientacion-presencial`);
    if (this.jumbotron) {
      this.jumbotron.url = environment.strapiUrl + this.jumbotron.image[0].url;
    } else {
      this.jumbotron = {};
    }
  }

  async getJobCenters() {
    this.jobCenters = await this.apiService.getMany('/job-centers');
    let stateIds: number[] = [];
    if (this.jobCenters) {
      this.jobCenters.forEach((x) => {
        if (!stateIds.includes(x.state_id)) {
          stateIds.push(x.state_id);
        }
      });
      this.departments = this.utilService
        .getDepartments()
        .filter((d: any) => stateIds.includes(d.id));
      this.draw(stateIds);
    } else {
      this.jobCenters = [];
    }
  }

  draw(stateIds: number[]) {
    let width = this.innerWidth < 500 ? 350 : 500;
    let height = this.innerWidth < 500 ? 500 : 750;

    const svg = d3
      .select('#presential-map')
      .attr('width', width)
      .attr('height', height);
    const path: any = d3.geoPath().projection(this.projection);

    d3.json('/assets/peru.json').then((json: any) => {
      const geojson: any = topojson.feature(json, json.objects.departments);
      this.projection.rotate([100, 40]).fitExtent([[0, 0], [width, height],], geojson);

      svg
        .selectAll('path')
        .data(geojson.features)
        .enter()
        .append('path')
        .attr('d', path)
        .attr('id', (r: any) => `r-${r.properties.NOMBDEP.replaceAll(' ', '-')}`)
        .style('stroke', '#fff')
        .style('stroke-width', 1.5)
        .attr('fill', '#DCE1EA');

      d3.json('/assets/markers.json').then((data: any) => {
        let filtered = data.filter((x: any) => stateIds.includes(x.id));
        filtered.forEach((x: any) => {
          svg
            .select(`#r-${x.place.replaceAll(' ', '-')}`)
            .attr('class', 'region')
            .on('click', () => {
              this.selectedId = `d-${x.id}`;
              this.defineMapMarkers();
            });
        });

        svg
          .selectAll('image')
          .data(filtered)
          .enter()
          .append('image')
          .attr('width', 14)
          .attr('height', 14)
          .attr('transform', (d: any) => `translate(${this.projection([d.lon, d.lat])})`)
          .attr('id', (d: any) => `d-${d.id}`)
          .attr('xlink:href', '/assets/vector.png')
          .style('opacity', 1)
          .style('cursor', 'pointer')
          .on('click', (x: any) => {
            this.selectedId = x.target.id;
            this.defineMapMarkers();
          });

        svg
          .selectAll('text')
          .data(filtered)
          .enter()
          .append('text')
          .attr('transform', (d: any) => {
            return `translate(${this.projection([
              d.lon - d.place.length * 0.06,
              d.lat - 0.7,
            ])})`;
          })
          .attr('font-size', '12')
          .text((d: any) => d.place);
      });
    });
  }

  selectEvent(item: any) {
    this.selectedId = `d-${item.id}`;
    this.defineMapMarkers();
  }

  onFocused(e: any) {
    this.auto.close();
  }

  onCleared(e: any) {
    this.auto.close();
  }

  defineMapMarkers() {
    this.isSelected = true;
    d3.selectAll('image')
      .attr('transform', (d: any) => `translate(${this.projection([d.lon, d.lat])})`)
      .attr('xlink:href', '/assets/dvector.png')
      .attr('width', 14)
      .attr('height', 14);

    d3.select(`#${this.selectedId}`)
      .attr('xlink:href', '/assets/marker.png')
      .attr('transform', (d: any) => `translate(${this.projection([d.lon - 0.155, d.lat - -0.18])})`)
      .attr('width', 24)
      .attr('height', 24);

    this.jobCentersByState = this.jobCenters.filter(
      (jobCenter: any) =>
        jobCenter.state_id === Number(this.selectedId.replace('d-', ''))
    );
    setTimeout(() => {
      this.cardDiv.nativeElement.scrollIntoView();
    }, 200);
  }
}
