import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DesignSystemComponent } from './design-system.component';

import { HttpClientModule } from '@angular/common/http';

import { SharedModule } from '../shared/shared.module';
import { ButtonsComponent } from './buttons/buttons.component';
import { FontsComponent } from './fonts/fonts.component';
import { ComponentsComponent } from './components/components.component';
import { CardsComponent } from './cards/cards.component';

@NgModule({
  declarations: [
    DesignSystemComponent,
    ButtonsComponent,
    FontsComponent,
    ComponentsComponent,
    CardsComponent,
  ],
  imports: [CommonModule, HttpClientModule, SharedModule],
  exports: [],
})
export class DesignSystemModule {}
