<app-loading *ngIf="loading"></app-loading>
<div *ngIf="!loading" class="p-5 rounded-lg " style="background: linear-gradient(#ffffff 0%, #ffffff 0%, #ecf2f9 0%, #ecf2f9 100%)">
  <h2 class="text-center text-attention">{{ information.results.title }}</h2>
  <p class="p-3 text-center text-secondary">
    {{ information.results.description }}
  </p>
  <div class="text-center">
    <a
      href="{{ information.results.url }}"
      class="btn btn-outline-primary"
      tabindex="-1"
      role="button"
      aria-disabled="true"
      >{{ information.results.button_text }}</a
    >
  </div>
</div>
