import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';


@Component({
  selector: 'app-outlook-indicators-list',
  templateUrl: './indicators-list.component.html',
  styleUrls: ['./indicators-list.component.scss']
})
export class IndicatorsListComponent implements OnInit {

  @ViewChild('auto') auto: any;

  loading: boolean;
  error: boolean;
  information: any;
  indicators: any[];
  grupos: any;  
  keyword = 'name';

  constructor(private router: Router, private apiService: ApiService) {
    this.loading = true;
    this.error = false;
    this.information = {};
    this.indicators = [];
    this.grupos = ["ODE","CON","ITP","SES","HTD","IOT", "DSR"];    
  }

  ngOnInit(): void {
    this.getInformation();
  }

  async getInformation() {

    let information = await this.apiService.getManyBackend('/indicator-groups', null);

    //console.log("information ini",information);

    information = information.data.filter(
      (occupation: any) => this.grupos.includes(occupation.code)
    );    
 
   
    //console.log("information ",information);
    
    this.information = information;

    if (!this.information) {
      this.error = true;
    } else {
      for (let group of this.information) {
        for (let subgroup of group.sub_groups) {
          this.indicators.push({
            id: subgroup.id,
            name: `${group.category} > ${subgroup.name}`,
            url: `/panorama-laboral/${group.code}/subgrupo/${subgroup.id}/vista/1`
          });
        }
      }
    }
    this.loading = false;
  }

  findByCode(code: string) {
    if (this.information) {
      return this.information.find((x: any) => x.code == code);
    }
  }

  selectEvent(item: any) {
    this.router.navigate([item.url]);
  }

  onFocused(e: any) {
    this.auto.close();
  }

  onCleared(e: any) {
    this.auto.close();
  }
}
