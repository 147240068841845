import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-two-columns',
  templateUrl: './card-two-columns.component.html',
  styleUrls: ['./card-two-columns.component.scss'],
})
export class CardTwoColumnsComponent implements OnInit {
  @Input() information: any;
  @Input() imageBase: any;

  navigate(url: string) {
    if (url === '') {
      return;
    }
    let external = document.createElement('a');
    external.href = url;
    external.target = '_blank';
    external.click();
    external.remove();
  }

  ngOnInit(): void {}
}
