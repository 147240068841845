import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-detail-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  occupation: any;
  secondLevel: any;

  strapiUrl: string;
  occupationId: string;
  occupationCode: string;

  loading: boolean;
  information: any;

  isFour: boolean;
  occupationFour: any;
  showIndicators: any;

  constructor(private route: ActivatedRoute, private apiService: ApiService) {
    this.loading = true;
    this.information = null;

    this.strapiUrl = environment.strapiUrl;
    this.occupationId = this.route.snapshot.params.id;
    this.occupationCode = this.route.snapshot.params.code;

    this.occupation = null;
    this.secondLevel = null;

    this.isFour = this.route.snapshot.params.code ? this.route.snapshot.params.code.length !== 7: true;
    this.occupationFour = undefined;
  }

  ngOnInit(): void {
    this.getOccupation();
  }

  async getOccupation() {
    if (this.isFour) {
      let occupation = await this.apiService.getMany(
        `/occupation-fours/${this.occupationId}`
      );
      this.occupation = occupation;

      let secondLevel = await this.apiService.getMany(
        `/occupation-twos/${occupation.occupation_three.occupation_two}`
      );
      this.secondLevel = secondLevel;

    } else {
      let occupation = await this.apiService.getOne(
        `/occupation-sevens?code=${this.occupationCode}`
      );
      this.occupation = occupation;

      let occupationFour = await this.apiService.getMany(
        `/occupation-fours/${occupation.occupation_four.id}`
      );
      this.occupationFour = occupationFour;

      let secondLevel = await this.apiService.getMany(
        `/occupation-twos/${occupationFour.occupation_three.occupation_two}`
      );
      this.secondLevel = secondLevel;
    }
    let indicators = await this.getDatosYTendencias();
    if(indicators != null){
      this.information = {indicators: indicators};
    }
  }

  async getDatosYTendencias(){
    let indicators = [];
    var filters: { [k: string]: any } = {};

    console.log("Invoca API sin filters - Head :",filters);

    let datosYTendencias = await this.apiService.getManyBackend(
      `/occupations/${this.occupation.code}`, filters
    );

    console.log("datosYTendencias",datosYTendencias);

    this.showIndicators = datosYTendencias.data.tabla_resumen.length>0;
    if(this.showIndicators){
      let tabla_resumen = datosYTendencias.data.tabla_resumen;

      //tabla_resumen = tabla_resumen.filter((row: any)=>row.name.toLowerCase() != 'no determinado');

      let totalTrabajadores = 0;
      let totalMujeres = 0;
      let tasaMujeres = 0;
      let sumaRemuneracion = 0;
      let sumaTotalRegla = 0;    
      let sumaEdad = 0;    
      let sumaTotalEdad = 0; 
      let totalSueldoPromedio = 0;            
      let totalEdad = 0;   


      tabla_resumen.forEach((row: any) => {
        totalTrabajadores += parseFloat(row.total_trabajadores.replace(",",""));
        totalMujeres += parseFloat(row.total_mujeres.replace(",",""));
        //edadTrabajadores += parseInt(row.edad_promedio.replace(",",""));
        //tasaMujeres += parseFloat(row.tasa_mujeres.replace(",",""));
  
        sumaRemuneracion += parseFloat(row.suma_remuneracion.replace(",",""));
        sumaTotalRegla += parseFloat(row.sumatotal_regla.replace(",",""));  
  
        sumaEdad += parseFloat(row.suma_edad.replace(",",""));
        sumaTotalEdad += parseFloat(row.sumatotal_edad.replace(",",""));           
      });
  

      if(sumaTotalRegla!=0){
        totalSueldoPromedio = Math.round(sumaRemuneracion/sumaTotalRegla);
      }
  
      if(sumaTotalEdad!=0){
        totalEdad= Math.round(sumaEdad/sumaTotalEdad);
      }

      if(totalTrabajadores!=0){
        tasaMujeres= Math.round(totalMujeres/totalTrabajadores * 100); 
      }   
      

      //indicators.push({title: "Remuneración promedio mensual", value: `S/ ${Math.round((sueldoPromedio/tabla_resumen.length)).toLocaleString().replace(".",",")}`});
      indicators.push({title: "Remuneración promedio mensual", value: `S/ ${totalSueldoPromedio.toString()}`});      
      indicators.push({title: "Cantidad de trabajadores", value: `${totalTrabajadores.toString()} trabajadores`});
      //indicators.push({title: "Edad promedio", value: `${Math.round((edadTrabajadores/tabla_resumen.length)).toLocaleString()} años`});
      indicators.push({title: "Edad promedio", value: `${totalEdad.toString()} años`});      
      indicators.push({title: "Participación de mujeres", value: `${tasaMujeres.toString()}%`});

    }

    return indicators;
  }

}
