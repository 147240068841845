<div id="carouselHome" class="carousel slide carousel-dark" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselHome" data-bs-slide-to="0" class="active" aria-current="true"
      aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselHome" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselHome" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item" data-bs-interval="6000" *ngFor="let carousel of information.results; let i = index"
      [ngClass]="{ 'active': i === 0}">
      <img class="d-block w-100 lazyload" [attr.data-src]="imageBase + carousel.image[0].url" alt="..." />
      <div class="carousel-caption carousel-bottom">
        <div class="row">
          <div class="col-md-6 col-sm-0">
          </div>
          <div class="col-md-6 col-sm-12" align="left">
            <h1 class="carousel-title pt-5 pb-2"  style="font-size: 3.0em;">{{carousel.title}} </h1>
            <span [innerHTML] ="carousel.Content" class="carousel-contenido" ></span>
            <br />            
            <a href={{carousel.url}} class="btn btn-primary" role="button" aria-disabled="true">{{carousel.button_text}}</a>
          </div>
        </div>

      </div>
      <!-- <div class="carousel-caption carousel-bottom">
        <h1 class="carousel-title pb-5">{{carousel.title}} </h1>
        <p [innerHTML] ="carousel.Description" class="carousel-title" style="font-size: 1.0em;"></p>
        <br />
        <a href={{carousel.url}} class="btn btn-primary" role="button" aria-disabled="true">{{carousel.button_text}}</a>
        <br /><br />
      </div> -->
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselHome" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Anterior</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselHome" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Siguiente</span>
  </button>
</div>
