import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { CommonService } from 'src/app/services/common.service';
import { UtilService } from 'src/app/services/util.service';
import { LineChartComponent } from '../../shared/linechart/linechart.component';
import { TableUtil } from "src/app/services/table.Utils";

@Component({
  selector: 'app-outlook-nostat-chart-section',
  templateUrl: './nostat-chart-section.component.html',
  styleUrls: ['./nostat-chart-section.component.scss'],
})
export class NoStatChartSectionComponent implements OnInit, OnDestroy {
  monthsName = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
  "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
  ];

  @ViewChild('cardDiv') cardDiv: any;

  loading: boolean;
  isFilterSelected: boolean;

  indicatorIds: any[];
  selectedIndicator: number;
  currentCode: string;
  currentSubgroupId: number;
  currentOrder: number;

  @ViewChild('lineChart')
  private lineChart: LineChartComponent = new LineChartComponent();
  chartInformation: any;
  chartColumns: any[];

  commonServiceSubscription: Subscription;
  paramsSubscription: Subscription;

  constructor(
    private r: Router,
    private router: ActivatedRoute,
    private commonService: CommonService,
    private apiService: ApiService,
    private utilService: UtilService
  ) {
    this.loading = true;
    this.isFilterSelected = false;

    this.indicatorIds = [];
    this.selectedIndicator = 0;
    this.currentCode = '';
    this.currentSubgroupId = 0;
    this.currentOrder = 0;

    this.chartInformation = {};
    this.chartColumns = [
      { type: 'string', role: 'domain' },
      { type: 'number', role: 'data' },
      { type: 'string', role: 'annotation' },
    ];

    this.commonServiceSubscription = Subscription.EMPTY;
    this.paramsSubscription = Subscription.EMPTY;
  }

  ngOnInit(): void {
    this.getInformation();
  }

  getInformation() {
    this.paramsSubscription = this.router.params.subscribe(
      ({ code, id, order }) => {
        this.currentCode = code;
        this.currentSubgroupId = id;
        this.currentOrder = order;
        this.loading = false;
        this.isFilterSelected = false;
      }
    );

    this.commonServiceSubscription = this.commonService
      .getUpdate()
      .subscribe((message) => {
        this.loading = true;
        this.getChartWithFilter(message);
      });
  }

  async getChartWithFilter(message: any) {
    if (message.filter == 'clear') {
      this.isFilterSelected = false;
    } else {
      let filters = {
        filter: message.filter,
        value: message.value,
      };
      let response = await this.apiService.getManyBackend(
        `/indicators/${this.currentCode}/sub-groups/${this.currentSubgroupId}`,
        null
      );
      this.indicatorIds = response.data;
      this.selectedIndicator = response.data[this.currentOrder - 1].id;
      response = await this.apiService.getManyBackend(
        `/indicators/${this.currentCode}/${this.selectedIndicator}`,
        filters
      );

      let arrayForChart = [];
      for (let item of response.data.values) {
        arrayForChart.push([this.normalizePeriod(item.period), item.value, item.text_value]);
      }
      this.chartInformation = {
        title: `${response.data.title} - ${message.filter} - ${message.value}`,
        description: response.data.description,
        source: response.data.source,
        technicalNotes: response.data.technical_note,
        data: arrayForChart,
        columns: this.chartColumns,
      };
      this.isFilterSelected = true;
    }
    this.loading = false;
    setTimeout(() => {
      this.cardDiv.nativeElement.scrollIntoView();
    }, 200);
  }

  normalizePeriod(period:any){
    if(!period.includes("-")){
      if(period.length >= 6){
        period = period.substring(0,4) + "-" + period.substring(4,6);
      }
    }
    return period;
  }

  changeChart(indicatorId: number) {
    let count = 0;
    for (let i of this.indicatorIds) {
      count++;
      if (i.id === indicatorId) {
        break;
      }
    }
    this.r.navigate([
      `/panorama-laboral/${this.currentCode}/subgrupo/${this.currentSubgroupId}/vista/${count}`,
    ]);
  }

  // downloadCsv() {
  //   let arrayForCsv = [];
  //   for (let item of this.chartInformation.data) {
  //     arrayForCsv.push({ period: this.normalizePeriod(item[0]), value: item[1] });
  //   }
  //   this.utilService.downloadCsvFile(arrayForCsv, this.chartInformation.title);
  // }

  downloadExcel() {
    let arrayForXls = [];
    for (let item of this.chartInformation.data) {
      arrayForXls.push({ periodo: this.normalizePeriod(item[0]), valor: item[1] });
    }
    TableUtil.exportArrayToExcel(arrayForXls, this.chartInformation.title);
  }  

  downloadImage() {
    let download = document.createElement('a');
    download.href = this.lineChart.lcImageDownload;
    download.download = this.chartInformation.title;
    download.click();
    download.remove();
  }

  ngOnDestroy(): void {
    this.paramsSubscription.unsubscribe();
    this.commonServiceSubscription.unsubscribe();
  }
}
