import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { environment } from '../../../environments/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-detail-formacion-academica',
  templateUrl: './formacion-academica.component.html',
  styleUrls: ['./formacion-academica.component.scss'],
})
export class FormacionAcademicaComponent implements OnInit {
  information: any;
  univCareers: any[];
  techCareers: any[];

  banners: any;
  strapiUrl: string;
  occupation: any;
  occupationSeven: any;
  occupationId: string;
  occupationCode: string;
  type_institutions: any;
  career_names: any;
  career_info: any;
  isFour: any;

  constructor(private apiService: ApiService, private route: ActivatedRoute) {
    this.information = {};
    this.univCareers = [];
    this.techCareers = [];

    this.banners = [];
    this.strapiUrl = environment.strapiUrl;
    this.occupation = null;
    this.occupationId = this.route.snapshot.params.id;
    this.occupationCode = this.route.snapshot.params.code;
    this.type_institutions = [];
    this.career_names = [];
    this.career_info = [];

    this.isFour = this.route.snapshot.params.code ? this.route.snapshot.params.code.length !== 7: true;
  }

  ngOnInit(): void {
    this.getBanners();
    this.getOccupation();
    this.getInformation();
  }

  async getBanners() {
    this.banners = await this.apiService.getMany(
      `/banner-urls?code=formacion-academica`
    );
  }


  async getOccupation() {
    let occupation: any = undefined;
    if (!this.isFour) {
      let occupationSeven = await this.apiService.getOne(
        `/occupation-sevens?code=${this.occupationCode}`
      );
      this.occupationSeven = occupationSeven;

      occupation = await this.apiService.getMany(
        `/occupation-fours/${occupationSeven.occupation_four.id}`
      );
    } else {
        occupation = await this.apiService.getMany(
          `/occupation-fours/${this.occupationId}`
        );
    }
    this.occupation = occupation;
    if(occupation!=null)
      await this.getOccupationCareer(occupation.code);

  }

  async getOccupationCareer(code: string) {
    const occupationCareerCodes = (
      await this.apiService.getManyBackend(`/occupations/career/${code}`, null)
    ).data;
    let indexCareer = 1;
    const occupationCareerCodesSorted = occupationCareerCodes.map((careerCode: { code: any; }) =>
      {return {code: careerCode.code, order: indexCareer++}}
    );
    const careerCodes = occupationCareerCodesSorted.reduce(
      (previo: any, actual: any) => {
        if (previo === '') {
          return `code_career_in=${actual.code}`;
        }
        return `${previo}&code_career_in=${actual.code}`;
      },
      ''
    );

    if (careerCodes === '') {
      return;
    }

    const careerInfo = await this.apiService.getMany(
      `/occupation-carrers?graduation=SI&${careerCodes}&_limit=200`
    );
    this.career_info = careerInfo;

    const carrerInstituteType = careerInfo.map(
      (career: any) =>
        `${career.code_type_institution}-${career.name_type_institution}`
    );

    let uniqueInstitutionType = new Set([...carrerInstituteType]);
    let uniqueInstitutionArray = [...uniqueInstitutionType];
    const uniqueInstitutionTypeObject = uniqueInstitutionArray.map(
      (career: any) => {
        const careerSplit = career.split('-');
        return {
          id: careerSplit[0],
          name: careerSplit[1],
        };
      }
    );
    this.type_institutions = uniqueInstitutionTypeObject;

    /* CAREER NAMES */

    const careerNames = careerInfo.map(
      (career: any) =>
        // `${career.code_type_institution}-${career.code_career}-${career.name_career}`
        `${career.code_type_institution}-${career.code_career}`
    );
    let uniqueCareerNames = new Set([...careerNames]);
    let uniqueCareerArray = [...uniqueCareerNames];

    const uniqueICareerNamesObject = uniqueCareerArray.map((career: any) => {
      const careerSplit = career.split('-');

      let careerName = '';
      let careerOrder = 0;
      for (let index = 0; index < careerInfo.length; index++) {
        const element = careerInfo[index];
        if (element.code_career === careerSplit[1]) {
          careerOrder = occupationCareerCodesSorted.find( (item: { code: any; order: any }) => element.code_career == item.code ).order;
          careerName = element.name_career;
          break;
        }
      }

      return {
        code_type_institute: careerSplit[0],
        id: careerSplit[1],
        order: careerOrder,
        name: careerName, //careerSplit[2],
      };
    });
    this.career_names = uniqueICareerNamesObject.sort((a,b)=> a.order - b.order);;
  }

  getInformation() {
    this.information = {
      careers: [
        {
          id: 1,
          title: 'Física',
          type: 1,
          studyCenters: [
            {
              title: 'Universidad Nacional de Ingeniería (UNI)',
              url: 'https://www.uni.edu.pe/',
            },
            {
              title: 'Pontificia Universidad Católica del Perú (PUCP)',
              url: 'https://www.pucp.edu.pe/',
            },
          ],
        },
        {
          id: 2,
          title: 'Química',
          type: 1,
          studyCenters: [
            {
              title: 'Universidad Peruana Cayetano Heredia (UPCH)',
              url: 'https://cayetano.edu.pe/cayetano/es/',
            },
            {
              title: 'Universidad Nacional de Ingeniería (UNI)',
              url: 'https://www.uni.edu.pe/',
            },
            {
              title: 'Pontificia Universidad Católica del Perú (PUCP)',
              url: 'https://www.pucp.edu.pe/',
            },
          ],
        },
        {
          id: 3,
          title: 'Ingeniería Química',
          type: 1,
          studyCenters: [
            {
              title: 'Universidad Nacional de Ingeniería (UNI)',
              url: 'https://www.uni.edu.pe/',
            },
            {
              title: 'Universidad de Ingeniería y Tecnología (UTEC)',
              url: 'https://www.utec.edu.pe/',
            },
          ],
        },
        {
          id: 4,
          title: 'Ingeniería Geológica',
          type: 1,
          studyCenters: [
            {
              title: 'Universidad Privada del Norte (UPN)',
              url: 'https://www.upn.edu.pe/',
            },
            {
              title: 'Universidad Nacional de Cajamarca (UNC)',
              url: 'https://www.unc.edu.pe/',
            },
          ],
        },
        {
          id: 5,
          title: 'EDUCACIÓN FÍSICA',
          type: 2,
          studyCenters: [
            {
              title: 'SEJOSÉ CARLOS MARIÁTEGUINATI',
              url: 'https://www.senati.edu.pe/',
            },
            {
              title: 'MARÍA MONTESSORI',
              url: 'https://isphde.edu.pe/',
            },
          ],
        },
        {
          id: 6,
          title:
            'EDUCACIÓN SECUNDARIA, ESPECIALIDAD: CIENCIA, TECNOLOGÍA Y AMBIENTE',
          type: 2,
          studyCenters: [
            {
              title: 'SAN MARCOS',
              url: 'https://www.cetemin.edu.pe/',
            },
          ],
        },
      ],
      cards: [
        {
          title: 'Financiamiento y becas',
          imageUrl: '/assets/img/formacion-academica/becas.jpeg',
          url: '/recursos-desarrollo-laboral/financiamiento-becas',
        },
        {
          title: 'Capacitación laboral',
          imageUrl: '/assets/img/formacion-academica/capacitacion.jpeg',
          url: '/recursos-desarrollo-laboral/capacitacion-laboral',
        },
        {
          title: 'Soporte al emprendimiento',
          imageUrl: '/assets/img/formacion-academica/soporte.jpeg',
          url: '/recursos-desarrollo-laboral/soporte-emprendimiento',
        },
      ],
    };
    this.univCareers = this.information.careers.filter(
      (x: any) => x.type === 1
    );
    this.techCareers = this.information.careers.filter(
      (x: any) => x.type === 2
    );
  }

  redirect(url: string) {
    let download = document.createElement('a');
    download.href = url;
    download.click();
  }
}
