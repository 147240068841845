import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableroComponent } from './tablero.component';
import { TableroRoutingModule } from './tablero-routing.module';
import { SharedModule } from '../shared/shared.module'; 
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { ListadoTableroComponent } from './listado-tablero/listado-tablero.component';
import { DetalleTableroComponent } from './detalle-tablero/detalle-tablero.component';
 
@NgModule({
  declarations: [
    TableroComponent,
    ListadoTableroComponent,
    DetalleTableroComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    TableroRoutingModule 
  ]
})
export class TableroModule { }
