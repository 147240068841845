import { Component, ElementRef, OnInit, ViewChild, Input } from '@angular/core';
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import { UtilService } from 'src/app/services/util.service';
import { TableUtil } from "src/app/services/table.Utils";

@Component({
  selector: 'app-trend-table',
  templateUrl: './trend-table.component.html',
  styleUrls: ['./trend-table.component.scss'],
})
export class TrendTableComponent implements OnInit {
  @Input() tabla_resumen: any;
  @Input() footer_resumen: any;
  @Input() loading: any;
  @Input() primerLevelCode: any;
  description: any;
  source: any;  
  technical_note: any;

  @ViewChild('myContent') myContent: ElementRef<HTMLInputElement> =
    {} as ElementRef;
  information: any;

  constructor(private utilService: UtilService) {
    //this.information = {};
  }

  ngOnInit(): void {
    this.getDataTable();
  }

  async getDataTable() {



    if (this.primerLevelCode === undefined) {
      await new Promise((f) => setTimeout(f, 400));
      this.getDataTable();
      return;
    }
    
    
    if (this.primerLevelCode === undefined) {
      await new Promise((f) => setTimeout(f, 400));
      this.getDataTable();
      return;
    }

    if (this.primerLevelCode ==='0') {
      this.technical_note= `<p align="justify">La información corresponde al número de puestos de trabajo ocupados por asalariados en el sector público. En el cuadro resumen no se muestra información de trabajadores para los que no registraron nivel educativo.</p>`,      
      this.description = 'El cuadro muestra el número de trabajadores asalariados contratados por las entidades e instituciones del sector público por nivel educativo para la ocupación seleccionada, así como algunas características de los trabajadores que se desempeñan en dicha ocupación (Nivel educativo, remuneración promedio mensual (S/), cantidad de trabajadores, edad promedio de trabajadores y participación de mujeres).';
      this.source = 'Ministerio de Trabajo y Promoción del Empleo - Planilla Electrónica (PLAME y T-Registro) Noviembre 2022.';
    } else 
    {
      this.technical_note= `<p align="justify">La información corresponde al número de puestos de trabajo ocupados por asalariados en el sector formal privado. En el cuadro resumen no se muestra información de trabajadores para los que no registraron nivel educativo.</p>`,      
      this.description = 'El cuadro muestra el número de trabajadores asalariados contratados por las empresas del sector privado formal por nivel educativo para la ocupación seleccionada, así como algunas características de los trabajadores que se desempeñan en dicha ocupación (Nivel educativo, remuneración promedio mensual (S/), cantidad de trabajadores, edad promedio de trabajadores y participación de mujeres).';
      this.source = 'Ministerio de Trabajo y Promoción del Empleo - Planilla Electrónica (PLAME y T-Registro) Noviembre 2022.';      
    } 


    this.information = {
      technical_note: this.technical_note,
      id: 'tabla-resumen',
      title: 'Resumen',
      description: this.description,
      source: this.source,

      heads: [
        {
          title: 'Nivel Educativo',
        },
        {
          title: 'Remuneración promedio mensual (S/)',
        },
        {
          title: 'Cantidad de trabajadores',
        },
        {
          title: 'Edad promedio de trabajadores',
        },
        {
          title: 'Participación de mujeres (%)',
        },
      ],
      footers: [
        {
          title: 'Total general',
        },
        {
          title: '3,200',
        },
        {
          title: '914,454',
        },
        {
          title: '40',
        },
        {
          title: '33%',
        },
      ],
    };
    this.information.footers = this.footer_resumen.footers;
  }

  downloadImage() {
    htmlToImage
      .toPng(this.myContent?.nativeElement, { backgroundColor: 'white' })
      .then(function (dataUrl) {
        var img = new Image();
        img.src = dataUrl;
        const encodedUri = encodeURI(img.src);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'tabla_tendencias');
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  }

  // downloadCsv() {
  //   let csv:any = [];
  //   this.tabla_resumen.forEach((x: any) => {
  //     csv.push({
  //       "periodo": x.period,
  //       "nivel_educativo": x.name,
  //       "sueldo_promedio": x.sueldo_promedio,
  //       "total_trabajadores": x.total_trabajadores,
  //       "edad_promedio": x.edad_promedio,
  //       "tasa_mujeres": x.tasa_mujeres
  //     });
  //   });
  //   this.utilService.downloadCsvFile(csv, this.information.title);
  // }

  downloadExcel() {
    let arrayForXls: any = [];
    this.tabla_resumen.forEach((x: any) => {
      arrayForXls.push({
        "Periodo": x.period,
        "Nivel Educativo": x.name,
        "Remuneración promedio mensual (S/)": Number(x.sueldo_promedio),
        "Cantidad de trabajadores": Number(x.total_trabajadores),
        "Edad promedio de trabajadores": Number(x.edad_promedio),
        "Participación de mujeres (%)": Number(x.tasa_mujeres)
      });      
    });

    TableUtil.exportArrayToExcel(arrayForXls, this.information.title);
  }  

}
