<div class="mt-4">
    <div class="row">
      <div class="col-4">
      </div>
      <div class="col-2">
        <p class="fw-bold mb-0 pb-2">Filtrar por región</p>
        <select class="form-select" (change)="onChangeRegion($event)">
          <!-- <option value="-1" selected disabled>Filtrar por región</option> -->
          <option value="0">Todos</option>
          <option *ngFor="let region of occupationRegions" [value]="region.id" [selected]="region.id==regionSelected"> {{ region.name }} </option>
        </select>
      </div>
  
      <div class="col-3">
        <p class="fw-bold mb-0 pb-2">Filtrar por nivel de educación</p>
        <select class="form-select" (change)="onChangeEducation($event)">
          <!-- <option value="-1" selected disabled>
            Filtrar por nivel de educación
          </option> -->
          <option value="0">Todos</option>
          <option *ngFor="let educationLevel of occupationEducationLevels" [value]="educationLevel.id" [selected]="educationLevel.id==educationSelected"> {{ educationLevel.name }} </option>
        </select>
      </div>
  
      <div class="col-3">
        <p class="fw-bold mb-0 pb-2">Filtrar por grupo de edad</p>
        <select class="form-select" (change)="onChangeEdad($event)">
          <option value="0">Todos</option>
          <option *ngFor="let grupoEdad of occupationGrupoEdads" [value]="grupoEdad.id" [selected]="grupoEdad.id==edadSelected"> {{ grupoEdad.name }} </option>
        </select>
      </div>    
    </div>
    <div class="row mt-4">
      <div class="mb-4 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
        <app-trend-table [loading]="loading" [tabla_resumen]="tabla_resumen" [footer_resumen]="footer_resumen"></app-trend-table>
      </div>
      <!-- <div class="mb-4 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
        <app-detail-salary-trend [loading]="loading" [remuneracion_mensual]="remuneracion_mensual">
        </app-detail-salary-trend>
      </div> -->
      <div class="mb-4 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
        <app-detail-employee-trend [loading]="loading" [tendencia_trabajadores]="tendencia_trabajadores">
        </app-detail-employee-trend>
      </div>



      <!-- NO VA <div class="mb-4 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
        <app-detail-hiring-companies [loading]="loading" [empresas_contratan]="empresas_contratan">
        </app-detail-hiring-companies>
      </div> -->


      <div class="mb-4 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
        <app-detail-employee-variations [loading]="loading" [entradas_salidas]="entradas_salidas">
        </app-detail-employee-variations>
      </div>    
      <div class="mb-4 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
        <app-detail-offers-variation [loading]="loading" [oferta_variacion]="oferta_variacion">
        </app-detail-offers-variation>
      </div>
      <div class="mb-4 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-7">
        <app-detail-economic-activity [loading]="loading" [actividad_economica]="actividad_economica">
        </app-detail-economic-activity>
      </div>
      <div *ngIf="mapa_calor && mapa_calor[0].data.length > 0 && mapa_calor[1].data.length > 0"
        class="mb-4 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-5">
        <app-detail-charts-heat [mapa_calor]="mapa_calor" [periodReference]="periodReference"></app-detail-charts-heat>
      </div>
  
      <div style="font-size: 12px;">
        <p><strong>Notas metodológicas:</strong></p>
        <p align="justify">Información basada en la Planilla Mensual de Pagos (PLAME) y T-Registro de Planilla Electrónica del MTPE. En el sector formal privado se entiende por trabajador privado a toda persona natural que presta servicios a un empleador bajo una relación de subordinación, sujeto a cualquier régimen laboral de la actividad privada, cualquiera sea la modalidad del contrato de trabajo. Considera a puestos de trabajo declarados una sola vez en una empresa, pertenecientes a renta de quinta categoría del sector privado; no incluye personal de modalidades formativas laborales; pensionistas; personal independiente con renta de cuarta categoría (locadores de servicios); y aquellos con suspensión perfecta durante todo el mes y además remuneración igual a cero.</p>
        <p align="justify">En el caso de los trabajadores del sector público, este grupo se conforma de puestos de trabajo registrados en la Planilla Mensual de Pagos (PLAME) y T-Registro de Planilla Electrónica del MTPE de entidades o instituciones que registraron tener un tipo de administración pública y pública-privada.</p>
        <p align="justify">La remuneración promedio del sector privado formal se refiere a las remuneraciones declaradas por el empleador en la Planilla Electrónica declaradas. Se considera a las remuneraciones de trabajadores percibidas por renta de quinta categoría y correspondientes al monto pagado por conceptos permanentes. La remuneración promedio del sector público se refiere monto pagado por conceptos pensionarios, no pensionarios y por Comité de Administración de Fondos de Asistencia y Estímulo (CAFAE).</p>    
        <p align="justify">La información de entradas y salidas corresponde a la comparación del mes actual respecto del mes anterior. En el caso de la entrada se refiere al número de puestos de trabajo que comenzaron (o volvieron) a registrarse en el sector formal privado; mientras que, las salidas se refieren a aquellos que dejaron de registrarse. Cabe resaltar que la entrada y salida en el sector formal privado no implica el ingreso o la salida de la formalidad, debido estos indicadores están sujetos a la declaración del empleador en Planilla Electrónica con fechas de corte.</p>
        <p align="justify">La variación porcentual reportada corresponde al mes actual respecto al mismo mes del año anterior.</p>
        <p align="justify">La información del perfil de la ocupación corresponde al “Clasificador Nacional de Ocupaciones 2015” (Basado en la Clasificación Internacional Uniforme de Ocupaciones: CIUO - 2008), desagregadas a nivel de grupo primario (4 dígitos).</p>      
        <p><strong>Fuente: </strong>MTPE - Planilla Electrónica (PLAME y T-Registro).</p>
        <p><strong>Elaboración: </strong>MTPE - DGPE - Dirección de Investigación Socio Económico Laboral (DISEL).</p>
      </div> 
    </div>
  </div>
  