<div class="row mt-4">
  <p class="p-select text-secondary fs-16">
    Selecciona la base de microdatos de interés y como máximo 3 períodos que
    desea descargar:
  </p>
</div>

<div class="row col-12">
  <p class="col-sm-2 col-form-label fw-bold">Base de Datos</p>
  <div class="pt-2 pb-2 col-sm-10">
    <div class="col-10 col-lg-5 col-md-10">
      <select class="form-select" (change)="selectTitle($event)">
        <option *ngFor="let title of titles" [value]="title.id">
          {{ title.name }}
        </option>
      </select>
    </div>
  </div>
</div>

<div class="row col-12">
  <p class="col-sm-2 col-form-label fw-bold">Períodos</p>
  <div class="pt-2 pb-2 col-sm-10">
    <span class="me-2" *ngFor="let period of periods">
      <input
        (click)="periodSelection(period)"
        [type]="'checkbox'"
        class="btn-check"
        [checked]="periodsSelection.includes(period)"
        [id]="'btn-check-' + period + '-' + titleSelected"
        [disabled]="
          periodsSelection.length === 3 && !periodsSelection.includes(period)
            ? 'disabled'
            : null
        "
        autocomplete="off"
      />
      <label
        [ngClass]="
          periodsSelection.includes(period)
            ? 'btn btn-outline-warning mb-2'
            : 'btn btn-outline-primary mb-2'
        "

        [for]="'btn-check-' + period + '-' + titleSelected"
        >{{ period }}</label
      ></span
    >
  </div>
</div>

<div class="row col-12">
  <p class="col-sm-2 col-form-label fw-bold">Formato</p>
  <div class="pt-2 pb-2 col-sm-10">
    <span class="me-2" *ngFor="let format of formats">
      <input
        (click)="formatSelection(format)"
        type="checkbox"
        class="btn-check"
        [id]="'btn-check-' + format"
        autocomplete="off"
      />
      <label
        class="btn btn-outline-secondary"
        [for]="'btn-check-' + format"
        [ngClass]="
          formatsSelection.indexOf(format)!=-1
            ? 'btn btn-outline-warning'
            : 'btn btn-outline-secondary'
        "
      >
        {{ format }}</label
      ></span
    >
  </div>
</div>

<div class="mt-5">
  <button
    [ngClass]="enableDownload ? 'btn-primary' : 'disabled'"
    (click)="download()"
    class="btn me-2"
  >
    <mat-icon
      [ngClass]="enableDownload ? 'text-inverted' : 'text-secondary'"
      svgIcon="file-download-outline"
    ></mat-icon>
    Descargar archivos seleccionados
  </button>
</div>
