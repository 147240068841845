import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
})
export class SubscriptionComponent implements OnInit, OnDestroy {
  @ViewChild('subscribeInput') subscribeInput: any;

  email: string = '';
  loading: boolean;
  showError: boolean;
  subscriptionBanners: any[];
  routerSubscription: Subscription;
  activeBanner: any;
  strapiUrl: string;
  isSubscribed: boolean;

  constructor(private apiService: ApiService, private router: Router) {
    this.loading = true;
    this.showError = false;
    this.subscriptionBanners = [];
    this.routerSubscription = Subscription.EMPTY;
    this.activeBanner = {};
    this.strapiUrl = environment.strapiUrl;
    this.isSubscribed = false;
  }

  ngOnInit(): void {
    this.getSubscriptionBanners();
  }

  async getSubscriptionBanners() {
    this.subscriptionBanners = await this.apiService.getMany(
      '/subscription-banners'
    );
    this.setCurrentPage(this.router.url);
    this.loading = false;

    this.routerSubscription = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.isSubscribed = false;
        this.email = '';
        this.showError = false;
        setTimeout(() => (this.subscribeInput.nativeElement.value = ''), 100);
        this.setCurrentPage(event.url);
      }
    });
  }

  setCurrentPage(url: string) {
    if (url.includes('/recursos/')) {
      this.activeBanner = this.subscriptionBanners.find((b: any) => b.id === 2);
    } else if (url.includes('/panorama-laboral')) {
      this.activeBanner = this.subscriptionBanners.find((b: any) => b.id === 3);
    } else {
      this.activeBanner = this.subscriptionBanners.find((b: any) => b.id === 1);
    }
  }

  onInputChange($event: any) {
    if ($event.target.value === '') {
      this.email = '';
      return;
    }
    if (/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test($event.target.value)) {
      this.showError = false;
      this.email = $event.target.value;
    } else {
      this.showError = true;
      this.email = '';
    }
  }

  async subscribe() {
    let request = { email: this.email, origin_url: this.router.url };
    let response: any = await this.apiService.post('/subscriptions', request);
    if (response.id) {
      this.activeBanner = this.subscriptionBanners.find((b: any) => b.id === 4);
      this.isSubscribed = true;
    }
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }
}
