<app-jumbotron [information]="jumbotron"></app-jumbotron>
<div class="container">
  <div class="row">
    <div class="mb-5 col-sm-12 col-xl-3">
      <div class="sidebar-heading text-start fw-bold mt-4 mb-4">
        Sobre estudios
      </div>
      <div class="list-group">
        <a
          *ngFor="let category of categories.studiesCategories"
          (click)="loadComponent(category.id)"
          [routerLink]="category.url"
          class="list-group-item list-group-item-action"
          [ngClass]="category.id === currentCategory ? 'active' : ''"
          >{{ category.title }}</a
        >
      </div>
      <div class="sidebar-heading text-start fw-bold mt-4 mb-4">
        Sobre Modalidades formativas laborales
      </div>
      <div class="list-group">
        <a
          *ngFor="let category of categories.laborTraningModalitiesCategories"
          (click)="loadComponent(category.id)"
          [routerLink]="category.url"
          class="list-group-item list-group-item-action p-2"
          [ngClass]="category.id === currentCategory ? 'active' : ''"
          >{{ category.title }}</a
        >
      </div>
      <div class="sidebar-heading text-start fw-bold mt-4 mb-4">
        Sobre empleos
      </div>
      <div class="list-group">
        <a
          *ngFor="let category of categories.jobsCategories"
          (click)="loadComponent(category.id)"
          [routerLink]="category.url"
          class="list-group-item list-group-item-action"
          [ngClass]="category.id === currentCategory ? 'active' : ''"
          >{{ category.title }}</a
        >
      </div>
      <div class="sidebar-heading text-start fw-bold mt-4 mb-4">
        Sobre emprendimiento
      </div>
      <div class="list-group">
        <a
          *ngFor="let category of categories.entreCategories"
          (click)="loadComponent(category.id)"
          [routerLink]="category.url"
          class="list-group-item list-group-item-action"
          [ngClass]="category.id === currentCategory ? 'active' : ''"
          >{{ category.title }}</a
        >
      </div>
      <select class="form-select">
        <option value="" selected>Seleccione</option>
        <option
          *ngFor="let category of categories.studiesCategories"
          [value]="category.title"
        >
          {{ category.title }}
        </option>
        <option disabled>──────────</option>
        <option
          *ngFor="let category of categories.laborTraningModalitiesCategories"
          [value]="category.title"
        >
          {{ category.title }}
        </option>
        <option disabled>──────────</option>
        <option
          *ngFor="let category of categories.jobsCategories"
          [value]="category.title"
        >
          {{ category.title }}
        </option>
        <option disabled>──────────</option>
        <option
          *ngFor="let category of categories.entreCategories"
          [value]="category.title"
        >
          {{ category.title }}
        </option>
      </select>
    </div>
    <div class="mb-5 col-sm-12 col-xl-9">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
