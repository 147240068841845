<app-jumbotron [information]="jumbotron"></app-jumbotron>
<div class="container">
  <app-resources-search></app-resources-search>
  <ul class="mt-4 nav nav-tabs">
    <li class="nav-item">
      <button class="nav-link" routerLink="/recursos/publicaciones" routerLinkActive="active"
        data-bs-toggle="tab" data-bs-target="#publications" type="button" role="tab"
        aria-controls="publications" aria-selected="true">
        Publicaciones
      </button>
    </li>
    <li class="nav-item">
      <button class="nav-link" routerLink="/recursos/microdatos" routerLinkActive="active"
        data-bs-toggle="tab" data-bs-target="#microdata" type="button" role="tab"
        aria-controls="microdata" aria-selected="true">
        Microdatos
      </button>
    </li>
    <!-- <li class="nav-item">
      <button class="nav-link" routerLink="/recursos/observatorios-regionales" routerLinkActive="active"
        data-bs-toggle="tab" data-bs-target="#regionales" type="button" role="tab"
        aria-controls="regionales" aria-selected="true">
        Observatorios Regionales
      </button>
    </li> -->
  </ul>
  <div class="tab-content" id="myTabContent">
    <router-outlet></router-outlet>
  </div>
</div>
