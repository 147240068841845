<div class="card chart-card">
  <div class="card-body">
    <div>
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <h4>{{ information.title }}</h4>
          <p>{{ information.description }}</p>
        </div>

        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
          <button
            class="btn btn-sm btn-link text-secondary fs-14"
            (click)="downloadExcel()"
          >
            <mat-icon svgIcon="file-download-outline"></mat-icon>
            Descargar datos
          </button>

          <button
            class="btn btn-sm btn-link text-secondary fs-14"
            (click)="downloadImage()"
          >
            <mat-icon svgIcon="image-outline"></mat-icon>
            Guardar imagen
          </button>

          <button
            class="btn btn-sm btn-link text-secondary fs-14"
            style="padding-right: 0px"
            data-bs-toggle="modal"
            [attr.data-bs-target]="'#modal-' + information.id"
          >
            <mat-icon svgIcon="note-text-outline"></mat-icon>
            Ver nota técnica
          </button>
        </div>
      </div>

      <div class="row">
        <div class="my-2">
          <button
            *ngFor="let data of mapa_calor; let i = index"
            class="btn btn-sm m-2"
            [ngClass]="
              map_position === i ? 'btn-primary' : 'btn-outline-primary'
            "
            (click)="changePosition(i)"
          >
            {{ data.title }}
          </button>
        </div>
      </div>
      <app-loading *ngIf="loading"></app-loading>
      <div id="heatmap-content"></div>
      <div class="mt-2">
        <mat-icon class="icon-color" svgIcon="information"></mat-icon>
        <span class="source">Fuente: {{ information.source }}</span>
        <br>
        <span class="source">Período referencia: {{ information.periodReference }}</span>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  [id]="'modal-' + information.id"
  tabindex="-1"
  data-bs-backdrop="static"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Nota técnica</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body" [innerHTML]="information.technical_note"></div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary view-custom"
          data-bs-dismiss="modal"
        >
          Cerrar
        </button>
      </div>
    </div>
  </div>
</div>
