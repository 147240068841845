<h2>Components</h2>
<div class="row">
  <div class="col-2">
    <select class="form-select">
      <option value="1">2001</option>
    </select>
  </div>
</div>

<div class="col-12 search-bar">
  <input type="search" class="form-control" placeholder="Placeholder" />
  <button class="btn btn-primary" type="button">
    <mat-icon class="text-inverted" svgIcon="magnify"></mat-icon>
  </button>
</div>

<h2>Navigation</h2>
<br />
<h5>Horizontal navigation</h5>
<div>
  <ul class="nav nav-tabs">
    <li class="nav-item">
      <button
        class="nav-link active"
        data-bs-toggle="tab"
        data-bs-target="#publications"
        type="button"
        role="tab"
        aria-controls="publications"
        aria-selected="true"
      >
        Publicaciones
      </button>
    </li>
    <li class="nav-item">
      <button
        class="nav-link"
        data-bs-toggle="tab"
        data-bs-target="#microdata"
        type="button"
        role="tab"
        aria-controls="microdata"
        aria-selected="true"
      >
        Microdatos
      </button>
    </li>
    <li class="nav-item">
      <button
        class="nav-link"
        data-bs-toggle="tab"
        data-bs-target="#regionales"
        type="button"
        role="tab"
        aria-controls="regionales"
        aria-selected="true"
      >
        Observatorios Regionales
      </button>
    </li>
  </ul>
</div>

<br /><br />
<h5>Vertical navigation</h5>
<div class="row">
  <div class="mb-5 col-sm-12 col-xl-3">
    <div class="list-group">
      <a class="list-group-item list-group-item-action active"
        >Active element
      </a>
      <a class="list-group-item list-group-item-action">Element 1</a>
      <a class="list-group-item list-group-item-action">Element 2</a>
    </div>
  </div>
  <div class="mb-5 col-sm-12 col-xl-9">Content</div>
</div>

<h5>Breadcrumbs</h5>
<div class="mb-4 mt-4">
  <a class="breadcrumb-link" routerLink="/">Inicio</a> >
  <a class="breadcrumb-link" routerLink="/descubre">Descubre ocupaciones</a> >
  <span class="text-secondary">Físicos, químicos y afines</span>
</div>
