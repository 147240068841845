<!--<div class="alert alert-light text-center mt-4" role="alert" *ngIf="loading">
  <h4 class="alert-heading">Loading...</h4>
  <p><i class="fa fa-sync fa-spin fa-2x"></i></p>
  <p class="mb-0"></p>
</div>
-->

<div *ngIf="occupation !== null">
  <div class="mb-4 mt-4">
    <a class="breadcrumb-link" routerLink="/">Inicio</a> >
    <a class="breadcrumb-link" routerLink="/descubre">Descubre ocupaciones</a> >
    <span *ngIf="!isFour && occupationFour !== undefined"
      ><a
        class="breadcrumb-link"
        [href]="'/descubre/' + occupationFour.id + '/detail'"
        >{{ occupationFour !== null ? occupationFour.name : "" }}</a
      >
      >
    </span>
    <span class="fs-12">{{ occupation !== null ? occupation.name : "" }}</span>
  </div>
  <div
    class="p-4 mb-4 bg-light"
    [style]="
      'background-image: url(' +
      strapiUrl +
      (secondLevel !== null ? secondLevel.occupation_one.image.url : '') +
      ')'
    "
  >
    <div class="container py-1">
      <div class="text-left mb-4">
        <a
          routerLink="/descubre"
          style="text-decoration: none"
          class="text-inverted"
        >
          <mat-icon svgIcon="arrow-left"></mat-icon>
          Volver a categorías de ocupaciones
        </a>
      </div>
      <div class="mb-4">
        <span
          class="badge text-inverted"
          style="background-color: #0056ac !important"
        >
          {{ secondLevel !== null ? secondLevel.name : "" }}
        </span>
      </div>
      <div class="mb-5">
        <h1 class="fw-bold text-inverted">
          {{ occupation !== null ? occupation.name : "" }}
        </h1>
        <hr
          class="text-inverted"
          style="width: 55px; border: 2px solid; opacity: 1"
        />
      </div>
      <div class="row" *ngIf="showIndicators">
        <div
          class="
            col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3
            text-center
            mb-2
          " style="color: aliceblue;"
          *ngFor="let indicator of information.indicators"
        >
          <div>
            <span style="color: aliceblue;">{{ indicator.title }}</span>
          </div>
          <div>
            <h2>{{ indicator.value }}</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
