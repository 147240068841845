import { Injectable } from '@angular/core';
import * as xml2js from 'xml2js';

// @ts-ignore
import * as savepng from 'save-svg-as-png';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor() { }

  downloadPngFile(svg: any, filename: string) {
    let options = {};
    savepng.saveSvgAsPng(svg.node(), filename, options);
  }

  downloadCsvFile(json: any, filename: string) {
    const fields = Object.keys(json[0]);
    const replacer = function (key: any, value: any) {
      return value === null ? '' : value
    };
    let csv = json.map(function (row: any) {
      return fields.map(function (fieldName) {
        return JSON.stringify(row[fieldName], replacer)
      }).join(',')
    });
    csv.unshift(fields.join(','))
    csv = csv.join('\r\n');
    const csvContent = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csv);
    const link = document.createElement("a");
    link.setAttribute("href", csvContent);
    link.setAttribute("download", filename + ".csv");
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  xmlToJson(xml: any) {
    let obj: any = {};
    let parser = new xml2js.Parser();
    parser.parseString(xml, function (err: any, result: any) {
      let json = JSON.stringify(result);
      json = json.replace(/\message:/g, '');
      json = json.replace(/\generic:/g, '');
      json = json.replace(/\common:/g, '');
      json = json.replace('common:', '');
      obj = JSON.parse(json);
    });
    return obj;
  }

  getDepartments() {
    const departments: any[] = [
      {
        "id": 1,
        "name": "Amazonas"
      },
      {
        "id": 2,
        "name": "Áncash"
      },
      {
        "id": 3,
        "name": "Apurímac"
      },
      {
        "id": 4,
        "name": "Arequipa"
      },
      {
        "id": 5,
        "name": "Ayacucho"
      },
      {
        "id": 6,
        "name": "Cajamarca"
      },
      {
        "id": 7,
        "name": "Callao"
      },
      {
        "id": 8,
        "name": "Cusco"
      },
      {
        "id": 9,
        "name": "Huancavelica"
      },
      {
        "id": 10,
        "name": "Huánuco"
      },
      {
        "id": 11,
        "name": "Ica"
      },
      {
        "id": 12,
        "name": "Junín"
      },
      {
        "id": 13,
        "name": "La Libertad"
      },
      {
        "id": 14,
        "name": "Lambayeque"
      },
      {
        "id": 15,
        "name": "Lima"
      },
      {
        "id": 16,
        "name": "Loreto"
      },
      {
        "id": 17,
        "name": "Madre de Dios"
      },
      {
        "id": 18,
        "name": "Moquegua"
      },
      {
        "id": 19,
        "name": "Pasco"
      },
      {
        "id": 20,
        "name": "Piura"
      },
      {
        "id": 21,
        "name": "Puno"
      },
      {
        "id": 22,
        "name": "San Martín"
      },
      {
        "id": 23,
        "name": "Tacna"
      },
      {
        "id": 24,
        "name": "Tumbes"
      },
      {
        "id": 25,
        "name": "Ucayali"
      }
    ];
    return departments;
  }
}
