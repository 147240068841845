<app-loading *ngIf="loading"></app-loading>
<div class="mb-4" *ngIf="!loading">
  <h4 class="fw-bold">Indicador</h4>
  <div class="mt-4 text-center" *ngIf="error">
    <h5 class="text-attention">No se pudo obtener la lista de indicadores</h5>
  </div>
  <div class="mt-4" *ngIf="!error">
    <select (change)="onChange($event)"
      class="form-select rounded-0" [(ngModel)]="selectedSubGroup">
      <option *ngFor="let option of information.data" [value]="option.id">{{option.name}}</option>
    </select>
  </div>
</div>
