<app-loading *ngIf="loading"></app-loading>
<div *ngIf="!loading">
  <div class="mt-4 mb-4">
    <h3 class="fw-bold text-attention">{{ information.title }}</h3>
  </div>
  <div>
    <div [innerHTML]="information.description"></div>
  </div>
  <div class="mt-5 nav nav-tabs tabs-overflow" id="nav-tab" role="tablist">
    <button
      *ngFor="let tab of tabs; let i = index"
      [ngClass]="{ active: i === 0 }"
      class="nav-link fw-bold text-secondary"
      id="nav-{{ tab.icon }}-tab"
      data-bs-toggle="tab"
      [attr.data-bs-target]="'#nav-' + tab.icon"
      type="button"
      role="tab"
      [attr.aria-controls]="'nav-' + tab.icon"
      aria-selected="false"
    >
      <span class="iconify" [attr.data-icon]="'mdi:' + tab.icon"></span> <br />
      {{ tab.title }}
    </button>
  </div>
  <div class="tab-content pt-4" id="nav-tabContent">
    <div
      *ngFor="let tab of tabs; let i = index"
      [ngClass]="{ 'show active': i === 0 }"
      class="tab-pane fade"
      id="nav-{{ tab.icon }}"
      role="tabpanel"
      [attr.aria-labelledby]="'nav-' + tab.icon + '-tab'"
    >
      <div class="mt-4">
        <h4>{{ tab.title }}</h4>
        <div [innerHTML]="tab.description"></div>
      </div>
      <div class="row">
        <div
          class="col-lg-4 col-md-4 col-12 col-sm-12"
          *ngFor="let banner of tab.banner_urls"
        >
          <div class="image-container">
            <img
              class="lazyload"
              [attr.data-src]="imageBase + '/' + banner.image[0].url"
              alt=""
            />
            <a *ngIf="banner.url.substring(0,4) === 'http'"
              class="centered text-inverted col-12 col-sm-12"
              href="{{ banner.url }}"
              target="_blank"
              rel="noopener"
              >{{ banner.title
              }}&nbsp;<mat-icon class="icon-color-p" svgIcon="open-in-new"> </mat-icon
            ></a>
            <a *ngIf="banner.url.substring(0,4) !== 'http'"
              class="centered text-inverted col-12 col-sm-12"
              routerLink="{{ banner.url }}"
              rel="noopener"
              >{{ banner.title
              }}&nbsp;<mat-icon class="icon-color-p" svgIcon="open-in-new"> </mat-icon
            ></a>            
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
