import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-outlook-indicator-detail',
  templateUrl: './indicator-detail.component.html',
  styleUrls: ['./indicator-detail.component.scss']
})
export class IndicatorDetailComponent implements OnInit, OnDestroy {

  loading: boolean;
  code: string;
  title: string;
  paramsSubscription: Subscription;
  baseGeneral: string = "SLN,TSF,ESP";

  constructor(private router: ActivatedRoute) {
    this.loading = true;
    this.code = "";
    this.title = "";
    this.paramsSubscription = Subscription.EMPTY;
  }

  ngOnInit(): void {
    this.paramsSubscription = this.router.params.subscribe(({ code }) => {
      this.code = code;
      switch (this.code) {
        case 'SLN':
          this.title = "Situación Laboral Nacional";
          break;
        case 'TSF':
          this.title = "Trabajadores del Sector Formal Privado";
          break;
        case 'ESP':
          this.title = "Empresas del Sector Formal Privado";
          break;
        case 'ODE':
          this.title = "Oportunidades de empleo";
          break;
        case 'ITP':
          this.title = "Ingresos adecuados y trabajo productivo";
          break;
        case 'HTD':
          this.title = "Horas de trabajo decente";
          break;
        case 'CON':
          this.title = "Contexto";
          break;
        case 'SES':
          this.title = "Seguridad Social";
          break;
        case 'IOT':
          this.title = "Igualdad de oportunidades y de trato";
          break;
        case 'DSR':
          this.title = "Diálogo social y representación";
          break;
        default:
          this.title = "";
      }
      this.loading = false;
    });
  }

  isBaseGeneral(code: string): boolean {
    return this.baseGeneral.includes(code);
  }

  ngOnDestroy(): void {
    this.paramsSubscription.unsubscribe();
  }
}
