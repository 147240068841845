import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-osel-region',
  templateUrl: './region.component.html',
  styleUrls: ['./region.component.scss'],
})
export class RegionComponent implements OnInit, OnDestroy {

  loading: boolean;
  paramsSubscription: Subscription;
  slug: any;
  information: any;
  publication: any;
  source: any;
  categories: any[];
  categoriesSource: any[];
  uniqueCategories: any[];
  uniqueSourceCategories: any[];
  uniqueYears: any[];
  uniqueYearsSource: any[];

  constructor(private router: ActivatedRoute, private apiService: ApiService) {
    this.loading = true;
    this.paramsSubscription = Subscription.EMPTY;
    this.slug = '';
    this.information = {};
    this.publication = {};
    this.source = {};
    this.categories = [];
    this.categoriesSource = [];
    this.uniqueCategories = [];
    this.uniqueSourceCategories = [];
    this.uniqueYears = [];
    this.uniqueYearsSource = [];
  }

  ngOnInit(): void {
    this.paramsSubscription = this.router.params.subscribe(({ slug }) => {
      this.slug = slug;

      this.getRegionInformation(slug);
    });
  }

  async getRegionInformation(slug: any) {
    let items = await this.apiService.getMany('/osel-regions?id=' + slug);
    let publications = await this.apiService.getMany(
      '/osel-publicaciones?osel_region.id=' + slug + '&_limit=500'
    );
    let informationSources = await this.apiService.getMany(
      '/osel-information-sources?osel_region.id=' + slug + '&_limit=500'
    );

    let listCategories = publications.map((publication: any) => {
      return {
        title: publication.osel_publicaciones_type.title,
        id: publication.osel_publicaciones_type.id,
      };
    });
    let listCategoriesSource = informationSources.map((source: any) => {
      return {
        title: source.osel_information_source_type.title,
        id: source.osel_information_source_type.id,
      };
    });
    let listyears = publications.map((publication: any) => {
      return { year: publication.year, id: publication.id };
    });
    let listyearsSource = informationSources.map((source: any) => {
      return { year: source.year, id: source.id };
    });

    this.uniqueYears = [...new Set(listyears.map((item: any) => item.year))];

    this.uniqueYearsSource = [
      ...new Set(listyearsSource.map((item: any) => item.year)),
    ];

    this.uniqueCategories = [
      ...new Map(
        listCategories.map((item: any) => [item['id'], item])
      ).values(),
    ];

    this.uniqueSourceCategories = [
      ...new Map(
        listCategoriesSource.map((item: any) => [item['id'], item])
      ).values(),
    ];

    this.information = {
      results: items,
    };
    this.publication = {
      results: publications,
    };
    this.source = {
      results: informationSources,
    };
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.paramsSubscription.unsubscribe();
  }
}
