import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-labor-rights',
  templateUrl: './labor-rights.component.html',
  styleUrls: ['./labor-rights.component.scss']
})
export class LaborRightsComponent implements OnInit {

  header: any;
  tabs: any[];
  loading: boolean;
  strapiUrl: string;

  constructor(private apiService: ApiService) {
    this.header = {};
    this.tabs = [];
    this.loading = true;
    this.strapiUrl = environment.strapiUrl;
  }

  ngOnInit(): void {
    this.getInformation();
  }

  async getInformation() {
    this.header = await this.apiService.getOne('/job-developments?code=derechos-laborales');
    this.tabs = await this.apiService.getMany('/labor-rights');
    this.loading = false;
  }
}
