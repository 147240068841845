import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { environment } from '../../../../environments/environment';
import { PaginationService } from '../../../services/pagination.service';

@Component({
  selector: 'app-publications-annual',
  templateUrl: './publications-annual-report.component.html',
  styleUrls: ['./publications-annual-report.component.scss'],
})
export class PublicationsAnnualComponent implements OnInit, OnDestroy {
  paramsSubscription: Subscription;
  publicationTypeId: any;

  headers: any;
  publications: any[];
  nationalPublications: any[];
  publicationsAvailable: any[];
  

  strapiUrl: string;

  totalPublications: number;
  pagesList: number[];
  currentPage: number;
  cardsPerPage: number;

  //Periodos 2022
  periods: any;
  periodsSelection: any;
  information: any;
  currentPublication: string;

  constructor(
    private router: ActivatedRoute,
    private apiService: ApiService,
    private paginationService: PaginationService
  ) {
    this.paramsSubscription = Subscription.EMPTY;
    this.publicationTypeId = '';

    this.headers = null;
    this.publications = [];
    this.nationalPublications =[];
    this.publicationsAvailable =[];

    this.strapiUrl = environment.strapiUrl;

    this.totalPublications = 0;
    this.pagesList = [];
    this.currentPage = 1;
    this.cardsPerPage = 16;

    //
    this.periods = [];
    this.periodsSelection = [];
    this.information = {};
    this.currentPublication = 'Todos';    

  }

  ngOnInit(): void {
    this.paramsSubscription = this.router.params.subscribe(
      ({ publicationTypeId }) => {
        this.publicationTypeId = publicationTypeId;

        this.getPublicationsData(publicationTypeId);
        this.getTotalPublications();
      }
    );
  }

  getActualPublication(currentPublication: string) {
    this.currentPublication = currentPublication;
    this.getTotalPublications();
  }



  async getPublicationsData(publicationTypeId: any) {
    let publicationType = await this.apiService.getMany(
      `/publications-types/${publicationTypeId}`
    );

    //console.log("publicationType");
    //console.log(publicationType);  

    //Periodos inicio
    this.publicationsAvailable = await this.apiService.getMany(
      `/publications?publications_type.id=${this.publicationTypeId}&_limit=-1`
    );

    //console.log("publicationsAvailable");
    //console.log(this.publicationsAvailable); 
    let yearsAvailable = this.publicationsAvailable.map(
      (publication: any) => publication.year
    );    
    //console.log("yearsAvailable");
    //console.log(yearsAvailable); 

    let uniquePeriods = [...new Set(yearsAvailable)];

    uniquePeriods.sort((a, b) => {
      return Number(b) - Number(a);
    });
    //console.log("uniquePeriods");
    //console.log(uniquePeriods); 
    this.periods = uniquePeriods;
    this.periods.unshift("Todos");

    //fin

    this.headers = publicationType;

 
  }

  async getTotalPublications() {
    this.currentPage = 1;
    if(this.currentPublication == 'Todos'){
      this.totalPublications = await this.apiService.getMany(
        `/publications/count?publications_type.id=${this.publicationTypeId}`
      );
         
    }else {
      this.totalPublications = await this.apiService.getMany(
        `/publications/count?publications_type.id=${this.publicationTypeId}&year=${this.currentPublication}`
      );
    }

    //console.log("this.totalPublications : " + this.totalPublications)

    this.paginationLogic();
  }

  OnPaginate(page: number) {
    this.currentPage = page;
    this.paginationLogic();
  }

  async paginationLogic() {
    let result = this.paginationService.paginate(
      this.totalPublications,
      this.currentPage,
      this.cardsPerPage
    );
    //console.log(result) ;
 
    //publicationTypeId == 5 corresponde a Informe de Demanda de Ocupaciones
    //Crear un campo adicional de Orden en la publicacion para estos casos (0:Sin Orden)

    if (this.publicationTypeId == 5) {
      if(this.currentPublication == 'Todos'){
        this.nationalPublications = await this.apiService.getMany(
          `/publications?publications_type.id=${this.publicationTypeId}&title_contains=NACIONAL&_sort=year:DESC,month:DESC&_start=${result.startIndex}&_limit=${result.pageSize}`
        );
        this.publications = await this.apiService.getMany(
          `/publications?publications_type.id=${this.publicationTypeId}&title_ncontains=NACIONAL&_sort=year:DESC,month:DESC&_start=${result.startIndex}&_limit=${result.pageSize}`
        );
           
      }else {
        this.nationalPublications = await this.apiService.getMany(
          `/publications?publications_type.id=${this.publicationTypeId}&title_contains=NACIONAL&year=${this.currentPublication}&_sort=year:DESC,month:DESC&_start=${result.startIndex}&_limit=${result.pageSize}`
        );
        this.publications = await this.apiService.getMany(
          `/publications?publications_type.id=${this.publicationTypeId}&title_ncontains=NACIONAL&year=${this.currentPublication}&_sort=year:DESC,month:DESC&_start=${result.startIndex}&_limit=${result.pageSize}`
        );
      }    
    } else{
      if(this.currentPublication == 'Todos'){
        this.publications = await this.apiService.getMany(
          `/publications?publications_type.id=${this.publicationTypeId}&_sort=year:DESC,month:DESC&_start=${result.startIndex}&_limit=${result.pageSize}`
        );
      }else {
        this.publications = await this.apiService.getMany(
          `/publications?publications_type.id=${this.publicationTypeId}&year=${this.currentPublication}&_sort=year:DESC,month:DESC&_start=${result.startIndex}&_limit=${result.pageSize}`
        );
      }    
    }
 

   // console.log("this.publications");
    //console.log(this.publications);  
      

    if (this.nationalPublications != undefined) {
      this.publications = this.nationalPublications.concat(this.publications);
    }
 
    this.pagesList = result.pages;
  }

  diffDaysPublished(publication:any){
    let date1 = new Date();
    let date2 = new Date(publication.year, publication.month, 1);
    let oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    return Math.abs((date1.getTime() - date2.getTime()) / (oneDay));
  }

  isNew(publication:any, periodicity:string){
    let daydiff = 0;
    daydiff = this.diffDaysPublished(publication);
    if(periodicity == 'Anual' && daydiff <= 365){
      return true;
    }else if(periodicity == 'Trimestral' && daydiff <= 90){
      return true;
    }else if(periodicity == 'Semestral' && daydiff <= 180){
      return true;
    }else if(periodicity == 'Mensual' && daydiff <= 60){
      return true;
    }
    return false;
  }

  ngOnDestroy(): void {
    this.paramsSubscription.unsubscribe();
  }
}
