import { Component, OnInit } from '@angular/core';
import { ChartType } from 'angular-google-charts';

@Component({
  selector: 'app-stack-education',
  templateUrl: './stack-education.component.html',
  styleUrls: ['./stack-education.component.scss'],
})
export class StackEducationComponent implements OnInit {
  information: any;

  chartType = ChartType.BarChart;
  chartOptions: any = {};
  chartColumns: any = [];

  constructor() {
    this.information = {};
  }

  ngOnInit(): void {
    this.getTopTenCharts();
  }

  getTopTenCharts() {
    this.information = {
      buttonText: 'Ver más sobre el Panorama laboral',

      charts: [
        {
          title: 'Top 10 ocupaciones más demandadas',
          source: 'Encuesta Nacional de Hogares (ENAHO) - INEI',
          data: [['2021', 43, 35, 22]],
        },
      ],
    };

    this.chartColumns = [
      'Year',
      'Universitario',
      'Educación Secundaria',
      'Técnico',
    ];

    this.chartOptions = {
      colors: ['#0056AC', '#1E456B', '#3573B1', '#7EA4CA', '#DCE1EA'],
      hAxis: {
        title: '',
      },
      vAxis: {
        minValue: 0,
      },
      isStacked: 'percent',
      legend: { position: 'top', maxLines: 3 },
      chartArea: {
        left: 10,
      },
    };
  }
}
