import { Placeholder } from '@angular/compiler/src/i18n/i18n_ast';
import { Component, OnInit,ViewChild,ElementRef, Renderer2 } from '@angular/core';
import { color, style } from 'd3';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-comparador',
  templateUrl: './comparador.component.html',
  styleUrls: ['./comparador.component.scss']
})
export class ComparadorComponent implements OnInit {
  @ViewChild('auto1', { static: false }) auto1: ElementRef<HTMLInputElement> = {} as ElementRef;
  ocupaciones: any;
  ocupacion1: any;
  ocupacion2: any;  
  ocupacion3: any;    
  total_pais: any;      
  keyword = 'name_sa';
  name1 :string;
  name2 :string;
  name3 :string;  
  constructor(private apiService: ApiService,private renderer2:Renderer2) { 
    this.ocupaciones = [];
    this.ocupacion1 = [];
    this.ocupacion2 = [];
    this.ocupacion3 = [];
    this.name1 = '';
    this.name2 = '';
    this.name3 = '';     
    this.total_pais =  
      {
      "total_trabajadores": 4243656,
      "sueldo": 2555,                       
      "tasa_mujeres": 35,
      "edad_promedio": 37,
      "tasa_crecimiento": 9.1,
      "empresas_contratan": 351583
      }
    ;    

  }

  ngOnInit(): void {
    this.getOcupaciones();
  }


  async getOcupaciones() {
    //Recorre por tramos, ya que strapi tiene un limite de 100 registros de retorno.
    let inicio = 0;
    let limite = 50;
    let total =  await this.apiService.getMany(`/occupation-fours/count`);

    for (let index = 0; index < 10; index++) {

      let ocupacionesTramo = await this.apiService.getMany(`/occupation-fours?_start=${inicio}&_limit=${limite}`);
      
      //console.log("ocupacionesTramo.length",ocupacionesTramo.length);

      this.ocupaciones = this.ocupaciones.concat(ocupacionesTramo);

      if (inicio + limite >= total) {
        index = 10;
      }

      inicio = inicio + limite;

    }

    this.ocupaciones = this.ocupaciones.map((row: any) => {
      const nombre_sa = this.removeAccents(row.name) + ' ' + row.name;

    return {
      id: row.id,
      code: row.code,
      name: row.name,
      name_sa: nombre_sa,
    };
    });
    //console.log("this.ocupaciones comparad",this.ocupaciones);
   //console.log("this.ocupaciones.length",this.ocupaciones.length);

  }

  removeAccents = (str:string) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  } 

  async getOcupacion(codigo :string,id : number ) {
    let occupation = await this.apiService.getManyBackend(
      `/occupations/indicadores/${codigo}`, null
    );
    switch (id) {
      case 1:
        this.ocupacion1 = occupation.data;
        //console.log("occupation1",this.ocupacion1);
        break;
      case 2:
        this.ocupacion2 = occupation.data;
        //console.log("occupation2",this.ocupacion2);
        break;
      case 3:
        this.ocupacion3 = occupation.data;
        //console.log("occupation3",this.ocupacion3);
        break;
    }      

  }

  public actualizaTexto(cadena:string,id : number) {
   //console.log("actualizaTexto");

   switch (id) {
    case 1:
      this.name1 = cadena;
      //console.log("occupation1",this.ocupacion1);
      break;
    case 2:
      this.name2 = cadena;
      //console.log("occupation2",this.ocupacion2);
      break;
    case 3:
      this.name3 = cadena;
      //console.log("occupation3",this.ocupacion3);
      break;
  }     

  }

  public Limpiar() {
    this.ocupacion1 = [];
    this.name1 = '';
    this.name2 = '';
    this.name3 = '';

    const asinput = this.auto1.nativeElement;

    //console.log("input1",asinput);
    //console.log("input2",this.auto1);

    this.ocupacion2 = [];
    this.ocupacion3 = [];
  }
 
  selectEvent_1(item: any) {
    var code = item.code;
    console.log("selectEvent_1");
    setTimeout(() => {
      this.actualizaTexto(item.name,1)
    }, 1);

    this.getOcupacion(code,1);
  }

  selectEvent_2(item: any) {
    var code = item.code;
    //console.log("selectEvent_1");    
    setTimeout(() => {
      this.actualizaTexto(item.name,2)
    }, 1);


    this.getOcupacion(code,2);
  }

  selectEvent_3(item: any) {
    var code = item.code;
    //console.log("code",code);
    setTimeout(() => {
      this.actualizaTexto(item.name,3)
    }, 1);

    this.getOcupacion(code,3);
 
  }


  onFocused(e: any) {
    //this.auto.close();
  }

  onCleared_1(e: any) {
    this.ocupacion1 = [];
    //this.auto1.close();
  }

  onCleared_2(e: any) {
    this.ocupacion2 = [];
  }

  onCleared_3(e: any) {
    this.ocupacion3 = [];
  }

  onChangeSearch(e: any) {
    //this.name1 = "ASASS";
    //console.log("onChangeSearch");
    //this.auto.close();
  }

  
}
