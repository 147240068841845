import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-looking-job',
  templateUrl: './looking-job.component.html',
  styleUrls: ['./looking-job.component.scss'],
})
export class LookingJobComponent implements OnInit {
  information: any;
  totalResults: number;
  publics: any[];
  privates: any[];
  globals: any[];
  imageBase: string;

  constructor(private apiService: ApiService) {
    this.information = {};
    this.totalResults = 0;
    this.publics = [];
    this.privates = [];
    this.globals = [];
    this.imageBase = environment.strapiUrl;
  }

  ngOnInit(): void {
    this.getInformation();
  }

  async getInformation() {
    let header = await this.apiService.getOne(
      '/job-developments?code=buscar-empleo'
    );

    let items = await this.apiService.getMany('/looking-jobs');

    this.information = {
      title: header.title,
      description: header.description,
      results: items,
    };

    this.publics = this.information.results.filter(
      (x: any) => x.category === 'Bolsas del sector público'
    );
    this.privates = this.information.results.filter(
      (x: any) => x.category === 'Bolsas del sector privado'
    );
    this.totalResults = this.information.results.length;
  }

  navigate(url: string) {
    if (url === '') {
      return;
    }
    let external = document.createElement('a');
    external.href = url;
    external.target = '_blank';
    external.click();
    external.remove();
  }
}
