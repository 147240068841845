import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiService } from '../../services/api.service';
import { environment } from '../../../environments/environment';
import { DomSanitizer} from '@angular/platform-browser';


@Component({
  selector: 'app-detalle-tablero',
  templateUrl: './detalle-tablero.component.html',
  styleUrls: ['./detalle-tablero.component.scss']
})
export class DetalleTableroComponent implements OnInit {
  tableroDemanda: any;
  imageBase: string;
  slug: any;
  paramsSubscription: Subscription;
  seguraUrl: any;
  height: any;
  information: any;

  constructor(private router: ActivatedRoute,private apiService: ApiService,private sanitizer: DomSanitizer ) 
  { 
    this.slug = '';
   
    this.paramsSubscription = Subscription.EMPTY;
    this.tableroDemanda = [];
    this.imageBase = environment.strapiUrl;
    this.information = {};
    this.seguraUrl = '';
  }

  ngOnInit(): void {
    this.paramsSubscription = this.router.params.subscribe(({ slug }) => {
      this.slug = slug;

      this.getTableroDemanda();
 
    });
  }


  async getTableroDemanda() {


    this.information = {
      buttonText: 'Conoce más sobre cada Ocupación',
      buttonLink: '/descubre',
    };

    this.tableroDemanda = await this.apiService.getMany(
      `/tablero-de-mandos/${this.slug}`
    );
    
    this.seguraUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.tableroDemanda.Url);   

    this.height = 2430;
    
  
  }  


}
