import { Component, OnInit,Input } from '@angular/core';
import { ChartType } from 'angular-google-charts';

@Component({
  selector: 'app-pie-job',
  templateUrl: './pie-job.component.html',
  styleUrls: ['./pie-job.component.scss'],
})
export class PieJobComponent implements OnInit {
  information: any;

  chartType = ChartType.PieChart;
  chartOptions: any = {};
  chartColumns: any = [];
  @Input() occupation_skills: any;

  constructor() {
    this.information = {};
  }

  ngOnInit(): void {
    this.getTopTenCharts();
  }

  getTopTenCharts() {

    // this.information = {
    //   buttonText: 'Ver más sobre el Panorama laboral',

    //   charts: [
    //     {
    //       title: 'Top 10 ocupaciones más demandadas',
    //       source: 'Encuesta Nacional de Hogares (ENAHO) - INEI',
    //       data: [
    //         ['Comunicación, colaboración y creatividad', 54],
    //         ['Competencias en materia de información', 26],
    //         ['Prestar asistencia y cuidados', 14],
    //         ['Competencias de gestión', 5],
    //         ['Trabajar con ordenadores', 1] 
    //       ],
    //     },
    //   ],
    // };

    // console.log("this.information");
    // console.log(this.information);      

    this.chartColumns = [
      { type: 'string', role: 'domain', label: 'Ocupations' },
      { type: 'number', role: 'data', label: 'Value' },
      { type: 'string', role: 'annotation', label: 'Value' },
    ];

    this.chartOptions = {
      colors: ['#125EA8', '#29537C', '#0A3055', '#474D98', '#866DB2', '#6D82B2', '#B3A5CC', '#DCE1EA'],
      pieHole: 0.4,
      legend: { position: 'right'},
      vAxis: {
        textStyle: {
          color: '#6f777b',
          fontSize: 12,
          fontName: 'Roboto',
        },
        baselineColor: 'none',
        gridlineColor: 'none',
        gridlines: { color: 'none' },
      },
      hAxis: {
        textPosition: 'none',
        baselineColor: 'none',
        gridlineColor: 'none',
        gridlines: { color: 'none' },
      },
      series: {
        0: {
          color: '#0056AC',
          annotations: {
            textStyle: {
              strokeWidth: 0,
              fontSize: 14,
              color: '#6F777B',
            },
          },
        },
      },
      annotations: {
        stemColor: 'none',
        alwaysOutside: true,
      },
      chartArea: {
        left: 10,
        right: 10,
        top: 10,
        bottom: 10,
      },
      tooltip: {
        //trigger: 'none'
      },
    };
  }
}
