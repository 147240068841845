<div class="card custom">
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-8">
          <h3 class="card-title">{{ information.value }}</h3>
        </div>
        <div class="col-4" style="padding: 0px">
          <mat-icon
            style="width: 40px; height: 40px"
            class="icon-color icon-x2"
            svgIcon="{{ information.icon }}"
          ></mat-icon>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div>
        <span
          class="fs-14 text-secondary col-md-4"
          [innerHTML]="information.description"
        ></span>
      </div>
    </div>
  </div>
</div>
