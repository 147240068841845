import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-occupational-advanced-studies',
  templateUrl: './advanced-studies.component.html',
  styleUrls: ['./advanced-studies.component.scss'],
})
export class AdvancedStudiesComponent implements OnInit, OnDestroy {
  information: any;
  totalResults: number;
  imageBase: string;
  tabs: any[];
  loading: boolean;
  banner_url: any;

  constructor(private apiService: ApiService) {
    this.information = {};
    this.totalResults = 0;
    this.imageBase = environment.strapiUrl;
    this.tabs = [];
    this.loading = true;
    this.banner_url = {};
  }

  ngOnInit(): void {
    this.getInformation();
  }

  async getInformation() {
    let header = await this.apiService.getOne(
      '/job-developments?code=estudios-avanzados'
    );

    let items = await this.apiService.getMany('/advanced-studies');

    this.information = {
      title: header.title,
      description: header.description,
      results: items,
    };
    this.totalResults = this.information.results.length;
    this.tabs = await this.apiService.getMany('/advanced-studies');
    this.banner_url = await this.apiService.getMany('/banner-urls');
    this.loading = false;
  }

  ngOnDestroy(): void {}
}
