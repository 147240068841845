<div class="container">

  <div class="col-12 mt-4">
     <div class="row">
        <div class="col-xs-12 col-lg-9 mb-2" *ngFor="let tablero of tableros">

            <div class="card two-columns">
                <div class="row">
                    <div class="col-3 col-sm-3 col-md-3 col-lg-3 image">
                        <img
                          class="lazyload"
                          alt=""
                          [attr.data-src]="imageBase + tablero.image[0].url"
                        />
                    </div>
                    <div class="col-9 col-sm-9 col-md-9 col-lg-9">
                        <div class="card-body">
                          <h4>
                            <a
                              class="text-primary"
                              [href]="'/tablero-mando/' + tablero.id"
                              >{{ tablero.Title }}</a
                            >
         
                          </h4>
                          <p align="justify" 
                            class="description"
                            [innerHTML]="tablero.description">
                          </p>                          
         
                        </div>
                    </div>       
                </div>

            </div>
     
        </div>
     </div>
  </div>

</div>

