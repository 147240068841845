<app-loading *ngIf="loading"></app-loading>
<div class="mt-4" *ngIf="!loading">
  <div class="mb-4 mt-4">
    <a class="breadcrumb-link" routerLink="/recursos/observatorios-regionales"
      >Observatorios Regionales</a
    >
    >
    <span class="text-secondary">{{ information.results[0].name }}</span>
  </div>
  <h4 class="mt-4">
    Observatorio Socio Económico Laboral {{ information.results[0].name }}
  </h4>
  <ul class="nav nav-tabs mt-4">
    <li class="nav-item">
      <button
        class="nav-link active"
        data-bs-toggle="tab"
        data-bs-target="#about"
        type="button"
        role="tab"
        aria-controls="about"
        aria-selected="true"
      >
        Sobre {{ information.results[0].name }}
      </button>
    </li>
    <li class="nav-item">
      <button
        class="nav-link"
        data-bs-toggle="tab"
        data-bs-target="#oselpub"
        type="button"
        role="tab"
        aria-controls="oselpub"
        aria-selected="true"
      >
        Publicaciones OSEL
      </button>
    </li>
    <li class="nav-item">
      <button
        class="nav-link"
        data-bs-toggle="tab"
        data-bs-target="#sources"
        type="button"
        role="tab"
        aria-controls="sources"
        aria-selected="true"
      >
        Fuentes de información
      </button>
    </li>
  </ul>

  <div class="tab-content" id="myTabContent">
    <div class="tab-pane fade show active" id="about" role="tabpanel">
      <app-osel-region-about
        [information]="information"
      ></app-osel-region-about>
    </div>
    <div class="tab-pane fade" id="oselpub" role="tabpanel">
      <app-osel-region-publications
        [publications]="publication"
        [categories]="uniqueCategories"
        [years]="uniqueYears"
      ></app-osel-region-publications>
    </div>
    <div class="tab-pane fade" id="sources" role="tabpanel">
      <app-osel-information-sources
        [sources]="source"
        [categories]="uniqueSourceCategories"
        [years]="uniqueYearsSource"
      ></app-osel-information-sources>
    </div>
  </div>
</div>
