import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { GoogleChartsModule } from 'angular-google-charts';
import { DiscoverModule } from '../discover/discover.module';

import { DetailComponent } from './detail.component';
import { HeaderComponent } from './header/header.component';
import { DatosTendenciasComponent } from './datos-tendencias/datos-tendencias.component';
import { FormacionAcademicaComponent } from './formacion-academica/formacion-academica.component';
import { SalaryTrendComponent } from './datos-tendencias/salary-trend/salary-trend.component';
import { EmployeeTrendComponent } from './datos-tendencias/employee-trend/employee-trend.component';
import { HiringCompaniesComponent } from './datos-tendencias/hiring-companies/hiring-companies.component';
import { EconomicActivityComponent } from './datos-tendencias/economic-activity/economic-activity.component';
import { EmployeeVariationsComponent } from './datos-tendencias/employee-variations/employee-variations.component';
import { OffersVariationComponent } from './datos-tendencias/offers-variation/offers-variation.component';
import { TrendTableComponent } from './datos-tendencias/trend-table/trend-table.component';
import { HeatComponent } from './datos-tendencias/heat/heat.component';
import { DescripcionComponent } from './descripcion/descripcion.component';
import { PieJobComponent } from './descripcion/pie-job/pie-job.component';
import { PrivadoComponent } from './datos-tendencias/privado/privado.component';
import { PublicoComponent } from './datos-tendencias/publico/publico.component';
import { GeneralComponent } from './datos-tendencias/general/general.component';

@NgModule({
  declarations: [
    DetailComponent,
    HeaderComponent,
    DatosTendenciasComponent,
    FormacionAcademicaComponent,
    SalaryTrendComponent,
    EmployeeTrendComponent,
    HiringCompaniesComponent,
    EconomicActivityComponent,
    EmployeeVariationsComponent,
    OffersVariationComponent,
    TrendTableComponent,
    HeatComponent,
    DescripcionComponent,
    PieJobComponent,
    PrivadoComponent,
    PublicoComponent,
    GeneralComponent

  ],
  imports: [CommonModule, SharedModule, RouterModule, GoogleChartsModule,DiscoverModule],
  exports: [],
})
export class DetailModule {}
