import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-occupational-empre-support',
  templateUrl: './empre-support.component.html',
  styleUrls: ['./empre-support.component.scss'],
})
export class EmpreSupportComponent implements OnInit {
  information: any;
  totalResults: number;
  publics: any[];
  privates: any[];
  globals: any[];
  imageBase: string;

  constructor(private apiService: ApiService) {
    this.information = {};
    this.totalResults = 0;
    this.publics = [];
    this.privates = [];
    this.globals = [];
    this.imageBase = environment.strapiUrl;
  }

  ngOnInit(): void {
    this.getInformation();
  }

  async getInformation() {
    let header = await this.apiService.getOne(
      '/job-developments?code=soporte-emprendimiento'
    );

    let items = await this.apiService.getMany('/support-entrepreneurships');

    this.information = {
      title: header.title,
      description: header.description,
      results: items,
    };

    this.publics = this.information.results.filter(
      (x: any) => x.category === 'iniciativas-publicas'
    );
    this.privates = this.information.results.filter(
      (x: any) => x.category === 'iniciativas-privadas'
    );
    this.globals = this.information.results.filter(
      (x: any) => x.category === 'iniciativas-globales'
    );
    this.totalResults = this.information.results.length;
  }

  navigate(url: string) {
    if (url === '') {
      return;
    }
    let external = document.createElement('a');
    external.href = url;
    external.target = '_blank';
    external.click();
    external.remove();
  }
}
