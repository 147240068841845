import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-job-number',
  templateUrl: './job-number.component.html',
  styleUrls: ['./job-number.component.scss'],
})
export class JobNumberComponent implements OnInit {
  information: any;
  totalResults: number;
  publics: any[];
  privates: any[];
  globals: any[];
  imageBase: string;
  computrabajo: any;
  empleosPeru: any;  
  tabPrivadoLista: any;
  tabPublicoLista: any;  
  loading: boolean;

  constructor(private apiService: ApiService) {
    this.information = {};
    this.totalResults = 0;
    this.publics = [];
    this.privates = [];
    this.globals = [];
    this.imageBase = environment.strapiUrl;
    this.computrabajo = false;
    this.empleosPeru = false;    
    this.tabPrivadoLista = true;
    this.tabPublicoLista = true;    
    this.loading = false;    
  }

  ngOnInit(): void {
    this.getInformation();
  }

  async getInformation() {
    let header = await this.apiService.getOne(
      '/job-developments?code=empleo-en-numeros'
    );

    let items = await this.apiService.getMany('/looking-jobs');

    items = items.filter(
      (x: any) => x.title === "Empleos Perú - MTPE" ||  x.title === "Computrabajo" 
    );

    console.log("items",items);;


    this.information = {
      title: header.title,
      description: header.description,
      results: items,
    };

    this.publics = this.information.results.filter(
      (x: any) => x.category === 'Bolsas del sector público'
    );
    this.privates = this.information.results.filter(
      (x: any) => x.category === 'Bolsas del sector privado'
    );


    //Provisional
    this.publics[0].description = "<p><span>Contiene los principales indicadores sobre las vacantes de empleo publicadas por las empresas registrados en el servicio de la Bolsa de Trabajo del Portal Empleos Perú. Se muestra, además, indicadores de intermediación laboral, así como brechas entre vacantes y postulaciones.</span></p>";
    this.privates[0].description = "<p><span>Contiene los principales indicadores sobre las vacantes de empleo publicadas por las empresas en la web de Computrabajo.</span></p>";
    //
    this.totalResults = this.information.results.length;
  }

  mostrarBolsaPrivado() {
    this.tabPrivadoLista = true;
    this.computrabajo = false;    
  }

  
  mostrarBolsaPublico() {
    this.tabPublicoLista = true;
    this.empleosPeru = false;    
  }

  mostrarEmpleosPeru() {
    this.loading = true;    
    this.empleosPeru = true;    
    this.tabPublicoLista = false;

    setTimeout(() => {
      this.loading = false;
    }, 2000);        

  }

  ocultarEmpleosPeru() {
    this.empleosPeru = false;    
    this.tabPublicoLista = true;

  }  

  mostrarComputrabajo() {
    this.loading = true;    
    this.computrabajo = true;
    this.tabPrivadoLista = false;

    setTimeout(() => {
      this.loading = false;
    }, 2000);       
  }

  ocultarComputrabajo() {
    this.computrabajo = false;
    this.tabPrivadoLista = true;    
  }

  navigate(url: string) {
    if (url === '') {
      return;
    }
    let external = document.createElement('a');
    external.href = url;
    external.target = '_blank';
    external.click();
    external.remove();
  }
}
