<div>
  <h3 class="text-attention pb-2">Recibe orientación ocupacional</h3>
</div>
<div class="row">
  <div class="col-sm-12 col-md-4" *ngFor="let card of information.results">
    <div class="card image no-border no-shadow" *ngIf="card.code === 'orientacion_ocupacional'">
      <div class="row">
        <div class="col-sm-12">
          <div class="card-header">
            <a routerLink="{{ card.url }}"
              ><img class="lazyload" [attr.data-src]="imageBase + card.image[0].url" alt=""
            /></a>
          </div>

          <div class="card-body">
            <a routerLink="{{ card.url }}">
              <h4 class="text-primary text-center">{{ card.title }}</h4>
              <p class="text-secondary text-center">
                {{ card.description }}
              </p></a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
