import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { GoogleChartsModule } from 'angular-google-charts';
import { OutlookRoutingModule } from './outlook-routing.module';

import { OutlookComponent } from './outlook.component';
import { IndicatorDetailComponent } from './indicator-detail/indicator-detail.component';
import { IndicatorsListComponent } from './indicators-list/indicators-list.component';
import { LineChartComponent } from './shared/linechart/linechart.component';
import { BarChartComponent } from './shared/barchart/barchart.component';
import { SelectIndicatorComponent } from './indicator-detail/select-indicator/select-indicator.component';
import { NoStatFiltersComponent } from './indicator-detail/nostat-filters/nostat-filters.component';
import { NoStatChartSectionComponent } from './indicator-detail/nostat-chart-section/nostat-chart-section.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';



@NgModule({
  declarations: [
    OutlookComponent,
    IndicatorsListComponent,
    IndicatorDetailComponent,
    LineChartComponent,
    BarChartComponent,
    SelectIndicatorComponent,
    NoStatFiltersComponent,
    NoStatChartSectionComponent,
  ],
  imports: [CommonModule, SharedModule, RouterModule, GoogleChartsModule, OutlookRoutingModule, FormsModule, AutocompleteLibModule],
  exports: [],
})
export class OutlookModule { }
