<app-loading *ngIf="loading"></app-loading>
<div class="mt-4" *ngIf="!loading">
  <h4 class="fw-bold mb-4">Filtros</h4>
  <div *ngIf="error">
    <h5 class="text-attention">No se pudo obtener los filtros</h5>
  </div>
  <div *ngIf="!error">
    <div class="mb-4">
      <span class="fst-italic fs-14 gray">Puedes seleccionar algún filtro de interés para acotar los datos. Ten en cuenta, que solo se aplicará uno a la vez.</span>
    </div>
    <div class="mb-4" *ngFor="let filter of filters;let indice=index">
      <p class="fw-bold">{{ filter.key }}</p>
      <select  [name]="filter.key" [id]="filter.key" #dropdown  class="form-select" [(ngModel)]="filter.key == selectedKey ? selectedValue : defaultValue"
        (change)="onChange(filter.key, $event)">
        <option value="" selected>Seleccione</option>
        <option *ngFor="let value of filter.values" [value]="value.name">{{value.name}}</option>
      </select>
    </div>
    <div class="mt-4">
      <a [ngClass]="{ 'disabled': cleanDisabled }"
        class="btn-link fw-bold" style="cursor: pointer;" (click)="cleanFilters()">Limpiar filtros</a>
    </div>
  </div>
</div>
