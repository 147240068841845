<app-loading *ngIf="loading"></app-loading>
<div class="container" *ngIf="!loading">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="row" *ngIf="!isSubscribed" id="Suscription">
          <div class="col-12 col-lg-5 text-center">
            <img alt="" width="250px" height="250px" [src]="strapiUrl + activeBanner.image[0].url" />
            <!-- <img class="lazyload" width="250px" height="250px"
              [attr.data-src]="strapiUrl + activeBanner.image[0].url" /> -->
          </div>
          <div class="col-12 col-lg-7 mt-0 mt-sm-0 mt-md-0 mt-lg-5 pt-0 pt-sm-0 pt-md-0 pt-lg-5">
            <div>
              <h3>{{ activeBanner.title }}</h3>
            </div>
            <div class="row mt-4">
              <div class="col-12 col-lg-6">
                <input (keyup)="onInputChange($event)" #subscribeInput style="height: 53px;" class="form-control"
                  type="text" placeholder="{{ activeBanner.input_placeholder }}">
                <span *ngIf="showError" class="fs-12 text-attention">
                  Debe tener formato de email, ejm: nombre@email.com
                </span>
              </div>
              <div class="col-12 col-lg-6 text-center text-lg-start
                mt-4 mt-sm-4 mt-md-4 mt-lg-0">
                <button [ngClass]="!(email.length > 0) ? 'btn-outline-primary disabled' : 'btn-primary'" class="btn"
                  (click)="subscribe()">
                  {{ activeBanner.button_text }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="isSubscribed">
          <div class="col-12 text-center">
            <img alt="" width="250px" height="250px" [src]="strapiUrl + activeBanner.image[0].url" />
            <!-- <img class="lazyload" width="250px" height="250px"
              [attr.data-src]="strapiUrl + activeBanner.image[0].url" /> -->
            <div>
              <h3>{{ activeBanner.title }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
