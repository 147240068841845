import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-occupational-scholarships',
  templateUrl: './scholarships.component.html',
  styleUrls: ['./scholarships.component.scss'],
})
export class ScholarshipsComponent implements OnInit, OnDestroy {
  information: any;
  subcategory: any;
  totalResults: number;
  publics: any[];
  privates: any[];
  imageBase: string;
  filteredList: any[];
  typePublications: any;
  basePublications: any;
  currentPublication: string;

  constructor(private apiService: ApiService) {
    this.information = {};
    this.subcategory = {};
    this.totalResults = 0;
    this.publics = [];
    this.privates = [];
    this.imageBase = environment.strapiUrl;
    this.totalResults = 0;
    this.filteredList = [];
    this.typePublications = {};
    this.basePublications = {};
    this.currentPublication = 'Todos';
  }

  ngOnInit(): void {
    this.getInformation();
    this.getActualPublication(this.currentPublication);
  }

  async getInformation() {
    this.subcategory = {
      buttons: [
        {
          title: 'Todos',
          periodicity: 'Todos',
        },
        {
          title: 'Pregrado',
          periodicity: 'Pregrado',
        },
        {
          title: 'Postgrado',
          periodicity: 'Postgrado',
        },
        {
          title: 'Universitario',
          periodicity: 'Universitario',
        },
        {
          title: 'Técnico',
          periodicity: 'Técnico',
        },
        {
          title: 'Capacitación laboral',
          periodicity: 'Capacitación laboral',
        },
      ],
    };

    let header = await this.apiService.getOne(
      '/job-developments?code=financiamiento-becas'
    );

    let items = await this.apiService.getMany('/scholarships');

    this.information = {
      title: header.title,
      description: header.description,
      results: items,
    };

    this.typePublications = items;
    this.basePublications = items;

    this.publics = this.typePublications.filter(
      (x: any) => x.category === 'Entidades públicas'
    );

    this.privates = this.typePublications.filter(
      (x: any) => x.category === 'Entidades privadas'
    );

    this.totalResults = this.typePublications.length;
  }

  navigate(url: string) {
    if (url === '') {
      return;
    }
    let external = document.createElement('a');
    external.href = url;
    external.target = '_blank';
    external.click();
    external.remove();
  }

  ngOnDestroy(): void {}

  getActualPublication(currentPublication: string) {
    this.currentPublication = currentPublication;
    if (currentPublication !== 'Todos') {
      let publicationsFiltered = this.basePublications.filter(
        (information: any) => information.subcategory === currentPublication
      );
      this.typePublications = publicationsFiltered;
      this.publics = this.typePublications.filter(
        (x: any) => x.category === 'Entidades públicas'
      );

      this.privates = this.typePublications.filter(
        (x: any) => x.category === 'Entidades privadas'
      );
    } else {
      this.typePublications = this.basePublications;
      this.publics = this.typePublications.filter(
        (x: any) => x.category === 'Entidades públicas'
      );

      this.privates = this.typePublications.filter(
        (x: any) => x.category === 'Entidades privadas'
      );
    }
  }
}
