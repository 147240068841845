<div  *ngIf="information !== undefined">
  <div class="card chart-card">
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-12">
          <div class="row">
            <div class="col-6">
              <h4>{{ information.title }}</h4>
            </div>
            <div class="col-6 d-flex justify-content-end">
              <button
                class="btn btn-sm btn-link text-secondary fs-14"
                (click)="downloadExcel()"
              >
                <mat-icon svgIcon="file-download-outline"></mat-icon>
                Descargar datos
              </button>
  
              <button
                class="btn btn-sm btn-link text-secondary fs-14"
                (click)="downloadImage()"
              >
                <mat-icon svgIcon="image-outline"></mat-icon>
                Guardar imagen
              </button>
  
              <button
                class="btn btn-sm btn-link text-secondary fs-14"
                data-bs-toggle="modal"
                [attr.data-bs-target]="'#modal-' + information.id"
              >
                <mat-icon svgIcon="note-text-outline"></mat-icon>
                Ver nota técnica
              </button>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-12">
          <p>{{ information.description }}</p>
        </div>
  
        <app-loading *ngIf="loading"></app-loading>
  
        <div *ngIf="!loading" class="col-12">
          <table class="table" #myContent>
            <caption style="visibility: hidden">
              El cuadro muestra el número de trabajadores asalariados contratados
              por las empresas del sector privado formal por nivel educativo para
              la ocupación seleccionada
            </caption>
            <thead>
              <tr>
                <th
                  scope="col"
                  class="text-center"
                  *ngFor="let head of information.heads"
                >
                  {{ head.title }}
                </th>
              </tr>
            </thead>
            <tbody *ngFor="let row of tabla_resumen" class="text-black">
              <tr>
                <td class="text-center td-educational">{{ row.name }}</td>
                <td class="text-center td-educational">S/ {{ row.sueldo_promedio }}</td>
                <td class="text-center td-educational">{{ row.total_trabajadores }} trabajadores</td>
                <td class="text-center td-educational">{{ row.edad_promedio }} años</td>
                <td class="text-center td-educational">{{ row.tasa_mujeres }}%</td>
              </tr>
            </tbody>
            <thead>
            <tr>
              <th class="text-center" *ngFor="let footer of information.footers">
                {{ footer.title }}
              </th>
            </tr>
          </thead>
          </table>
          1/ Incluye el total de puestos de trabajo, incluso aquellos para los que no se especificaron respuestas en el nivel educativo.
        </div>
        <div class="text-start mt-2">
          <mat-icon class="icon-color" svgIcon="information"></mat-icon>
          <span class="source">Fuente: {{ information.source }}</span>
        </div>
      </div>
    </div>
  </div>
  
  <div
    class="modal fade"
    [id]="'modal-' + information.id"
    tabindex="-1"
    data-bs-backdrop="static"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Nota técnica</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body" [innerHTML]="information.technical_note"></div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary view-custom"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
  
</div>

