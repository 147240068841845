<div *ngIf="information">
  <h3 class="text-attention" style="margin-bottom: 1.5rem">
    {{ information.title }}
  </h3>

  <app-loading *ngIf="loading"></app-loading>

  <div *ngIf="!loading" class="row">
    <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2" *ngFor="let indicator of indicators">
      <app-card [information]="indicator"></app-card>
    </div>
  </div>

  <div style="margin-top: 1rem" class="text-start">
    <mat-icon class="icon-color" svgIcon="information"></mat-icon>
    <span class="source">Fuente: {{ information.source }}</span>
  </div>
</div>
