<app-loading *ngIf="loading"></app-loading>
<div class="mt-4" *ngIf="!loading">
  <div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-3">
      <h4>Categorías</h4>
      <div class="mt-4 list-group">
        <a
          [ngClass]="currentCategory === 0 ? 'active' : ''"
          class="list-group-item list-group-item-action"
          (click)="filterByCategory(0)"
        >
          Todas las publicaciones</a
        >
        <a
          [ngClass]="currentCategory === category.id ? 'active' : ''"
          class="list-group-item list-group-item-action"
          *ngFor="let category of categories"
          (click)="filterByCategory(category.id)"
          >{{ category.title }}</a
        >
      </div>
    </div>
    <div class="col-12 col-sm-12 col-md-12 col-lg-9">
      <div class="row">
        <div class="col-10">
          <h4 *ngIf="currentCategory === 0">
            Todas las publicaciones {{ sources.results[0].osel_region.name }}
          </h4>
          <h4 *ngIf="currentCategory != 0">
            {{ categoryName }}
            {{ sources.results[0].osel_region.name }}
          </h4>
        </div>
        <div class="col-6 col-sm-6 col-md-2 col-lg-2 text-end">
          <select
            class="form-select rounded-0"
            [(ngModel)]="currentYear"
            (change)="filterByYear($event)"
          >
            <option [value]="year" *ngFor="let year of years">
              {{ year }}
            </option>
          </select>
        </div>
        <div class="col-12">
          <span class="fs-12 text-secondary"
            >{{ totalResults }} resultados</span
          >
        </div>
      </div>

      <div class="mt-4 row">
        <div class="col-sm-12 col-lg-4" *ngFor="let item of filteredList">
          <div class="card simple" style="height: 250px">
            <div class="row">
              <div class="col-sm-12">
                <div class="card-body">
                  <span *ngIf="item.recentlyAdded == 1" class="badge"
                    >NUEVO</span
                  >
                  <h5 class="fw-bold">{{ item.title }}</h5>
                </div>
                <div class="card-footer">
                  <div *ngIf="item.extension === '.pdf'">
                    <a
                      href="{{ filteredList[0].urls }}"
                      class="text-primary fw-bold"
                    >
                      <mat-icon
                        class="text-attention"
                        svgIcon="pdf-box"
                      ></mat-icon
                      >Descargar archivo
                    </a>
                  </div>
                  <div *ngIf="item.extension === '.xls'">
                    <a
                      href="{{ filteredList[0].urls }}"
                      class="text-primary fw-bold"
                    >
                      <mat-icon class="text-xls" svgIcon="file-excel"></mat-icon
                      >Descargar archivo
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <mat-icon class="icon-color" svgIcon="information"></mat-icon>
        <span class="ms-2 fs-12 fw-bold text-secondary"
          >Actualizado el: {{ lastPublication }}</span
        >
      </div>
    </div>
  </div>
</div>
