<app-publications-header
  [headers]="headers"
  [strapiUrl]="strapiUrl"
></app-publications-header>

<div *ngIf="headers?.periodicity === 'Anual'" class="row col-12">
  <p class="col-sm-2 col-form-label fw-bold">Períodos</p>
  <div class="pt-2 pb-2 col-sm-10">
    <div class="button-group">
      <button
        *ngFor="let period of periods"
        type="button"
        [ngClass]="period === currentPublication ? 'active' : ''"
        (click)="getActualPublication(period)"
      >
        {{ period }}
      </button>
    </div>
  </div>
</div>


<div class="row mt-4">
  <div class="col-sm-12 col-lg-3" *ngFor="let publication of publications">
    <div class="card simple">
      <div class="row">
        <div class="col-sm-12">
          <div class="card-body">
            <span *ngIf="isNew(publication, headers.periodicity)" class="badge">NUEVO</span>
            <h4>{{ publication.title }}</h4>
          </div>
          <div class="card-footer">
            <a target="_blank" class="text-primary" [href]="publication.link" [ngSwitch]="publication.extension"
              >
              <mat-icon *ngSwitchCase="'.pdf'"
                class="text-attention"
                svgIcon="file-pdf-box"
              ></mat-icon>
              <mat-icon *ngSwitchCase="'.xls'"
              class="text-attention"
              svgIcon="file-excel-box"
              style="color:green"
              ></mat-icon>
              <mat-icon *ngSwitchCase="'.xlsx'"
                class="text-attention"
                svgIcon="file-excel-box"
                style="color:green"
              ></mat-icon>
              <mat-icon *ngSwitchCase="'.zip'"
                class="text-attention"
                svgIcon="zip-box"
                style="color:darkgoldenrod"
              ></mat-icon>     
              <mat-icon *ngSwitchCase="'.pptx'"
                class="text-attention"
                svgIcon="file-powerpoint"
                style="color:#d04423"
              ></mat-icon>                          
              <mat-icon *ngSwitchDefault
              class="text-attention"
              svgIcon="file"
            ></mat-icon>
              Descargar archivo
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="totalPublications > 0" class="text-end">
    <button
      class="btn btn-sm btn-link fw-bold"
      style="margin: 0rem 0.2rem; padding: 0rem 0.4rem"
      [ngClass]="currentPage <= 1 ? 'link-disabled' : ''"
      (click)="OnPaginate(currentPage - 1)"
    >
      < Anterior
    </button>

    <button
      class="btn btn-sm btn-link fw-bold"
      style="margin: 0rem 0.2rem; padding: 0rem 0.4rem"
      [ngClass]="page === currentPage ? 'current-page' : ''"
      (click)="OnPaginate(page)"
      *ngFor="let page of pagesList"
    >
      {{ page }}
    </button>

    <button
      class="btn btn-sm btn-link fw-bold"
      style="margin-left: 0.2rem; padding: 0rem 0.4rem"
      [ngClass]="currentPage === pagesList.length ? 'link-disabled' : ''"
      (click)="OnPaginate(currentPage + 1)"
      *ngIf="pagesList.length > 1"
    >
      Siguiente >
    </button>
  </div>

  <div class="text-start mt-2">
    <mat-icon class="icon-color" svgIcon="information"></mat-icon>
    <span class="source"
      >Fuente: {{ headers !== null ? headers.source_information : "" }}</span>
  </div>
</div>
