<app-loading *ngIf="loading"></app-loading>
<div *ngIf="!loading">
  <div class="mt-4 mb-4">
    <h3 class="fw-bold text-attention">{{ information.title }}</h3>
  </div>
  <div>
    <div [innerHTML]="information.description"></div>
  </div>
  <div class="mt-5 nav nav-tabs tabs-overflow" id="nav-tab" role="tablist">
    <button *ngFor="let tab of tabs; let i = index" [ngClass]="{ 'active': i === 0}" class="nav-link fw-bold text-secondary"
      id="nav-{{tab.icon}}-tab" data-bs-toggle="tab" [attr.data-bs-target]="'#nav-' + tab.icon" type="button" role="tab"
      [attr.aria-controls]="'nav-' + tab.icon" aria-selected="false">
      <span class="iconify" [attr.data-icon]="'mdi:' + tab.icon"></span> <br />
      {{ tab.title }}
    </button>
  </div>
  <div class="tab-content" id="nav-tabContent">
    <div *ngFor="let tab of tabs; let i = index" [ngClass]="{ 'show active': i === 0}" class="tab-pane fade"
      id="nav-{{tab.icon}}" role="tabpanel" [attr.aria-labelledby]="'nav-' + tab.icon + '-tab'">
      <div class="mt-5 row">
        <div class="col-12 col-md-7 col-lg-12">
          <h4>{{tab.title}}</h4>
          <div class="mt-3" [innerHTML]="tab.description"></div>
        </div>
      </div>
    </div>
  </div>
</div>