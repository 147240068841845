import { ReturnStatement } from '@angular/compiler';
import { Component, OnInit, Input } from '@angular/core';
import { getDiffieHellman } from 'crypto';
import { ApiService } from '../../../services/api.service';

@Component({
  selector: 'app-publico',
  templateUrl: './publico.component.html',
  styleUrls: ['./publico.component.scss']
})
export class PublicoComponent implements OnInit {

  monthsName = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
  "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
  ];

  @Input() occupation: any;
  filters: { [k: string]: any } = {};
  empresas_contratan: any;
  entradas_salidas: any;
  mapa_remuneraciones: any;
  mapa_trabajadores: any;
  remuneracion_mensual: any;
  tabla_resumen: any;
  footer_resumen: any;
  tendencia_trabajadores: any;
  oferta_variacion: any;

  actividad_economica: any;
  mapa_calor: any;

  regionSelected: any;
  edadSelected: any;
  educationSelected: any;
  loading: any;

  periodReference: any;

  occupationRegions: any;
  occupationGrupoEdads: any;
  occupationEducationLevels: any;

  isFour: boolean;
  ocupationTipo_nivel_educativo:any;

  constructor(private apiService: ApiService) {
    this.tabla_resumen = [];
    this.footer_resumen =  { footers:
    [
      {
        title: 'Total general',
      },
      {
        title: '0',
      },
      {
        title: '0',
      },
      {
        title: '0',
      },
      {
        title: '0%',
      },
    ],
    };
    this.tendencia_trabajadores = [];

    this.actividad_economica = undefined;

    this.regionSelected = 0;
    this.educationSelected = 0;
    this.edadSelected = 0;
    
    this.loading = true;
    this.occupationRegions = [];
    this.occupationGrupoEdads = [];    
    this.occupationEducationLevels = [];
    this.isFour= false;
    this.ocupationTipo_nivel_educativo= 0;
    
    this.periodReference = "2022-Abril "; //Provisional, dado que debe ser una variable
  }


  ngOnInit(): void {
    this.regionSelected = 0;
    this.educationSelected = 0;
    this.edadSelected = 0;
    this.getFiltros();    
    this.getDatosYTendencias();    
  }


  async getFiltros() {
    if (this.occupation.code === undefined) {
      await new Promise((f) => setTimeout(f, 300));
      this.getFiltros();
      return;
    }
    
    this.isFour = this.occupation.code ? this.occupation.code.length !== 7: true;

    console.log("this.occupation DT",this.occupation);
    
    if (this.isFour) {
      this.ocupationTipo_nivel_educativo= this.occupation.tipo_nivel_educativo;
    } else
    {
      this.ocupationTipo_nivel_educativo = this.occupation.occupation_four.tipo_nivel_educativo;
    }

    console.log("this.ocupationTipo_nivel_educativo ",this.ocupationTipo_nivel_educativo);


    // if (this.regionSelected !== 0) {
    //   filters.region_id = this.regionSelected;
    // }

    // if (this.educationSelected !== 0) {
    //   filters.education_level_id = this.educationSelected;
    // }

    let occupationRegions = await this.apiService.getManyBackend(
      `/occupations/${this.occupation.code}/regions`,''
    );
    
    //console.log("occupationRegions",occupationRegions.data);
    occupationRegions = occupationRegions.data.filter((el: any)=>el.name != '99');
    
    this.occupationRegions = occupationRegions;
    
    console.log("occupationRegions");
    console.log(occupationRegions);

    //Filtro de Grupo de Edad
    let occupationGrupoEdads = await this.apiService.getManyBackend(
      `/occupations/${this.occupation.code}/grupoEdads`,''
    );      
    this.occupationGrupoEdads = occupationGrupoEdads.data.filter((el: any)=>el.name != 'ND');;
    
    console.log("occupationGrupoEdads");
    console.log(occupationGrupoEdads);


    let occupationEducationLevels = await this.apiService.getManyBackend(
      `/occupations/${this.occupation.code}/educationLevels`,''
    );

    console.log("occupationEducationLevels");
    console.log(occupationEducationLevels);

 
    //Filtro por Tipo de Nivel Educativo
    switch (this.ocupationTipo_nivel_educativo) {
      case 1:
        //Debe mostrar solo datos de Superior Universitaria
        occupationEducationLevels = occupationEducationLevels.data.filter((el: any)=> el.name.toLowerCase() != 'ND' && el.name.toLowerCase() == 'superior universitaria');        

        break;
      case 2:
        //Debe mostrar solo datos de Superior No universitaria, y Superior Universitaria
        occupationEducationLevels = occupationEducationLevels.data.filter((el: any)=> el.name.toLowerCase() != 'ND' && (el.name.toLowerCase() == 'superior universitaria' || el.name.toLowerCase() == 'superior no universitaria'));                
        break;
      case 3:
        //Debe mostrar datos de todos los niveles educativos
        occupationEducationLevels = occupationEducationLevels.data.filter((el: any)=>el.name.toLowerCase() != 'ND');
        break;

      default:
        occupationEducationLevels = occupationEducationLevels.data.filter((el: any)=>el.name.toLowerCase() != 'ND');

        //
    }

    function getELId(occupationEducLevels: any, name: any){
      let occupationLevel: any;
      if(occupationEducationLevels!=null){
        occupationLevel = occupationEducLevels.find((el : any) => {return el.name.toLowerCase() === name.toLowerCase()});
        if(occupationLevel!=null)
          return occupationLevel.id;
      }
      return "-2";
    }

    let occupationEducationLevelsOrdered =
    [
      {id: getELId(occupationEducationLevels, "Sin educacion"), name: "Sin educación"},
      {id: getELId(occupationEducationLevels, "Primaria"), name: "Primaria"},
      {id: getELId(occupationEducationLevels, "Secundaria"), name: "Secundaria"},
      {id: getELId(occupationEducationLevels, "Tecnica"), name: "Técnica"},
      {id: getELId(occupationEducationLevels, "Superior No Universitaria"), name: "Superior No Universitaria"},
      {id: getELId(occupationEducationLevels, "Superior Universitaria"), name: "Superior Universitaria"}
    ];
    occupationEducationLevelsOrdered = occupationEducationLevelsOrdered.filter((value)=>value.id!="-2");
    this.occupationEducationLevels = occupationEducationLevelsOrdered;

    this.loading = false;
  }

  async getDatosYTendencias() {
    if (this.occupation.code === undefined) {
      await new Promise((f) => setTimeout(f, 300));
      this.getDatosYTendencias();
      return;
    }
 
    this.filters.region_id = this.regionSelected;
    this.filters.education_level_id = this.educationSelected;
    this.filters.grupo_edad_id = this.edadSelected;
    this.filters.base_empresa_id = 1;//Publica

    console.log("Invoca API con filters :",this.filters);
    let datosYTendencias = await this.apiService.getManyBackend(
      `/occupations/${this.occupation.code}`,
      this.filters
    );

    let tabla_resumen = datosYTendencias.data.tabla_resumen;

    this.tabla_resumen = tabla_resumen;
    
    let totalTrabajadores = 0;
    //let edadTrabajadores = 0;
    let tasaMujeres = 0;
    //let sueldoPromedio = 0;
    let sumaRemuneracion = 0;
    let sumaTotalRegla = 0;    
    let sumaEdad = 0;    
    let sumaTotalEdad = 0;            
    let totalSueldoPromedio = 0;            
    let totalEdad = 0;            


    tabla_resumen.forEach((row: any) => {
      totalTrabajadores += parseFloat(row.total_trabajadores.replace(",",""));
      //edadTrabajadores += parseInt(row.edad_promedio.replace(",",""));
      tasaMujeres += parseFloat(row.tasa_mujeres.replace(",",""));

      sumaRemuneracion += parseFloat(row.suma_remuneracion.replace(",",""));
      sumaTotalRegla += parseFloat(row.sumatotal_regla.replace(",",""));  

      sumaEdad += parseFloat(row.suma_edad.replace(",",""));
      sumaTotalEdad += parseFloat(row.sumatotal_edad.replace(",",""));           
    });


    if(sumaTotalRegla!=0){
      totalSueldoPromedio = Math.round(sumaRemuneracion/sumaTotalRegla);
    }

    if(sumaTotalEdad!=0){
      totalEdad= Math.round(sumaEdad/sumaTotalEdad);
    }

    if(tabla_resumen.length>0){
      this.footer_resumen.footers[0].title = 'Total general 1/';
      this.footer_resumen.footers[1].title = `S/ ${totalSueldoPromedio.toString()}`;      
      this.footer_resumen.footers[2].title = `${totalTrabajadores.toString()} trabajadores`;
      this.footer_resumen.footers[3].title = `${totalEdad.toString()} años`;      
      this.footer_resumen.footers[4].title = `${(Math.round(tasaMujeres/tabla_resumen.length)).toString()}%`;
    }else{
      this.footer_resumen.footers[0].title = 'Total general';
      this.footer_resumen.footers[1].title = `S/ 0`;
      this.footer_resumen.footers[2].title = `0 trabajadores`;
      this.footer_resumen.footers[3].title = `0 años`;
      this.footer_resumen.footers[4].title = `0%`;
    }
    //this.tabla_resumen = tabla_resumen.filter((row: any)=>row.name.toLowerCase() != 'no determinado');
    
    //Filtro por Tipo de Nivel Educativo
    switch (this.ocupationTipo_nivel_educativo) {
      case 1:
        //Debe mostrar solo datos de Superior Universitaria
        this.tabla_resumen = tabla_resumen.filter((row: any)=>row.name.toLowerCase() != 'no determinado' && row.name.toLowerCase() == 'superior universitaria');
        break;
      case 2:
        //Debe mostrar solo datos de Superior No universitaria, y Superior Universitaria
        this.tabla_resumen = tabla_resumen.filter((row: any)=>row.name.toLowerCase() != 'no determinado' &&  (row.name.toLowerCase() == 'superior universitaria' || row.name.toLowerCase() == 'superior no universitaria'));        
        break;
      case 3:
        //Debe mostrar datos de todos los niveles educativos
        this.tabla_resumen = tabla_resumen.filter((row: any)=>row.name.toLowerCase() != 'no determinado');
        break;

      default:
        this.tabla_resumen = tabla_resumen.filter((row: any)=>row.name.toLowerCase() != 'no determinado');
        //
    }

    let tendencia_trabajadores = datosYTendencias.data.tendencia_trabajadores;
    this.tendencia_trabajadores = tendencia_trabajadores.map((row: any) => {
      
      // if(this.periodReference==null){
      //     this.periodReference = this.formatPeriod(row.period)
      // }
      
      return [
        `${row.period.substring(0, 4)}-${row.period.substring(4, 6)}`,
        row.trabajadores,
        parseInt(row.trabajadores).toString(),
      ];
    });
    //console.log("this.tendencia_trabajadores",this.tendencia_trabajadores);
    //console.log("this.periodReference",this.periodReference);
    let remuneracion_mensual = datosYTendencias.data.remuneracion_mensual;
    this.remuneracion_mensual = remuneracion_mensual.map((row: any) => {
      return [
        `${row.period.substring(0, 4)}-${row.period.substring(4, 6)}`,
        row.sueldo_promedio,
        parseInt(row.sueldo_promedio).toString(),
      ];
    });

    let entradas_salidas = datosYTendencias.data.entradas_salidas;
    this.entradas_salidas = entradas_salidas.map((row: any) => {
      return [
        `${row.period.substring(0, 4)}-${row.period.substring(4, 6)}`,
        row.salidas * -1,
        (parseInt(row.salidas) * -1).toString(),
        row.entradas,
        parseInt(row.entradas).toString(),
      ];
    });

    let empresas_contratan = datosYTendencias.data.empresas_contratan;
    this.empresas_contratan = empresas_contratan.map((row: any) => {
      return [
        `${row.period.substring(0, 4)}-${row.period.substring(4, 6)}`,
        row.empresas,
        parseInt(row.empresas).toString(),
      ];
    });

    let actividad_ecnonomica_remuneraciones =
      datosYTendencias.data.actividad_economica_remuneracion;
    actividad_ecnonomica_remuneraciones =
      actividad_ecnonomica_remuneraciones.map((row: any) => {
        return [
          row.name,
          row.remuneracion,
          `S/ ${parseInt(row.remuneracion).toString()}`,
        ];
      });

    
    actividad_ecnonomica_remuneraciones = actividad_ecnonomica_remuneraciones.filter((row: any)=>row[0].toLowerCase() != 'no determinado');        
    actividad_ecnonomica_remuneraciones =
      actividad_ecnonomica_remuneraciones.sort((a: any, b: any) =>
        parseInt(a[1]) < parseInt(b[1]) ? 1 : -1
      );


    let actividad_ecnonomica_trabajadores =
      datosYTendencias.data.actividad_economica_trabajadores;

    actividad_ecnonomica_trabajadores = actividad_ecnonomica_trabajadores.filter((row: any)=>row.name.toLowerCase() != 'no determinado');        

    actividad_ecnonomica_trabajadores = actividad_ecnonomica_trabajadores.map(
      (row: any) => {
        return [
          row.name,
          row.porcentaje_trabajadores,
          `${parseInt(row.porcentaje_trabajadores).toString()}%`,
        ];
      }
    );

    actividad_ecnonomica_trabajadores = actividad_ecnonomica_trabajadores.sort(
      (a: any, b: any) => (parseInt(a[1]) < parseInt(b[1]) ? 1 : -1)
    );



    this.actividad_economica = [
      {
        title: 'Remuneración mensual (S/)',
        data: actividad_ecnonomica_remuneraciones,
        periodReference: this.periodReference,
      },
      {
        title: 'Porcentaje de trabajadores (%)',
        data: actividad_ecnonomica_trabajadores,
        periodReference: this.periodReference,
      },
    ];

    let mapa_remuneraciones = datosYTendencias.data.mapa_remuneraciones;
    this.mapa_remuneraciones = mapa_remuneraciones.map((row: any) => {
      return {
        region: row.name,
        value: row.remuneracion,
        label: `S/ ${parseInt(row.remuneracion).toString()}`,
        period: row.period,
      };
    });

    let mapa_trabajadores = datosYTendencias.data.mapa_trabajadores;
    this.mapa_trabajadores = mapa_trabajadores.map((row: any) => {
      return {
        region: row.name,
        value: row.cantidad_trabajadores,
        label: `${parseInt(row.cantidad_trabajadores).toString()}`,
        period: row.period,
      };
    });

    this.mapa_calor = [
      {
        title: 'Remuneración mensual (S/)',
        shortTitle: 'Remuneración',
        data: this.mapa_remuneraciones,
        periodReference: this.periodReference,
      },
      {
        title: 'Cantidad de trabajadores',
        shortTitle: 'Cantidad',
        data: this.mapa_trabajadores,
        periodReference: this.periodReference,
      },
    ];

    let oferta_variacion = datosYTendencias.data.variacion_puestos;
    this.oferta_variacion = oferta_variacion.map((row: any) => {
      const period = `${row.period.substring(0, 4)}-${row.period.substring(
        4,
        6
      )}`;
      const formatValue = `${(
        Math.round(row.value * 10) / 10
      ).toString()}%`;
      if (row.value >= 0) {
        return [period, row.value, `+${formatValue}`, 0, ''];
      } else {
        return [period, 0, '', row.value, `-${formatValue}`];
      }
    });

    this.loading = false;
  }

  formatPeriod(period:any){

    if(period!=null && period.length===6){
      return period.substring(0,4) + "-" + this.monthsName[parseInt(period.substring(4,6))];
    }
    return period;
  }

  onChangeRegion($event: any) {
    this.loading = true;
    this.regionSelected = $event.target.value;
    this.getDatosYTendencias();
  }

  onChangeEdad($event: any) {
    this.loading = true;
    this.edadSelected = $event.target.value;
    this.getDatosYTendencias();
  }
  onChangeEducation($event: any) {
    this.loading = true;
    this.educationSelected = $event.target.value;
    this.getDatosYTendencias();
  }  
}
