import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-presential-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss']
})
export class DescriptionComponent implements OnInit {

  header: any;
  imageUrl: string;
  description: string;
  view: number

  constructor(private apiService: ApiService) {
    this.header = {};
    this.imageUrl = "";
    this.description = "";
    this.view = 1;
  }

  ngOnInit(): void {
    this.getInformation();
  }

  async getInformation() {
    this.header = await this.apiService.getMany('/job-center-header');
    if (this.header) {
      this.imageUrl = environment.strapiUrl + this.header.image[0].url;
      this.description = this.header.description.substring(0, this.header.description.indexOf('@{ver-mas}'));
    } else {
      this.header = {};
    }
  }

  changeVisibility() {
    if (this.view === 1) {
      this.view = 2;
      this.description = this.header.description.replace('@{ver-mas}', '');
    } else {
      this.view = 1;
      this.description = this.header.description.substring(0, this.header.description.indexOf('@{ver-mas}'));
    }
  }
}
