<div class="mt-4">
  <div class="row">
    <div class="col-4">
      <h3 class="text-attention fw-bold mb-4">Datos y tendencias</h3>
    </div> 
  </div>

  <div class="row mb-0 pb-0">
    <div class="mb-1 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
      <app-general [occupation]="occupation" [secondLevel]="secondLevel"></app-general>
    </div>
  </div>   

  <!-- <div class="nav nav-tabs mt-1" id="nav-tab" role="tablist">
    <button
      class="nav-link fw-bold active"
      id="nav-private-tab"
      data-bs-toggle="tab"
      data-bs-target="#nav-private"
      type="button"
      role="tab"
      aria-controls="nav-private"
      aria-selected="false"
    >
      Sector privado
    </button>

    <button
      class="nav-link fw-bold"
      id="nav-public-tab"
      data-bs-toggle="tab"
      data-bs-target="#nav-public"
      type="button"
      role="tab"
      aria-controls="nav-public"
      aria-selected="false"
    >
      Sector público
    </button>
  
    
  </div>

  <div class="tab-content" id="nav-tabContent">

    <div
      class="tab-pane fade show active"
      id="nav-private"
      role="tabpanel"
      aria-labelledby="nav-private-tab"
    >
      <div class="row">
        <div class="mb-4 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
          <app-privado [occupation]="occupation"></app-privado>
        </div>
      </div>   

    </div>

    <div
      class="tab-pane fade show"
      id="nav-public"
      role="tabpanel"
      aria-labelledby="nav-public-tab"
    >
    
      <div class="row">
        <div class="mb-4 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
          <app-publico [occupation]="occupation"></app-publico>
        </div>
      </div>   


    </div>
      
  </div> -->

  
</div>



