<div *ngIf="isFour" class="row mt-4">
  <div class="col-7">
    <h3 class="text-attention fw-bold mb-4">Descripción del perfil</h3>
    <span>{{ occupation !== null ? occupation.description : "" }}</span>
    <p class="mt-4" *ngIf="occupationFunctions.length>0">Sus tareas incluyen las siguientes:</p>
    <ul>
      <li *ngFor="let function of occupationFunctions">
        {{ function.function }}
      </li>
    </ul>
  </div>
  <div class="col-5" *ngIf="levelSeven.length>0">
    <h4 class="mt-5 mb-4">Ocupaciones incluidas</h4>

    <ul>
      <li *ngFor="let occ of levelSeven">
        <a
          class="link text-primary"
          [href]="'/descubre/' + occ.id + '/' + occ.code + '/detail'"
          >{{ occ.name }}</a
        >
      </li>
    </ul>
  </div>
</div>

<div *ngIf="!isFour && occupationSeven!=null && occupation!=null" class="row mt-4">
  <div class="col-7">
    <h3 class="text-attention fw-bold mb-4">Descripción del perfil</h3>
    <p>
      La ocupación de {{ occupationSeven.name }} está incluida dentro de la
      ocupación {{ occupation.name }}.
    </p>
    <p>
      Para revisar mayor información sobre ella:
      <a
        class="fw-bold link text-primary"
        [href]="'/descubre/' + occupation.id + '/detail'"
        >click aquí</a
      >
    </p>
  </div>
</div>

<div class="row mt-4">
  <div class="col-6" *ngIf="occupation_skills != undefined">
    <div style="min-height: 310px" class="card"  >
      <div class="card-body">
        <div
          class="mb-3 d-flex justify-content-center align-items-center"
          style="width: '52px'; height: '52px'; background-color: #0056ac"
        >
          <mat-icon
            style="width: 32px; height: 32px; color: #ffffff"
            svgIcon="atom"
          ></mat-icon>
        </div>
        <p class="card-title fw-bold fs-20">Habilidades</p>
        <p class="description">
          A continuación, se muestra las habilidades requeridas para el desempeño de esta ocupación*, las cuales están ordenadas de acuerdo a su participación**.
        </p>

        
        <div class="row">
          <div class="col-sm-12">
            <!-- <h4 class="mb-4">Ofertas de empleo por tipo de empleo</h4> -->
            <app-pie-job [occupation_skills]="occupation_skills"></app-pie-job>
          </div>
        </div>


        <div *ngFor="let skill of occupationSkills" class="mb-2 fs-16">
          <span *ngIf="skill.value !== '0'" >
            {{ skill.name + " (" + skill.value + "%)" }}
            <div>
              <div class="description">
                {{ skill.description }}  
              </div>
            </div>
          </span>


        </div>
        <br>
        <p align="justify" style="font-size: 12px;"> <strong>Fuente:</strong> Clasificador Nacional de Ocupaciones (CNO) del 2015 y el ESCO Skill-Occupation Matrix Tables de la Clasificación europea de capacidades/competencias, cualificaciones y ocupaciones (ESCO). Análisis realizado con última actualización de la matriz de ESCO al año 2022.<br>
          <strong>*</strong> El nivel de agregación para el análisis de las ocupaciones del CNO 2015 es el grupo primario, y para las habilidades el nivel 1 de grupos de habilidades para ESCO. Además, se muestran solo tipos de habilidades con participación mayor o igual al 1%.<br>
          <strong>**</strong> La participación refleja lo que la ocupación requiere, lo que se obtiene de la ocupación y lo que frecuentemente solicitan los empleadores para esta ocupación.
        </p>
        <div class="mt-3"></div>
      </div>
    </div>
  </div>
  <div class="col-6">
    <div style="min-height: 310px" class="card">
      <div class="card-body">
        <div
          class="mb-3 d-flex justify-content-center align-items-center"
          style="width: '52px'; height: '52px'; background-color: #0056ac"
        >
          <mat-icon
            style="width: 32px; height: 32px; color: #ffffff"
            svgIcon="trending-up"
          ></mat-icon>
        </div>
        <p class="card-title fw-bold fs-20">Proyecciones</p>
        <div class="mb-2 fs-16">
          <div *ngIf="edo_projection > 0">
            <div class="mb-2 fs-16 fw-bold">
              Encuesta de Demanda Ocupacional (EDO)
            </div>
            <div class="mb-2 fs-16">
              Según la EDO, para el
              <span class="text-attention fw-bold fs-20">{{
                edo_year + 1
              }}</span>
              se demandarían
              <span class="text-attention fw-bold fs-20">{{
                edo_projection
              }}</span>
              puestos de trabajo.
            </div>
          </div>          
          <div>
            <div class="mb-2 mt-4 fs-16 fw-bold">
              Crecimiento histórico según Planilla Electrónica (PE)
            </div>
            <div class="mb-2 fs-16">
              El empleo de la ocupación muestra un crecimiento de
              <span class="text-attention fw-bold fs-20"
                >{{ occupationGrowth }}%</span
              >
              en el último año.
            </div>
          </div>
        </div>

        <div style="font-size: 12px;" class="mt-3">
          <p align="justify" *ngIf="edo_projection > 0"><strong>Nota EDO: </strong>Las proyecciones de la EDO se calcularon en base a 8 dominios geográficos: Lima Metropolitana y Callao, Arequipa, Ica, La Libertad, Lambayeque, Piura y San Martín; y un estrato denominado resto de regiones. No se presenta información de las proyecciones de empleo para ocupaciones incluidas con baja participación (ocupaciones con un registro menor a un promedio de 15 trabajadores durante los últimos meses).</p>
          <p align="justify" *ngIf="occupationGrowth>0"><strong>Nota PE: </strong>Las proyecciones de Planilla Electrónica está basada en el crecimiento del número de puestos de trabajo registrados durante los últimos periodos mensuales del año vigente, respecto del mismo periodo del año anterior.</p>        
        </div>


      </div>
    </div>
  </div>
</div>
