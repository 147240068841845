import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PublicationsComponent } from './publications/publications.component';
import { PublicationsAnnualComponent } from './publications/publications-annual-report/publications-annual-report.component';
import { PublicationsMonthComponent } from './publications/publications-month-report/publications-month-report.component';
import { PublicationsMiscComponent } from './publications/publications-misc-report/publications-misc-report.component';
import { MicrodataComponent } from './microdata/microdata.component';
import { OselComponent } from './osel/osel.component';
import { RegionComponent } from './osel/region/region.component';

const routes: Routes = [
  { path: 'publicaciones', component: PublicationsComponent },
  { path: 'microdatos', component: MicrodataComponent },
  //{ path: 'observatorios-regionales', component: OselComponent },
  // { path: 'observatorios-regionales/:slug', component: RegionComponent },

  {
    path: 'publicaciones/anuales/:publicationTypeId',
    component: PublicationsAnnualComponent,
  },
  {
    path: 'publicaciones/archivos/:publicationTypeId',
    component: PublicationsMonthComponent,
  },
  {
    path: 'publicaciones/informes/:publicationTypeId',
    component: PublicationsMiscComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ResourcesRoutingModule {}
