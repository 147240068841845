import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class DetailComponent implements OnInit {
  occupationId: string;
  occupationCode: string;
  occupationCodigo: string;  
  occupation: any;

  showDatosYTendencias: any;
  showFormacionAcademica: any;
  secondLevel: any;
  primerLevelCode: any;

  constructor(private route: ActivatedRoute, private apiService: ApiService) {
    this.occupationId = this.route.snapshot.params.id;
    this.occupationCode = this.route.snapshot.params.code;
    this.occupationCodigo = "";
    this.secondLevel = null;     
  }

  ngOnInit(): void {
    this.getOccupation();
    this.occupation = {};
    this.secondLevel = {};    
  }

  async getOccupation() {
    let occupation: any;
    let secondLevel: any;
    if (this.route.snapshot.params.code!=null && this.route.snapshot.params.code.length === 7) {

      this.occupationCodigo = this.occupationCode;

      occupation = await this.apiService.getOne(
        `/occupation-sevens?code=${this.occupationCode}`
      );

      let occupationFour = await this.apiService.getMany(
        `/occupation-fours/${occupation.occupation_four.id}`
      );
        secondLevel = await this.apiService.getMany(
        `/occupation-twos/${occupationFour.occupation_three.occupation_two}`
      );
      //this.secondLevel = secondLevel;   
      this.primerLevelCode = secondLevel.occupation_one.code;   
    } else {
      occupation = await this.apiService.getMany(
        `/occupation-fours/${this.occupationId}`
      );

      this.occupationCodigo = occupation.code;      

      secondLevel = await this.apiService.getMany(
        `/occupation-twos/${occupation.occupation_three.occupation_two}`
      );
      //this.secondLevel = secondLevel;
      this.primerLevelCode = secondLevel.occupation_one.code;   

    }
    let filters: { [k: string]: any } = {};
    let datosYTendencias = await this.apiService.getManyBackend(
      `/occupations/${occupation.code}`, filters
    );
   
    
    this.showDatosYTendencias = datosYTendencias.data.tabla_resumen.length>0;

    // console.log("this.occupationCodigo",this.occupationCodigo);
    const occupationCareerCodes = (
       await this.apiService.getManyBackend(`/occupations/career/${this.occupationCodigo}`, null)
      //await this.apiService.getManyBackend(`/occupations/career/${occupation.code.substring(0,4)}`, null)      
    ).data;

    // console.log("occupationCareerCodes",occupationCareerCodes)  ;
    //No se muestra para Policias y Militares : this.primerLevelCode !=='0'
    this.showFormacionAcademica = occupationCareerCodes.length>0 && this.primerLevelCode !=='0';
    this.occupation = occupation;
    this.secondLevel = secondLevel;    
  }
}
